@use "src/styles/utilities/_rem-transform" as style;

.c-job-reference-manager {
  display: flex;
  align-self: flex-start;
  width: 80%;
  min-width: style.pxtorem(8*88);
  max-width: style.pxtorem(8*114);
  height: calc(100% - var(--spacing-6));
  margin: var(--spacing-6) auto 0;

  background-color: var(--white);

  box-shadow: var(--dropshadow);
}

.c-job-reference-manager .tag {
  vertical-align: baseline;
}

.c-job-reference-manager__list {
  flex: 0 0 style.pxtorem(8*33);

  overflow: auto;

  background-color: var(--background-1);
  border-right: var(--bw1) solid var(--border-shade);
}

.c-job-reference-manager__pane {
  flex: 1 1 auto;
  padding: var(--spacing-6);
  min-height: 0;
  display: flex;
  flex-direction: column;
}
