.c-icon-button {
  display: inline-flex;
  padding: var(--spacing-2);

  background-color: var(--icon-button-background);
  border: var(--bw1) solid var(--icon-button-background);
  border-radius: var(--br);

  color: var(--icon-button-text);

  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    color 0.15s ease-in-out;

  &:focus {
    outline: none;
  }

  &.c--selected:not(.c--following) {
    background-color: var(--icon-button-selected-background);
    color: var(--icon-button-selected-text);

    &:focus-visible {
      outline: none;
      border-color: var(--icon-button-selected-hover-background);
    }

    &:hover {
      background-color: var(--icon-button-selected-hover-background);
      border-color: var(--icon-button-selected-hover-background);
      color: var(--icon-button-selected-hover-text);
    }
  }

  &.c--following {
    background-color: var(--yellow);
    color: var(--white);

    &:focus-visible {
      outline: none;
      border-color: var(--yellow);
    }

    &:hover {
      background-color: var(--yellow);
      border-color: var(--yellow);
      color: var(--icon-button-selected-hover-text);
    }
  }

  &:focus-visible {
    outline: none;
    border-color: var(--icon-button-hover-background);
  }

  &:hover {
    background-color: var(--icon-button-hover-background);
    color: var(--icon-button-hover-text);
    border-color: var(--icon-button-hover-background);
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;

    cursor: default;

    &:hover {
      opacity: 0.3;
      color: var(--text-2);
      border-color: transparent;
      background-color: transparent;
    }
  }
}

// Small Size

.c-icon-button.c--sm {
  padding: var(--spacing-1);
}

// Variation on dark background
.c-icon-button.c--dark:not(.c--following) {
  background: var(--icon-button-dark-background);
  color: var(--icon-button-dark-text);
  border: var(--bw1) solid var(--icon-button-background);

  &:focus-visible {
    border-color: var(--icon-button-dark-hover-background);
  }

  &:hover {
    background: var(--icon-button-dark-hover-background);
    color: var(--icon-button-dark-hover-text);
  }

  &.c--selected {
    background-color: var(--icon-button-selected-background);
    color: var(--icon-button-selected-text);

    &:focus-visible {
      border-color: var(--icon-button-selected-hover-background);
    }

    &:hover {
      background-color: var(--icon-button-selected-hover-background);
      border-color: var(--icon-button-selected-hover-background);
      color: var(--icon-button-selected-hover-text);
    }
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;

    &:hover {
      opacity: 0.3;
      color: var(--text-2);
      border-color: transparent;
      background-color: transparent;
    }
  }
}

// Link Style
.c-icon-button.c--link {
  color: var(--icon-button-link-text);
}

// Toggle Style
.c-icon-button.c--toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-3);

  border: 1px solid var(--button-border-color);
  border-radius: var(--br1);
  font-weight: var(--fw-normal);
  &:hover {
    background-color: var(--grey-20);
  }
  &.c--selected {
    background-color: var(--icon-button-selected-background);
    color: var(--icon-button-selected-text);

    &:focus-visible {
      border-color: var(--icon-button-selected-hover-background);
    }

    &:hover {
      background-color: var(--icon-button-selected-hover-background);
      border-color: var(--icon-button-selected-hover-background);
      color: var(--icon-button-selected-hover-text);
    }
  }
}
