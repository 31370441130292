// Message Header
// ===
@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";

.c-message-header {
  position: relative; // for plain text button

  display: flex;

  background-color: var(--background-1);

  border-bottom: 1px solid var(--border-shade);

  .c-message-address-block {
    @media print {
      display: none;
    }
  }
}

.c-message-header__container {
  width: 100%;

  padding: var(--spacing-3) var(--spacing-3) var(--spacing-2);

  @media (min-width: $small) {
    padding: var(--spacing-3) var(--spacing-3) 0;
  }
}

.c-message-header__date_container {
  display: flex;
  position: relative;
}

.c-message-header__date {
  flex: 1 0 auto;
  font-size: var(--fs-sm);
  text-align: right;
}

.c-message-header__subject {
  flex: 1 1 auto;
  padding-right: var(--spacing-8);

  font-size: var(--fs-xlg);
  font-weight: normal;

  white-space: pre-wrap;

  &:empty {
    &:before {
      content: "(No Subject)";

      display: block;

      color: var(--text-4);
    }
  }
}

.c-message-header__following {
  border-right: var(--bw4) double var(--yellow);
}

.c-message-header__plaintext {
  position: absolute;
  right: var(--spacing-3);
  bottom: var(--spacing-2);
}

.c-message-header__default {
  display: flex;
  margin-bottom: var(--spacing-2);

  opacity: 1;
  transition: opacity 150ms ease-in;

  &.fade-in-appear {
    opacity: 0;
  }

  @media print {
    display: none;
  }
}

.c-message-header__recipients {
  position: relative;

  width: 100%;
  padding-bottom: var(--spacing-3);

  margin-right: calc(var(--spacing-2) + var(--spacing-6));
  margin-bottom: var(--spacing-2);

  opacity: 1;
  transition: opacity 150ms ease-in;

  &.fade-in-appear {
    opacity: 0;
  }

  &.c--expanded {
    max-height: style.pxtorem(8*13);
    overflow-y: auto;
  }

  @media print {
    display: none;
  }
}

.c-message-header__print-recipients {
  display: none;
  padding-left: var(--spacing-8);

  @media print {
    display: block;
    margin-bottom: var(--spacing-3);

    &.c-ff-collapsible-hd {
      padding-left: 0;
    }
  }
}

.c-message-header__verified {
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: $medium) {
    position: absolute;
    right: 0;
    top: style.pxtorem(8*2.5);
  }

  a {
    color: var(--person-tag);
  }
}

.c-message-header__unverified {
  display: flex;
  justify-content: flex-end;

  a {
    color: var(--action-70);
  }

  @media screen and (min-width: $medium) {
    position: absolute;
    right: 0;
    top: style.pxtorem(8*2.5);
  }
}

.c-message-header__verified-text-bold {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}

.c-message-header__verified-bold {
  display: flex;
  background: var(--purple-tint);
  justify-content: center;
  border-radius: 25px;
  margin-bottom: var(--spacing-2);
  padding: var(--spacing-1);

  a {
    color: var(--purple-50);
  }
}
.c-message-header__unverified-bold {
  display: flex;
  background: var(--action-10);
  justify-content: center;
  border-radius: 25px;
  margin-bottom: var(--spacing-2);
  padding: var(--spacing-1);

  a {
    color: var(--action-70);
  }
}

.c-message-header__subject-date {
  flex-direction: column-reverse;
  display: flex;

  @media screen and (min-width: $small) {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* feature flag collapsible header */
.c-message-header.c-ff-collapsible-hd {
  position: static;

  display: block;

  .c-message-header__top {
    display: flex;
  }

  .c-message-header__subject-from {
    flex: 1 1 auto;

    overflow: hidden;

    margin: var(--spacing-3) var(--spacing-4) 0 var(--spacing-4);
  }

  .c-message-header__from-container {
    display: flex;
    flex-wrap: nowrap;
  }

  .c-message-address-block__label {
    min-width: style.pxtorem(8*5);

    text-align: right;
  }
}

.c-message-header__toggle-btn-ff-collapsible-hd {
  align-self: flex-start;

  margin-top: var(--spacing-1); // match top padding of .c-message-address-block
  margin-left: var(--spacing-1);

  @media print {
    display: none;
  }
}

.c-message-header__default.c-ff-collapsible-hd {
  // From field
  flex: 1 1 auto;
  flex-wrap: wrap;

  margin-bottom: 0;

  /* We need to set some additional overflow for the From field to
     let text-overflow set ellipsis properly instead of wrapping */
  overflow: hidden;

  .c-message-address-block {
    max-width: 100%;
  }
}

.c-message-header__recipients-ff-collapsible-hd {
  position: relative;
  margin: 0 var(--spacing-4) var(--spacing-6) var(--spacing-4);

  overflow: hidden;

  .c-message-address-block {
    transform: translateY(-100%);

    &.recipients-default-appear-active,
    &.recipients-default-appear-done {
      transition: transform 0.15s ease-out;
      transform: translateY(0);
    }

    &.recipients-expanded-appear-active,
    &.recipients-expanded-appear-done {
      transition: transform 0.2s ease-out;
      transform: translateY(0);
    }

    &.recipients-expanded-exit {
      transform: translateY(0);
    }

    &.recipients-expanded-exit-active {
      transition: transform 0.2s ease-out;
      transform: translateY(-100%);
    }
  }

  .c-message-address-block {
    // size of icon + padding on icon
    margin-left: calc(var(--icon-sm) + (2 * var(--spacing-1)));
  }

  @media print {
    display: none;
  }
}

.c-message-header__plaintext-ff-collapsible-hd {
  position: absolute;
  top: style.pxtorem(8*9);
  right: var(--spacing-3);
  z-index: var(--stage-layer);

  @media (min-width: $small) {
    top: style.pxtorem(8*6);
    right: var(--spacing-6);
  }
}

.mobile-specific-styling {
  .c-message-header__date_container {
    flex-direction: column-reverse;
  }

  .c-message-header__date {
    margin-bottom: var(--spacing-3);

    font-size: var(--fs-sm);
    text-align: left;

    display: flex;
    margin-right: style.pxtorem(8*12); // space for absolutely positioned activity and tag icons
  }

  .c-message-header__subject {
    font-size: var(--fs-lg);
  }
}
