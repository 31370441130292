.tippy-box[data-theme="tooltip"] {
  font-size: var(--fs-sm);
  border-radius: var(--br);
  .tippy-content {
    background-color: var(--tooltip-background);
    padding: var(--spacing-1) var(--spacing-2);
  }

  @media print {
    display: none;
  }
}
