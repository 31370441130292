.c-editor-button {
  display: inline-flex;
  margin-right: 16px;
  background-color: transparent;
  border: none;
  color: var(--text-3);
  padding: 0;

  &:hover {
    color: var(--text-2);
  }

  &:focus {
    outline: none;
  }

  .c--active {
    color: var(--text-1);
  }
}
