// Global imports
@import "@sednasystems/harbour-core/dist/index.css";

/* Message List Pane
 * - message list, files list, activity list
 */
.message-list {
  box-shadow: 3px 0 1px rgba(0, 0, 0, 0.6);
  transition: transform 200ms;

  &:focus {
    outline: none;
  }
}
