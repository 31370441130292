// User Settings Dropdown
// ===
//
// Notes
// 1. Keeps text baselines consistent
@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";

.c-teams-dropdown {
  padding: var(--spacing-1) var(--spacing-2);
  margin: 0 var(--spacing-1);

  background-color: var(--background-3);
  border-color: transparent;
  border-width: 2px;
  border-radius: var(--br3);
  font-size: var(--fs-sm);
  color: var(--text-4);

  @media (min-width: $x-large) {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
  }

  &:hover {
    background-color: var(--background-3); // override bootstrap
    border-color: var(--border-3);
    color: var(--white);
  }

  &:active,
  &:focus {
    background-color: var(--background-3);
    border-color: var(--action-tint-20);
    box-shadow: none; // override bootstrap
    color: var(--white);
  }

  // override bootstrap
  &.nav-link.nav-link {
    color: var(--text-4);

    &:hover {
      color: var(--white);
    }
  }

  &.c--text-only {
    line-height: 1.5; // 1
  }
}

.c-teams-dropdown.c--fw {
  display: flex;

  max-width: style.pxtorem(8*14);

  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;

  @media (min-width: $large) {
    max-width: style.pxtorem(8*16);
  }

  @media (min-width: $x-large) {
    max-width: style.pxtorem(8*18);
  }

  .c-icon {
    flex: 0 0 auto;

    @media (min-width: $medium) {
      margin-right: var(--spacing-2);
    }
  }
}

// Isolate ellipsis text because it was causing weird spacing issues on the icon
.c-teams-dropdown__ellipsis {
  flex: 0 1 auto;
  align-self: center;

  overflow: hidden;

  line-height: 1.5; // 1

  text-overflow: ellipsis;

  white-space: nowrap;

  @media (max-width: $medium) {
    display: none;
  }
}

.c-teams-dropdown__menu {
  max-height: 35vh;
  overflow: auto;

  .c-teams-dropdown-item {
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .c-teams-dropdown-item.active {
    background-color: var(--background-shade);
    color: inherit;
  }

  .c-teams-dropdown-item.active:active,
  .c-teams-dropdown-item:active {
    background-color: var(--background-shade);
    color: var(--text-1);
  }

  .c-teams-dropdown-item.active:focus,
  .c-teams-dropdown-item:focus {
    outline: none;
  }
}

.c-teams-dropdown__menu.c--triangle {
  top: var(--spacing-3) !important;
  border-top: none;
}

.c-teams-dropdown__triangle {
  position: absolute;
  top: 110%;
  left: 50%;

  display: none;
  width: 0;
  height: 0;
  margin: auto auto auto -8px;

  border-right: 10px solid transparent;
  border-bottom: 15px solid var(--white);

  border-left: 10px solid transparent;

  .dropdown.show & {
    display: block;
  }
}

.c-teams-dropdown__btn-secondary {
  border: 0;
  color: var(--text-1);
  padding: 0;
  background-color: inherit;
  display: flex;
  align-items: center;

  &:hover,
  &:focus,
  &:active {
    background-color: inherit;
    border: 0;
    box-shadow: inherit;
  }

  .c-teams-dropdown__toggle {
    padding: var(--spacing-2) var(--spacing-3);

    background-color: var(--white);
    border-color: currentColor;
    border-radius: style.pxtorem(8*18);

    color: var(--person-tag);
  }
}
