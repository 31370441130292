// Modal Styles
// ===
//
// Applied to the reactstrap modal
@use "src/styles/utilities/_rem-transform" as style;

.sidebar-expanded,
.sidebar-collapsed {
  .c--background-clickable {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: block;

    .modal {
      position: absolute;
    }
  }
}

.sidebar-expanded {
  .c--background-clickable {
    left: var(--sidebar-expanded-width);
    transition: left 150ms ease-in-out;
  }
}

.sidebar-collapsed {
  .c--background-clickable {
    left: var(--sidebar-collapsed-width);
    transition: left 150ms ease-in-out;
  }
}

.c--background-clickable {
  pointer-events: none;
}

.c-modal {
  .modal-header {
    display: flex;
    align-items: center;

    background-color: var(--background-1);

    border-bottom: 1px solid var(--border-shade);
  }

  .modal-title {
    flex: 1 1 auto;
    font-size: var(--fs-lg);
    text-align: center;
  }

  .modal-body {
    padding: var(--spacing-6);
  }

  .modal-content {
    border-radius: var(--br1);
  }

  .modal-footer {
    .form-group {
      flex: 1;
    }

    @media screen and (max-width: $small) {
      .form-group {
        flex-basis: 100%;
        margin-bottom: var(--spacing-2);
      }
    }

    justify-content: center;

    border-top: none;
  }
}

.c-modal.c--modal-wide {
  // Override max-width on bootstrap's modal-dialog
  max-width: 680px;
}

.c--shared-page {
  // Override max-width on bootstrap's modal-dialog
  max-width: style.pxtorem(8*100);
}

.c--modal-narrow {
  max-width: style.pxtorem(8*50);
}

.c-modal.c--body-centered {
  .modal-header {
    display: flex;
    align-items: center;

    background-color: var(--background-1);

    border-bottom: 1px solid var(--border-shade);
  }
  .modal-title {
    flex: 1 1 auto;

    text-align: center;
  }
  .modal-content {
    border-radius: var(--br1);
  }

  .modal-body {
    text-align: center;
  }
  .modal-footer {
    justify-content: center;

    border-top: none;
  }
}

.c-modal.c--button-r {
  .modal-header {
    display: flex;
    padding: var(--spacing-6);
    background-color: transparent;
    border-bottom: none;
  }

  .modal-title {
    font-size: var(--fs-xlg);
    font-weight: var(--fw-semi);
    text-align: start;
  }

  .modal-body {
    padding-top: var(--spacing-1);
  }

  .table-body {
    padding-bottom: var(--spacing-1);
  }

  .modal-footer {
    justify-content: flex-end;
  }
}

.c-modal.c--space-between {
  max-height: 30vh;

  .modal-header {
    display: flex;
    align-items: center;

    background-color: var(--background-1);

    border-bottom: 1px solid var(--border-shade);
  }

  .modal-title {
    flex: 1 1 auto;

    text-align: center;
  }
  .modal-content {
    border-radius: var(--br1);
  }

  .modal-footer {
    justify-content: space-between;

    border-top: none;
  }
}

.c-modal.c--scroll {
  max-height: 90vh;
  overflow: hidden;

  .modal-content {
    display: flex;
    height: 100%; // stretch to content height
    max-height: 90vh;
  }

  .modal-header {
    flex-shrink: 0;
  }

  .modal-body {
    flex: 1 1 auto;
    overflow-x: auto;
  }

  .modal-footer {
    flex-shrink: 0;
  }
}

.modal-footer {
  padding: var(--spacing-6);

  > * {
    margin: 0 var(--spacing-2); // override bootstrap
  }

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
}
