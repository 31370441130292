// Mobile Menu Items
// ---

.c-mobile-menu-button {
  display: flex;
  align-items: center;
  width: 100%;

  padding: var(--spacing-2);

  background-color: transparent;

  border: none;

  border-radius: var(--br2);
  color: var(--text-4);

  text-align: left;

  &:not(:last-child) {
    margin-bottom: var(--spacing-3);
  }

  &:focus {
    outline: none;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    background-color: var(--menu-item-dark-hover-background);
    color: var(--menu-item-dark-hover-text);
  }

  &:active:not(:disabled) {
    background-color: var(--action-tint-20);

    .c-snippet-insert-menu__icon {
      color: var(--action-60);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.c-mobile-menu-button__description {
  font-size: var(--fs-sm);
  color: var(--text-3);
}

.c-mobile-menu-button__icon {
  margin-right: var(--spacing-3);

  color: var(--text-4);
}
