// Radio Button Styles
// ===
@use "src/styles/utilities/_rem-transform" as style;

[type="radio"] {
  position: relative;
  top: -1px;

  display: inline-block;
  width: style.pxtorem(8*2.5);
  height: style.pxtorem(8*2.5);

  margin-right: var(--spacing-2);

  background-color: var(--radio-button-default-background);
  border: 2px solid var(--radio-button-default-border);
  border-radius: 50%;

  vertical-align: middle;

  -webkit-appearance: none;
  -moz-appearance: none;

  &:checked {
    border-color: var(--radio-button-checked-border);
    &::after {
      display: block;
    }
  }

  &:active,
  &:focus {
    border-color: var(--radio-button-active-border);
    outline: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;

    display: none;

    background-color: var(--radio-button-checked-background);

    border-radius: 50%;
  }
}
