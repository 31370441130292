// Overflow
// ===

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-x-auto {
  overflow-x: auto;
}

.u-overflow-y-auto {
  overflow-y: auto;
}

.u-overflow-y-auto-x-hidden {
  overflow-x: hidden;
  overflow-y: auto;
}
