// Font Style Utilities
// ===

.u-fs-n {
  font-style: normal;
}

.u-fs-i {
  font-style: italic;
}
