// Message Toolbar
// ===
//

.c-message-toolbar {
  position: relative;

  z-index: var(--stage-layer);
}

.c-message-toolbar__btn-group {
  position: absolute;
  left: 50%;

  display: inline-flex;

  background-color: var(--white);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);
  transform: translateX(-50%) translateY(-50%);
}

.c-message-toolbar__btn-right {
  position: absolute;
  right: var(--spacing-3);
  transform: translateY(-50%);
  display: inline-flex;

  background-color: var(--white);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);
}

.c-message-toolbar__btn {
  display: inline-flex;
  flex: 0 1 auto;

  padding: var(--spacing-2) var(--spacing-4);

  background-color: var(--white);
  border: none;
  border-radius: 0;
  color: var(--text-2);

  &:first-child {
    border-top-left-radius: var(--br1);
    border-bottom-left-radius: var(--br1);
  }

  &:last-child {
    border-top-right-radius: var(--br1);
    border-bottom-right-radius: var(--br1);
  }

  &:disabled {
    background-color: var(--white);
    opacity: 0.5;

    &:hover {
      background-color: var(--white);
      color: var(--text-2);
    }
  }

  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    color: var(--action-50);
  }

  &:focus {
    box-shadow: none;
    color: var(--action-50);
  }

  &:hover {
    background-color: var(--action-tint-10);
    box-shadow: none;
    color: var(--action-60);
  }

  &.c--selected {
    background-color: var(--text-2);
    color: var(--white);
  }
}
