.u-h2 {
  font-size: var(--fs-lg);
  font-weight: var(--fw-bold);

  @media print {
    font-size: 16px;
  }
}

.u-h3 {
  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
  color: var(--text-2);

  text-transform: uppercase;

  @media print {
    font-size: 12px;
  }
}
