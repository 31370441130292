@use "src/styles/utilities/_rem-transform" as style;
@import "../../node_modules/bootstrap/scss/mixins/text-truncate";
@import "../styles/breakpoints";

@mixin filter-position($numIcons: "2") {
  // counter displacement: 2 regular icons 24px
  // first one is the extra padding for the new message button
  // second one has left and right margin and padding of --spacing-2
  $string: unquote($numIcons);

  --compose-picker-extra-padding: 0px;
  &.c--with-compose-button {
    --compose-picker-extra-padding: calc(var(--spacing-2) * 2);
  }

  &.c--icons-#{$string} {
    .react-autosuggest__suggestions-container--open {
      left: calc(
        -1 * (var(--compose-picker-extra-padding) + (#{$numIcons} * 4) *
              var(--spacing-2) + (#{$numIcons} * var(--icon-md)))
      );
    }
  }
}

.c-filter-token-select-container {
  z-index: var(--orchestra-layer);

  .react-autosuggest__container--open {
    flex: 0 0 auto;
  }

  .react-autosuggest__suggestions-container--open {
    display: flex;
    flex-direction: column;

    // Needed to fix an open issue with react dnd where chrome snapshots break when rendering the drag preview
    // on an overflowing container
    // https://github.com/react-dnd/react-dnd/issues/832
    transform: translate3d(0, 0, 0);
  }

  .react-autosuggest__suggestions-container--open-options {
    overflow-y: scroll;
  }

  .react-autosuggest__container {
    flex: 1 1 auto;
  }

  /* maybe we can somehow pass in an inverse theme instead of styling inside .filter-tray */
  .react-autosuggest__input {
    width: style.pxtorem(8*31.5);
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
    padding-left: calc(
      var(--spacing-3) * 2 + var(--icon-md)
    ); // the small icon is hardcoded to 24px

    background: none;
    border: none;

    outline: none;

    @media (min-width: 375px) {
      width: style.pxtorem(8*35);
    }

    @media (min-width: $x-large) {
      width: style.pxtorem(8*38.5);
    }
  }

  .react-autosuggest__title-show-more {
    position: absolute;
    right: var(--spacing-3);

    font-size: var(--fs-sm);

    text-transform: none;

    cursor: pointer;

    .react-autosuggest__title-show-more-icon {
      margin-left: var(--spacing-1);
    }
  }

  .react-autosuggest__filter-search-footer {
    font-size: var(--fs-sm);
    font-weight: var(--fw-bold);

    padding: var(--spacing-3);

    background-color: var(--filter-search-footer-background);

    color: var(--filter-search-text);

    .react-autosuggest__filter-search-footer-section {
      display: inline-block;
      margin-right: var(--spacing-3);
    }

    .react-autosuggest__filter-search-esc-key {
      display: inline-block;

      padding: 0 var(--spacing-2) 1px var(--spacing-2);

      margin-right: var(--spacing-2);

      background-color: var(--background-3);
      border-radius: var(--br1);
      line-height: var(--fs-lg);
      color: var(--utility-white);
    }
  }

  .react-autosuggest__section-container {
    margin-top: 0;

    border-top: none;
  }

  .react-autosuggest__section-title {
    padding-left: var(--spacing-3);

    background-color: var(--filter-search-footer-background);
    font-size: var(--fs);

    color: var(--filter-search-text);

    text-transform: none;
  }

  .react-autosuggest__suggestion {
    border-bottom: 1px solid var(--border-1);
  }

  .react-autosuggest__suggestion-sample-lozenges {
    display: inline-flex;

    align-items: center;
  }

  .react-autosuggest__suggestion-advanced {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .react-autosuggest__suggestion-bookmark {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .react-autosuggest__suggestion-description {
    @include text-truncate();

    display: inline-flex;
    flex-shrink: 1;

    margin-left: var(--spacing-3);

    font-size: var(--fs-sm);
    color: var(--filter-search-suggestion-descrption-text);
  }

  .react-autosuggest__suggestion-result {
    display: flex;
  }

  .react-autosuggest__suggestion-title {
    display: inline-flex;
    width: style.pxtorem(8*11.5); // Hardcode the width the make sure titles are aligned in different sections
    margin-right: var(--spacing-1);
  }

  .react-autosuggest__header {
    padding-top: var(--spacing-2);

    font-weight: var(--fw-normal);
  }
}

.c-filter-token-select-container.c--header-wide {
  @include filter-position("1");
  @include filter-position("2");
  @include filter-position("3");
  @include filter-position("4");

  .react-autosuggest__input {
    height: calc(var(--icon-md) + (var(--spacing-4) * 2));
    // the height of the input should be same as the compose picker (one 24px icon + style.pxtorem(8*0.75) top and bottom padding) in order to precisely position the dropdown
  }

  &.c--sidebar-bookmarks {
    .react-autosuggest__suggestions-container--open {
      min-height: calc(
        100vh - (calc(var(--icon-md) + var(--spacing-4) * 2 + var(--spacing-2)))
      );
      left: calc(
        -1 * (var(--compose-picker-extra-padding) + 5 * var(--spacing-2) +
              (var(--icon-md)))
      );
    }
  }

  &.c--extra-padding .react-autosuggest__input {
    padding-left: calc(var(--spacing-7) + var(--icon-md));
  }

  .react-autosuggest__suggestions-container--open {
    top: calc(var(--icon-md) + (var(--spacing-4) * 2));

    .sidebar-collapsed &,
    .sidebar-expanded & {
      top: calc(var(--icon-md) + (var(--spacing-4) * 2) + var(--spacing-2));
    }

    width: 100vw;
    min-height: 0;
    max-height: 80vh;

    @media (min-width: $small) {
      max-height: 60vh;
    }

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);

    .sidebar-expanded & {
      width: calc(100vw - var(--sidebar-expanded-width));
    }

    .sidebar-collapsed & {
      width: calc(100vw - var(--sidebar-collapsed-width));
    }

    @media (max-width: $medium) {
      .sidebar-expanded & {
        width: calc(100vw - var(--sidebar-collapsed-width));
      }
    }
  }
}

.c-filter-token-select-container.c--modal {
  .react-autosuggest__container {
    flex: 0 0 auto;
    padding: var(--spacing-3);

    border-radius: var(--br1);
  }

  .react-autosuggest__suggestions-container {
    left: -1px;

    border-bottom-right-radius: var(--br1);
    border-bottom-left-radius: var(--br1);
  }

  .react-autosuggest__suggestions-container--open {
    left: calc(
      -1 * (var(--spacing-3) + var(--icon-md) + 1px)
    ); // counter displacement: 1 margin + 1 regular icon + 1 border size

    width: calc(
      70vw - 2 * (var(--spacing-3)) - 2px
    ); // note: 2px is the left and right border size of the modal
    min-height: 0;
    margin-top: calc(-1 * var(--br1));

    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.4);
  }

  .react-autosuggest__input {
    width: style.pxtorem(8*31.5);

    padding-top: var(--spacing-1);
    padding-bottom: var(--spacing-1);
    padding-left: 0;

    border: 0;

    &:focus {
      outline: none;
    }
  }
}
