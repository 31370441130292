.c-template-meta {
  padding: 0 var(--spacing-5) 0 var(--spacing-3);
}

.c-template-meta__heading {
  margin: var(--spacing-3) 0;
  padding: var(--spacing-2) 0;

  font-size: var(--fs-lg);
  font-weight: var(--fw-bold);
  line-height: var(--icon-md);
}
