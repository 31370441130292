.c-activity-by-group {
  position: relative;

  display: flex;
  padding-bottom: var(--spacing-3);
  margin-left: calc(
    var(--spacing-2) + calc(var(--icon-lg) / 2)
  ); // spacing plus half of icon

  &:not(:last-child) {
    border-left: var(--bw1) solid var(--border-shade);
  }
}

.c-activity-by-group__toggle-btn {
  position: absolute;
  left: calc(-1 * var(--icon-lg) / 2);

  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;

  width: var(--icon-lg);
  height: var(--icon-lg);

  padding: 0;
  overflow: hidden;

  background-color: var(--text-1);
  border: var(--br) solid var(--text-1);
  border-radius: 50%;
  color: var(--white);
  flex-shrink: 0;

  @media print {
    background-color: transparent;
  }

  .c-icon {
    transition: transform 150ms ease-in-out;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--action-tint-10);
  }

  &.c--hover {
    background-color: var(--action-60);
    border-color: var(--action-60);

    &.c--expanded {
      .c-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.c-activity-by-group__item-content {
  flex: 1 1 auto;
  min-height: var(--icon-lg);
  // same margin that is on the left of icon + margin + half of icon size
  margin-left: calc(var(--spacing-2) + var(--spacing-3) + var(--icon-lg) / 2);
}

.c-activity-by-group__timestamp {
  margin-right: var(--spacing-3);

  font-size: var(--fs-sm);
  font-style: italic;
  color: var(--text-2);
}
