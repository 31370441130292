// Message Activity Pane Comment Input
// ===
@use "src/styles/utilities/_rem-transform" as style;

.c-activity-input-bar {
  width: 100%;

  background-color: var(--white);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);
  box-shadow: var(--dropshadow);

  @media print {
    display: none;
  }
}

.c-activity-input-bar__input {
  max-height: 25vh;
  position: relative;

  textarea::placeholder {
    opacity: 0.6;
    color: var(--text-2);
  }
}

.c-activity-input-bar__warning {
  position: relative; // for absolutely positioned triangle

  max-width: style.pxtorem(8*30);

  padding: var(--spacing-2);

  margin: 0 auto var(--spacing-3);

  background-color: var(--danger-light);

  border-radius: var(--br1);

  font-size: var(--fs-sm);
  color: var(--danger);
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    bottom: calc(-1 * var(--spacing-3));
    left: 50%;

    display: block;
    width: 0;
    height: 0;
    margin: auto auto auto calc(-1 * var(--spacing-3));

    border-top: var(--spacing-3) solid var(--danger-light);
    border-right: var(--spacing-3) solid transparent;
    border-left: var(--spacing-3) solid transparent;
    transform: translateX(-50%);
  }
}

.c-activity-input-bar__tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: style.pxtorem(8*45);
  padding: var(--spacing-2);
}

.c-activity-input-bar__tooltip-target {
  color: var(--link-text);
  cursor: default;
}
