.c-full-screen-modal {
  top: 50%;
  left: 50%;

  width: 70%;
  max-width: initial; // reset from bootstrap
  height: 80%;
  max-height: 830px;

  margin: 0;

  overflow: hidden;

  border-radius: var(--br2);

  transform: translate(-50%, -50%) !important;

  @media (max-height: 600px) {
    height: 100%;
  }
}

.c-full-screen-modal__head {
  display: flex;
  align-items: center;
  padding: var(--spacing-3) var(--spacing-3) var(--spacing-3) var(--spacing-6);
  background-color: var(--background-1);

  border-bottom: 1px solid var(--border-shade);
}

.c-full-screen-modal__title {
  flex: 1 1 auto;
  margin-bottom: 0;

  font-size: var(--fs-lg);
}
