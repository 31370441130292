// Layout Object
// ===
//

// Layout with fixed header/footer and full height scrollable content

.c-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden; // contains bootstrap dropdowns in header
  position: relative;

  @media print {
    height: 100%;
  }
}

.c-layout__header {
  flex: none;
}

.c-layout__body {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  align-content: stretch;
  min-height: 0; // contain nested flex items

  .row {
    width: 100%; // prevents jump on page load

    // contain long messages so they can be scrolled
    > [class*="col-"] {
      height: 100%;
    }
  }
}

// Modal Modifier
// ---

.c-layout.c--modal {
  height: 100%;

  .c-layout__body {
    overflow: auto;
  }
}
