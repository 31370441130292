.c-topic-interface-panel {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
  min-height: calc(var(--spacing-2) * 6 + var(--icon-md));

  .dropdown {
    display: inline-flex;
  }

  .c-icon-button {
    margin: 0 var(--spacing-1);

    .mobile-specific-styling & {
      margin: 0 var(--spacing-2);
    }
  }
}
