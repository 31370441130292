.appcues .appcues-active {
  // triangle
  .appcues-widget-dropdown:before {
    border-right: 10px solid transparent;
    border-bottom: 15px solid var(--white);
    border-left: 10px solid transparent;
    margin-right: 4px !important;
    top: -22px;
  }

  .sidebar-collapsed & .appcues-widget-dropdown:before,
  .sidebar-expanded & .appcues-widget-dropdown:before {
    display: none;
  }

  .sidebar-collapsed & .appcues-widget-dropdown {
    left: var(--sidebar-collapsed-width) !important;
    margin-left: 0;
    margin-top: 60px;
  }

  .sidebar-expanded & .appcues-widget-dropdown {
    left: var(--sidebar-expanded-width) !important;
    margin-left: 0;
    margin-top: 60px;
  }

  .appcues-widget-dropdown:after {
    display: none;
  }

  .appcues-widget-dropdown {
    padding: 0;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--border-1);
    border-radius: var(--br1);
    margin-left: -145px;
    margin-top: 5px;

    .appcues-widget-header {
      padding: var(--spacing-6);
      font-size: var(--fs-lg);
      font-weight: var(--fw-bold);
      border-bottom: none;
    }

    .appcues-widget-content {
      overflow-y: auto;
      padding-bottom: var(--spacing-3);

      .appcues-widget-list {
        border-bottom: none;
      }

      .appcues-widget-list li {
        padding: 0;
      }

      .appcues-widget-list li:not(.appcues-nothing-new):hover,
      li:not(.appcues-nothing-new):focus {
        background-color: var(--action-tint-10);
      }

      .appcues-widget-list li:not(.appcues-nothing-new):active {
        background-color: var(--background-shade);
      }

      .appcues-widget-list li:not(.appcues-nothing-new) {
        border-top: 1px solid var(--border-1);
      }

      .appcues-widget-list li.appcues-nothing-new {
        padding: 0 var(--spacing-6) var(--spacing-3);
      }

      .appcues-widget-list li {
        border-bottom: none;
      }

      // list item text
      .appcues-widget-list a {
        padding: var(--spacing-3) var(--spacing-6) var(--spacing-3)
          var(--spacing-7);
        outline: none;
        font-weight: var(--fw-normal);
        font-size: var(--fs);
        color: var(--text-1);
      }

      .appcues-widget-list a:hover {
        background: initial;
      }

      // list item date
      .appcues-widget-list time {
        display: none;
      }

      .appcues-unread:before {
        content: "";
        position: absolute;
        width: var(--spacing-3);
        height: var(--spacing-3);
        background-color: var(--danger);
        border: var(--bw2) solid var(--danger);
        border-radius: 50%;
        // padding-top + half of font size
        top: calc(var(--spacing-3) + calc(#{var(--fs) / 2}));
        // padding-left - margin - badge width
        left: calc(var(--spacing-6) - var(--spacing-2) - var(--spacing-3));
      }
    }
  }
}
