// New (as of Feb 2023) "Harbour" colour scheme

:root {
  --harbour-black: #000;
  --harbour-white: #fafafa;

  --harbour-grey-100: #1a1a1a;
  --harbour-grey-90: #333333;
  --harbour-grey-80: #4d4d4d;
  --harbour-grey-70: #666666;
  --harbour-grey-60: #808080;
  --harbour-grey-50: #999999;
  --harbour-grey-40: #b3b3b3;
  --harbour-grey-30: #cccccc;
  --harbour-grey-20: #e6e6e6;
  --harbour-grey-10: #f5f5f5;
  --harbour-grey-04: #f0f0f0;

  --harbour-red-tint-grey-100: #1b191b;
  --harbour-red-tint-grey-90: #353135;
  --harbour-red-tint-grey-80: #504a50;
  --harbour-red-tint-grey-70: #6a626a;
  --harbour-red-tint-grey-60: #857b85;
  --harbour-red-tint-grey-50: #9d959d;
  --harbour-red-tint-grey-40: #b6b1b6;
  --harbour-red-tint-grey-30: #cecace;
  --harbour-red-tint-grey-20: #e7e6e7;
  --harbour-red-tint-grey-10: #f5f5f5;

  --harbour-blue-tint-grey-100: #19191c;
  --harbour-blue-tint-grey-90: #303036;
  --harbour-blue-tint-grey-80: #494952;
  --harbour-blue-tint-grey-70: #60606c;
  --harbour-blue-tint-grey-60: #797988;
  --harbour-blue-tint-grey-50: #93939f;
  --harbour-blue-tint-grey-40: #afafb7;
  --harbour-blue-tint-grey-30: #c9c9cf;
  --harbour-blue-tint-grey-20: #e5e5e7;
  --harbour-blue-tint-grey-10: #f5f5f6;

  --harbour-blue-100: #010629;
  --harbour-blue-90: #010a4a;
  --harbour-blue-80: #010e6d;
  --harbour-blue-70: #031595;
  --harbour-blue-60: #031dd3;
  --harbour-blue-50: #2441d6;
  --harbour-blue-40: #527ae1;
  --harbour-blue-30: #85a8ef;
  --harbour-blue-20: #b9cdf6;
  --harbour-blue-10: #e0e8f8;

  --harbour-violet-100: #14031b;
  --harbour-violet-90: #3f0859;
  --harbour-violet-80: #5b0c80;
  --harbour-violet-70: #7513a4;
  --harbour-violet-60: #9118ca;
  --harbour-violet-50: #ac20ee;
  --harbour-violet-40: #c251f7;
  --harbour-violet-30: #d274f7;
  --harbour-violet-20: #edc1fe;
  --harbour-violet-10: #f9eaff;

  --harbour-green-100: #011503;
  --harbour-green-90: #054e0d;
  --harbour-green-80: #096c14;
  --harbour-green-70: #0a971a;
  --harbour-green-60: #05c01b;
  --harbour-green-50: #2ae840;
  --harbour-green-40: #57ed69;
  --harbour-green-30: #8bf197;
  --harbour-green-20: #bdf4c3;
  --harbour-green-10: #ecffef;

  --harbour-red-100: #180505;
  --harbour-red-90: #450507;
  --harbour-red-80: #810d11;
  --harbour-red-70: #ad1016;
  --harbour-red-60: #f2252c;
  --harbour-red-50: #f04e54;
  --harbour-red-40: #f8878b;
  --harbour-red-30: #fbb3b6;
  --harbour-red-20: #fbd9da;
  --harbour-red-10: #fff0f0;

  --harbour-orange-100: #150a02;
  --harbour-orange-90: #542709;
  --harbour-orange-80: #874113;
  --harbour-orange-70: #bc5b1b;
  --harbour-orange-60: #f17421;
  --harbour-orange-50: #f58d49;
  --harbour-orange-40: #f7ab79;
  --harbour-orange-30: #fbc6a2;
  --harbour-orange-20: #fbdbc5;
  --harbour-orange-10: #fff5ee;

  --harbour-yellow-100: #161101;
  --harbour-yellow-90: #5e4700;
  --harbour-yellow-80: #997710;
  --harbour-yellow-70: #d5a71c;
  --harbour-yellow-60: #f8c939;
  --harbour-yellow-50: #fbd561;
  --harbour-yellow-40: #fddf83;
  --harbour-yellow-30: #fee8a6;
  --harbour-yellow-20: #fff3d0;
  --harbour-yellow-10: #fefaee;

  --harbour-pink-100: #120209;
  --harbour-pink-90: #3f0621;
  --harbour-pink-80: #710b3b;
  --harbour-pink-70: #9c0f51;
  --harbour-pink-60: #ed2080;
  --harbour-pink-50: #f64e9d;
  --harbour-pink-40: #fa7db8;
  --harbour-pink-30: #ffa8d1;
  --harbour-pink-20: #fdd9ea;
  --harbour-pink-10: #fff0f7;

  --harbour-purple-100: #0c0617;
  --harbour-purple-90: #351a67;
  --harbour-purple-80: #4c2398;
  --harbour-purple-70: #662ecd;
  --harbour-purple-60: #803bff;
  --harbour-purple-50: #9b65fe;
  --harbour-purple-40: #b78fff;
  --harbour-purple-30: #caadff;
  --harbour-purple-20: #e2d2ff;
  --harbour-purple-15: #ccb6fc;
  --harbour-purple-10: #f2ebff;
}
