@use "src/styles/utilities/_rem-transform" as style;
.c-contact-card-skeleton {
  width: style.pxtorem(8*46);
  min-height: style.pxtorem(8*14);

  background-image: linear-gradient(
      var(--background-shade) style.pxtorem(8*1.75),
      transparent 0
    ),
    linear-gradient(var(--background-shade) style.pxtorem(8*1.75), transparent 0),
    linear-gradient(var(--background-shade) style.pxtorem(8*1.75), transparent 0),
    linear-gradient(var(--background-shade) style.pxtorem(8*1.75), transparent 0);

  background-repeat: no-repeat;
  background-position: 0 style.pxtorem(8*1.75), style.pxtorem(8*17.5) style.pxtorem(8*1.75), 0 style.pxtorem(8*5.25), 0 style.pxtorem(8*8.75);
  background-size: style.pxtorem(8*15.75) style.pxtorem(8*3.5), style.pxtorem(8*21) style.pxtorem(8*3.5), style.pxtorem(8*26.25) style.pxtorem(8*3.5),
    style.pxtorem(8*22.75) style.pxtorem(8*3.5);
}
