// Searchable Tag
// ===
//

@import "../../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-searchable-tag {
  position: relative;

  display: inline-flex;
  max-width: 100%;

  padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-1);

  overflow: hidden;

  border-style: solid;
  border-width: var(--bw1);

  font-size: var(--fs-sm);

  color: var(--white);

  cursor: pointer;

  &:hover {
    .c-searchable-tag__search {
      transform: translateY(0);
    }

    // TODO: this was temporarily removed as part of hotfix SED-16007. Reinstate later in a different
    //  way that doesn't reintroduce this same minor UI bug. Same for all subsequent TODOs mentioning
    //  this JIRA ticket.
    // .c-searchable-tag__tag .c-searchable-tag__icon {
    //   opacity: 0;
    // }
  }

  &.c--touch {
    font-size: var(--fs);

    @media (pointer: fine) {
      height: 24px;

      font-size: var(--fs-sm);
    }

    @media print {
      font-size: 11pt;
    }
  }

  &.c--small {
    padding: 0 var(--spacing-1);
  }

  @media print {
    font-size: 11pt;
  }
}

.c-searchable-tag.c--job-reference {
  background-color: var(--job-tag);
  border-color: var(--job-tag);
  border-radius: var(--br);

  &:hover {
    .c-searchable-tag__label {
      // TODO: SED-16007
      // opacity: 0.8;
      opacity: 0.25;
    }
  }
}

.c-searchable-tag.c--vessel {
  background-color: var(--category-tag);
  border-color: var(--category-tag);
  border-radius: var(--br);

  &:hover {
    .c-searchable-tag__tag {
      // TODO: SED-16007
      // opacity: 0.8;
      opacity: 0.25;
    }
  }
}

.c-searchable-tag__remove {
  display: flex;
  padding: 0;

  background-color: transparent;
  border: none;

  user-select: none;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}

.c-searchable-tag__tag {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}

.c-searchable-tag__label {
  @include text-truncate();
  display: block;
  padding: 0 var(--spacing-1);
  transition: opacity 150ms ease-in-out;
}

.c-searchable-tag__search {
  position: absolute;
  top: 0;
  // TODO: SED-16007 (next line should be removed when proper fix put in)
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  transition: transform 150ms ease-in-out;
  transform: translateY(100%);

  @media (pointer: fine) {
    // TODO: SED-16007
    // padding-left: var(--spacing-2);
    padding-left: var(--spacing-6);
  }
}

.c-searchable-tag__icon {
  flex-shrink: 0;
  margin: 0 var(--spacing-2);
  color: var(--white);

  @media (pointer: fine) {
    margin: 0 var(--spacing-1);
  }
}
