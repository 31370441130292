.c-button {
  --button-color: var(--button-primary-background);
  --button-bg-color: var(--button-color);
  --button-border-color: var(--button-color);
  --button-text-color: var(--button-primary-text);
  --button-hover-color: var(--button-primary-hover);
  --button-active-color: var(--button-primary-active);

  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-2) var(--spacing-3);

  background-color: var(--button-bg-color);
  border: 1px solid var(--button-border-color);
  border-radius: var(--br);

  font-weight: var(--fw-semi);
  line-height: var(--icon-md);
  color: var(--button-text-color);
  white-space: nowrap;

  transition:
 background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;

  &:focus-visible,
  &:hover {
    --button-bg-color: var(--button-hover-color);
    --button-border-color: var(--button-hover-color);
    color: var(--button-text-color);
  }

  &:active:active {
    --button-bg-color: var(--button-active-color);
    --button-border-color: var(--button-active-color);
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    --button-color: var(--button-primary-disabled-background);
    --button-text-color: var(--button-primary-disabled-text);

    opacity: 0.5;

    cursor: not-allowed;

    &:hover {
      --button-hover-color: var(--button-color);
    }
  }
}

.c-button.c--bold {
  &:disabled {
    opacity: 1;
  }
}

.c-button.c--on-dark {
  &:disabled {
    --button-color: var(--button-primary-dark-disabled-background);
    --button-text-color: var(--button-primary-dark-disabled-text);
  }
}

.c-button.c--small {
  padding: var(--spacing-1) var(--spacing-3);

  line-height: var(--icon-sm);
}

.c-button.c--x-small {
  padding: var(--spacing-2) var(--spacing-2);
  font-size: var(--fs-sm);
  line-height: var(--icon-xs);
}

.c-button.c--wide {
  width: 100%;
}

.c-button.c--secondary {
  --button-bg-color: var(--button-secondary-background);
  --button-text-color: var(--button-secondary-text);
  --button-border-color: var(--button-secondary-border);
  --button-hover-color: var(--button-secondary-hover);
  --button-active-color: var(--button-secondary-active);

  &:active,
  &:focus-visible,
  &:hover {
    --button-bg-color: var(--button-hover-color);
    --button-border-color: var(--button-color);
    color: var(--button-text-color);
  }

  &:disabled {
    --button-text-color: var(--button-secondary-disabled-text);
    --button-border-color: var(--button-secondary-disabled-border);
    &:hover {
      --button-bg-color: transparent;
    }
  }

  &.c--danger {
    --button-text-color: var(--danger);
    --button-border-color: var(--danger);
  }
}

.c-button.c--secondary.c--on-dark {
  --button-text-color: var(--button-secondary-dark-text);
  --button-border-color: var(--button-secondary-dark-border);
  --button-hover-color: var(--button-secondary-dark-hover);

  &:active,
  &:focus-visible,
  &:hover {
    --button-border-color: var(--button-color);
    color: var(--button-text-color);
  }

  &:disabled {
    &:hover {
      --button-bg-color: transparent;
    }
  }
}

.c-button.c--link {
  --button-bg-color: var(--button-link-background);
  --button-text-color: var(--button-link-text);
  --button-border-color: var(--button-bg-color);
  --button-hover-color: var(--button-link-hover);
  --button-active-color: var(--button-link-active);

  &:active,
  &:focus-visible,
  &:hover {
    --button-border-color: transparent;
    --button-bg-color: var(--button-hover-color);
    color: var(--button-text-color);
  }

  &:disabled {
    --button-text-color: var(--button-link-disabled-text);
    &:hover {
      --button-bg-color: transparent;
    }
  }

  &.c--on-dark {
    --button-text-color: var(--button-link-dark-text);
    --button-hover-color: var(--button-link-dark-hover);
    --button-active-color: var(--button-link-dark-active);

    &:active,
    &:focus-visible,
    &:hover {
      --button-border-color: transparent;
    }

    &.c--danger {
      --button-text-color: var(--danger);
    }
  }
}

.c-button.c--toggle {
  --button-bg-color: transparent;
  --button-text-color: var(--grey-60);
  --button-border-color: none;
  --button-hover-color: var(--grey-30);
  --button-active-color: none;

  font-weight: var(--fw-normal);
  &:hover {
    --button-bg-color: var(--grey-20);
  }
  &.c--selected {
    background-color: var(--grey-20);
    color: var(--grey-60);

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: var(--grey-20);
      color: var(--grey-60);
    }
  }
}
