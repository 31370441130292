.c-select {
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-6) var(--spacing-2) var(--spacing-3);

  background-color: var(--operator-tag);

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6.414L8.707 9.707 7.293 8.293 12 3.586l4.707 4.707-1.414 1.414L12 6.414zm3.293 7.879l1.414 1.414L12 20.414l-4.707-4.707 1.414-1.414L12 17.586l3.293-3.293z' fill='%23343A40'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0 top 50%;
  border: none;
  border-radius: var(--br3);
  font-weight: var(--fw-bold);

  appearance: none;

  &:active {
    background-color: var(--action-20);
  }

  &:hover {
    background-color: var(--action-tint-10);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--action-tint-10);
  }

  &::-ms-expand {
    display: none;
  }
}
