@use "src/styles/utilities/_rem-transform" as style;

.c-activity-list {
  padding: var(--spacing-3) 0;
  margin-bottom: 0;

  list-style-type: none;
}

.c-activity-list__date-wrapper {
  position: sticky;
  top: var(--spacing-1);
  z-index: var(--backdrop-layer);

  text-align: center;
}

.c-activity-list__date {
  display: inline-block;
  padding: var(--spacing-1) var(--spacing-2);

  background-color: var(--background-2);
  border-radius: var(--br1);

  color: var(--text-2);

  @media print {
    font-size: 16px;
  }
}

.c-activity-list__item {
  position: relative;

  display: flex;
  padding-bottom: var(--spacing-3);
  margin-left: calc(
    var(--spacing-2) + calc(var(--icon-lg) / 2)
  ); // spacing plus half of icon

  border-left: var(--bw1) solid transparent;

  &:not(:last-child) {
    border-left-color: var(--border-shade);

    @media print {
      border-left-color: transparent;
    }
  }

  .c-sidebar-right-pane.c--dark & {
    border-color: var(--border-1);
  }

  @media print {
    font-size: 16px;
  }
}

.c-activity-list__item-content {
  flex: 1 1 auto;
  min-height: var(--icon-lg); // match icon height
  // same margin that is on the left of icon + margin + half of icon size
  margin-left: calc(var(--spacing-2) + var(--spacing-3) + var(--icon-lg) / 2);
}

.c-activity-list__icon {
  position: absolute;
  left: calc(-1 * var(--icon-lg) / 2 - (var(--bw1) / 2));

  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;

  width: var(--icon-lg);
  height: var(--icon-lg);

  overflow: hidden;

  background-color: var(--text-1);
  border: var(--br) solid var(--text-1);
  border-radius: 50%;
  color: var(--white);
  flex-shrink: 0;
  line-height: 1;

  &.c--assign {
    background-color: var(--yellow);
    border-color: var(--yellow);
  }

  &.c--person {
    background-color: var(--white);
    border-color: var(--person-tag);
    color: var(--person-tag);
  }

  &.c--person.c--fill {
    background-color: var(--person-tag);
    color: var(--white);
  }

  &.c--auto-share {
    background-color: var(--purple-50);
    border-color: var(--purple-50);
  }

  @media print {
    background-color: transparent;
  }
}

.c-activity-list__timestamp {
  font-size: var(--fs-sm);
  font-style: italic;
  color: var(--text-2);

  @media print {
    font-size: 12pt;
  }
}

.c-activity-list__comment {
  flex: 1 1 auto;

  padding: var(--spacing-2) var(--spacing-3);
  margin-left: calc(var(--spacing-2) + calc(var(--icon-lg) / 2));

  background-color: var(--background-1);
  border: 1px solid var(--background-1);
  border-radius: var(--br1);
  box-shadow: var(--dropshadow);

  word-break: break-word; // SED-5185 SED-5232
  white-space: pre-wrap;

  // Making sure that the person tag in the comments stays at height 18px
  // since the tags in the tag section and team tag in message compose header
  // are height 24px.
  .c-tag {
    height: style.pxtorem(8*2.25);
  }

  &.c--current {
    background-color: var(--purple-tint);
    border-color: var(--purple-20);
  }

  .c-sidebar-right-pane.c--dark & {
    color: var(--text-1);
  }

  // SED-8334 - links lack contrast in the sidebar
  a {
    color: var(--action-70);
  }

  @media print {
    max-width: 50%;
  }
}

.c-activity-list__comment-time {
  font-size: var(--fs-sm);
  font-style: italic;
  color: var(--text-2);

  @media print {
    font-size: 12pt;
  }
}

.c-activity-list__link {
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-3);

  margin-bottom: var(--spacing-2);

  background-color: var(--background-1);
  border-radius: var(--br);
  color: var(--text-1);
}

.c-activity-list__external-chip {
  display: inline-block;
  padding: 0 var(--spacing-2);

  background-color: var(--yellow-10);
  border-radius: var(--br1);

  font-size: var(--fs-xs);
  font-weight: var(--fw-semi);
  color: var(--yellow-80);
  letter-spacing: 1px;
}
