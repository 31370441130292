// Header Logo
// ===
@import "../../styles/breakpoints";

.c-header-logo {
  position: relative;

  display: block;
  max-width: 158px;
  margin-right: var(--spacing-2);

  cursor: pointer;
}
