.c-message-address-block {
  padding-top: var(--spacing-1);
}

.c-message-address-block__label {
  margin-right: var(--spacing-2);

  color: var(--text-2);
}

.c-message-address-block__row {
  display: flex;
  margin-bottom: var(--spacing-3);
}

.c-message-address-block__list {
  list-style-type: none;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
}

.c-message-address-block__item {
  display: block;
  overflow: hidden;
  padding: 3px; // need to give padding of 3px for box-shadow to not get cut off by overflow hidden
}

.c-ff-collapsible-hd {
  .c-message-address-block__row {
    align-items: baseline;

    margin-bottom: var(--spacing-1);
  }
}
