.c-snippet-insert-button {
  display: flex;

  padding: var(--spacing-1);

  background-color: var(--text-4);
  border: none;
  border-radius: var(--br1);

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background-color: var(--action-30);
    color: var(--action-90);
  }

  &:active {
    background-color: var(--action-40);

    color: var(--action-90);
    transform: scale(0.98);
  }
}
