@use "src/styles/utilities/_rem-transform" as style;
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";
@import "../../styles/breakpoints";

.c-insert-snippet {
  position: relative; // for absolutely positioned cancel and control buttons

  display: flex;
  flex-direction: column;
  max-height: style.pxtorem(8*38);
  width: style.pxtorem(8*37);
  padding: var(--spacing-2) var(--spacing-3);
  overflow: hidden;

  .c-snippet-wrapper__content & {
    padding-bottom: 0;
  }

  .c-icon-button {
    color: var(--text-4);
  }
}

.c-insert-snippet__heading-wrapper {
  display: flex;
  flex: 0 0 style.pxtorem(8*4.5); // set consistent height between view and edit screens
  align-items: center;
  height: style.pxtorem(8*5);
  margin-bottom: var(--spacing-2);
}

.c-insert-snippet__heading {
  position: absolute;
  left: 0;

  width: 100%;
  padding-top: var(--spacing-1);

  margin-bottom: var(--spacing-2);

  font-size: var(--fs);

  font-weight: var(--fw-normal);

  text-align: center;

  pointer-events: none;
}

.c-insert-snippet__category_label {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-3);

  color: var(--text-3);
  text-transform: initial;
}

.c-insert-snippet__cancel {
  position: absolute;
  top: calc(-1 * var(--spacing-1));
  left: calc(-1 * var(--spacing-1));

  padding-left: 0;

  color: var(--text-4);

  &:hover {
    color: var(--white);
  }
}

.c-insert-snippet__control {
  position: absolute;
  top: calc(-1 * var(--spacing-1));
  right: calc(-1 * var(--spacing-1));

  padding-top: var(--spacing-1);

  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
  line-height: 2;
  text-transform: uppercase;

  &:hover {
    color: var(--action-40);
  }
}

.c-insert-snippet__list_container {
  //if this height is changed, .react-autosuggest__suggestions-container--open height and min-height should also be adjusted
  height: 400px;
  padding: var(--spacing-2);
  overflow-x: hidden;
  overflow-y: auto;

  hr {
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-6);
  }
}

.c-insert-snippet__icon {
  margin-top: var(--spacing-2);

  margin-right: var(--spacing-2);
  margin-bottom: var(--spacing-2);

  color: var(--text-3);
  flex-shrink: 0;
}

.c-insert-snippet__delete {
  padding: 0;
  margin-right: var(--spacing-2);
  margin-left: calc(-1 * var(--spacing-1));

  color: var(--text-4);

  &:hover {
    color: var(--action-40);
  }
}

.c-insert-snippet__item {
  display: flex;
  align-items: center;

  width: 100%;

  padding: var(--spacing-3);

  margin-bottom: 0;

  background-color: transparent;
  border: none;

  border-radius: var(--br1);

  color: var(--text-4);

  text-align: initial;

  @media (min-width: $small) {
    padding: var(--spacing-2) 0;
  }

  &:hover {
    background-color: var(--action-tint-20);
    color: var(--white);
  }

  &.c--edit {
    &:hover {
      background-color: transparent;
      color: var(--text-4);
    }
  }

  &.c--disabled {
    opacity: 0.5;
  }

  &.c--loading {
    opacity: 0.5;
  }

  .c-insert-snippet__lists.c--edit & {
    cursor: initial;

    &:hover {
      color: inherit;
    }
  }

  .c-insert-snippet__delete-icon,
  .c-insert-snippet__delete {
    &:hover {
      color: var(--danger) !important;
    }
  }
}

.c-insert-snippet__name {
  flex: 1 1 auto;
  @include text-truncate();
}

.c-insert-snippet__lists {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: var(--spacing-3);

  list-style-type: none;
}

hr {
  margin: var(--spacing-2) 0 0;
}

.c-insert-snippet__search {
  position: relative; // for absolutely positioned search icon

  width: 100%;

  margin-bottom: var(--spacing-2);

  color: var(--text-4);

  .react-autosuggest__container {
    padding: var(--spacing-2);

    background-color: var(--background-3);
    border-radius: var(--br1);

    ::-webkit-scrollbar-thumb {
      background-color: var(--action-tint-30);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--action-tint-60); // TODO this needs fixing
    }
  }

  .c-icon.c--search-icon {
    position: absolute;
    top: calc(var(--spacing-2) + var(--spacing-1));
    left: var(--spacing-2);

    color: var(--text-3);
  }

  .react-autosuggest__input {
    padding: var(--spacing-2) var(--spacing-2) var(--spacing-2)
      calc(var(--icon-md) + var(--spacing-2));

    background-color: transparent;
    border: none;

    font-size: var(--fs);

    color: var(--text-4);

    &::placeholder {
      font-size: inherit;
      font-style: normal;
      color: var(--text-3);
    }
  }

  .react-autosuggest__input--open {
    border-radius: 0 0 var(--br1) var(--br1);
  }

  .react-autosuggest__suggestions-container {
    top: 100%;
    left: 0;

    height: style.pxtorem(8*24.5);
  }

  .react-autosuggest__suggestions-container--open {
    //Height set to fit the height of the snippet insert container box
    //change this height if the .c-insert-snippet__list_container height is changed
    //to allow this box to fit into the parent container box.
    height: style.pxtorem(8*28);
    min-height: style.pxtorem(8*28);

    background-color: var(--background-4);
    border: none;
  }

  .react-autosuggest__suggestion {
    padding: 0 var(--spacing-2);

    &--highlighted {
      color: var(--white);
    }
  }
}

.c-insert-snippet__edit {
  opacity: 0 !important; // hacky hide the disabled edit button to center title
}

.c-snippet-menu-mobile__wrapper {
  .c-snippet-insert-menu {
    width: 100%;
  }
}

.c-mobile-menu {
  .c-insert-snippet-pwa {
    width: 100%;
  }
}
