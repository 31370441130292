@use "src/styles/utilities/_rem-transform" as style;
.c-snippet-create {
  position: relative; // for absolutely positioned cancel button

  width: style.pxtorem(8*37);
  padding: var(--spacing-2) var(--spacing-3);

  color: var(--text-4);

  .c-warning-message {
    max-width: style.pxtorem(8*21);
  }
}

.c-snippet-create__cancel {
  position: absolute;
  top: calc(-1 * var(--spacing-1));
  left: calc(-1 * var(--spacing-1));

  padding-left: 0;
}

.c-snippet-create__heading {
  position: absolute;
  left: 0;

  width: 100%;
  padding-top: var(--spacing-1);

  margin-bottom: var(--spacing-2);

  font-size: var(--fs);
  text-align: center;

  pointer-events: none;
}

.c-snippet-create__input {
  width: 100%;
  margin-top: var(--spacing-1);

  background-color: var(--background-3);
  border-color: var(--background-3);
  color: var(--text-4);

  &:focus {
    background-color: var(--background-3);
    color: var(--text-4);
  }
}

.c-snippet-create__namespace_radio {
  margin-right: var(--spacing-3);

  background-color: transparent;
  border-color: var(--border-2);
  border-width: 2px;
}

.c-snippet-create__option {
  display: flex;
  align-items: center;
  padding: var(--spacing-2);

  border-radius: var(--br1);

  &.c--selected {
    background-color: var(--action-tint-20);
  }
}
