// Batch Actions Component
// ===
@use "src/styles/utilities/_rem-transform" as style;
@import "../styles/breakpoints";

.c-batch-actions-component {
  min-width: style.pxtorem(8*52);
  max-width: style.pxtorem(8*88);
  margin: 10vh auto 0;

  @media (min-width: $small) {
    position: fixed;
    z-index: var(--stage-layer);
    margin-right: var(--spacing-3);
  }

  .react-autosuggest__section-title {
    padding: var(--spacing-2) 0;
    margin-bottom: var(--spacing-2);
  }
}

.c-batch-actions-component__inner {
  position: relative;

  margin-left: var(--spacing-5);

  @media (min-width: $medium) {
    margin-left: var(--spacing-6);
  }

  border-bottom-right-radius: var(--br1);
  border-bottom-left-radius: var(--br1);
  // Arrow
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;

    width: 0;
    height: 0;

    border: 0 solid transparent;
    border-top-width: calc(var(--spacing-3) + 20px);
    border-right-color: var(--background-1);
    border-right-width: 20px;
    border-bottom-width: calc(var(--spacing-3) + 20px);

    pointer-events: none;
  }
}

.c-batch-actions-component__menu {
  display: flex;
  align-items: center;

  background-color: var(--background-1);
}

.c-batch-actions-component__drawer {
  position: relative;

  background-color: var(--white);

  border-bottom-right-radius: var(--br1);
  border-bottom-left-radius: var(--br1);
}

.c-batch-actions-component__content {
  min-height: style.pxtorem(8*8); // Space for the loading spinner
  max-height: 30vh;
  padding: var(--spacing-3);
  overflow-y: auto;

  border-bottom-right-radius: var(--br1);
  border-bottom-left-radius: var(--br1);
}

.c-batch-actions-component__selected {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: var(--spacing-3);
  line-height: 1.2;

  @media (max-width: $small) {
    .c-icon-button {
      padding: 0;
    }
  }

  .c-icon-button {
    margin-right: var(--spacing-3);
  }

  @media (min-width: $medium) {
    font-size: var(--fs-lg);
  }
}

.c-batch-actions-component__buttons {
  display: flex;
  margin: 0 var(--spacing-3);

  @media (min-width: $small) {
    padding: var(--spacing-3) 0;
  }

  .c-icon-button {
    padding: var(--spacing-3);
    margin: 0 var(--spacing-2);

    &.c--active {
      background-color: var(--white);
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.c-batch-actions-component__search {
  position: relative; // for absolutely positioned search icon

  width: 100%;
  padding: var(--spacing-3) 0;

  border-bottom-right-radius: var(--br1);
  border-bottom-left-radius: var(--br1);
  color: var(--text-2);

  .c-icon.c--search-icon {
    position: absolute;
    top: calc(var(--spacing-3) + var(--spacing-1));
    left: var(--spacing-3);
  }

  .react-autosuggest__container::before {
    top: 0;

    padding: var(--spacing-3) var(--spacing-4);

    font-size: var(--fs);
  }

  .react-autosuggest__input {
    padding: var(--spacing-2);
    padding-left: style.pxtorem(8*6);

    background-color: transparent;
    border: none;

    font-size: var(--fs);
    color: inherit;

    &::placeholder {
      opacity: 0.6;

      color: inherit;
    }
  }

  .react-autosuggest__suggestion {
    padding: var(--spacing-2);
    margin: 0 calc(-1 * var(--spacing-2));
  }

  .react-autosuggest__input--open {
    border-radius: 0 0 var(--bw2) var(--bw2);
  }

  .react-autosuggest__suggestions-container {
    padding: var(--spacing-3);

    background-color: var(--background-1);
    border: none;
  }

  .react-autosuggest__suggestions-container--open {
    top: calc(100% + var(--spacing-3));

    min-height: style.pxtorem(8*8);
    max-height: 25vh;
  }
}

.mobile-specific-styling {
  .c-batch-actions-component {
    position: fixed;
    bottom: 0;
    z-index: var(--frontrow-layer);

    width: 100vw;
    min-width: initial;
    max-width: initial;
    margin: 0;

    background: var(--white);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

    .c-batch-actions-component__inner {
      margin: 0;

      .c-batch-actions-component__menu {
        display: block;
      }

      .c-batch-actions-component__buttons {
        justify-content: space-around;
        width: 100vw;
      }

      .c-batch-actions-component__selected {
        justify-content: center;
        padding-bottom: 0;

        font-size: var(--fs);
      }

      .c-batch-actions-component__selected .c-icon-button {
        display: none;
      }

      .c-batch-actions-component__search {
        padding-bottom: 25vh; // max height of react-autosuggest__suggestions-container--open
      }
    }
  }
}
