@use "src/styles/utilities/_rem-transform" as style;
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-template-item {
  display: flex;
  width: 100%;
  padding: 0;

  background-color: transparent;

  border: none;
  color: var(--text-2);
  text-align: left;

  &:active,
  &:focus {
    outline: none;
  }
}

.c-template-item__icon {
  flex: 0 0 style.pxtorem(8*3.5);
  margin-right: var(--spacing-2);
}

.c-template-item__name {
  @include text-truncate();
  font-weight: var(--fw-bold);
  color: var(--text-1);
}

.c-template-item:hover {
  .c-template-item__name {
    color: var(--action-80);
  }
}
