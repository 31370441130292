// Variables
// ===
@import "styles/breakpoints";

// Spacing
// ---
:root {
  --spacing-0: 0;
  --spacing-1: 0.125rem; // 2px
  --spacing-2: 0.25rem; // 4px
  --spacing-3: 0.5rem; // 8px
  --spacing-4: 0.75rem; // 12px
  --spacing-5: 1rem; // 16px
  --spacing-6: 1.5rem; // 24px
  --spacing-7: 2rem; // 32px
  --spacing-8: 2.5rem; // 40px
  --spacing-9: 3rem; // 48px
  --spacing-10: 5rem; // 80px
  --spacing-11: 7.5rem; // 120px
  --spacing-12: 7.75rem; // 124px
}

// Font Sizes
// ---
:root {
  --fs-xs: 0.625rem; // 10px
  --fs-sm: 0.75rem; // 12px
  --fs: 0.875rem; // 14px
  --fs-lg: 1rem; // 16px
  --fs-xlg: 1.25rem; // 20px
  --fs-xxlg: 1.5rem; // 48px
}

// Font Weights
// ---
:root {
  --fw-normal: 400;
  --fw-semi: 500;
  --fw-bold: 700;
  --fw-ultrabold: 800;
}

// Line Heights
// ---
:root {
  --lh-header: 1.2;
}

// Layers (z-index)
// ---
// Organizes z-index usage by name. Values can be incremented/decremented
// slightly as necessary. eg. $stage-layer + 1;

:root {
  --backdrop-layer: 1; // background
  --stage-layer: 10; // icon or other ui element
  --orchestra-layer: 100; // modal shade or similar
  --frontrow-layer: 1000; // modal dialog or similar
  --usurper-layer: 2000; // notifications above modals
}

// Modal
// ---
// Consistent max width / max height setting for generic modal

:root {
  // Max width
  --modal-mw-md: 480px;
  --modal-mw-lg: 550px;
  --modal-mw-xlg: 640px;

  // Max height
  --modal-mh-lg: 80vh;
}

// Mobile
// ---
:root {
  --mobile-header-height: 3.5rem;
  --mobile-large-bottom-search: 5.25rem;
}

:root {
  // Border widths
  --bw1: 1.5px;
  --bw2: 3px;
  --bw3: 6px;
  --bw4: 8px;

  // Border radius
  --br: 2px;
  --br1: 4px;
  --br2: 8px;
  --br3: 12px;
  --br-max: 87.5rem;

  // Icon Sizing - need to match icon.tsx spec
  --icon-xs: 14px;
  --icon-sm: 18px;
  --icon-md: 24px;
  --icon-lg: 32px;
  --icon-xl: 48px;

  // Misc
  --arrow-size: 0.75rem;

  // Sidebar fixed widths
  --sidebar-collapsed-width: 4rem;
  --sidebar-expanded-width: 13.5rem;
}

:root {
  // Dropdown
  --dropdown-input-border-radius: 4px;

  .new-sedna-ds {
    --dropdown-input-border-radius: 4px;
  }

  .polaris-sedna-ds {
    --dropdown-input-border-radius: 2px;
  }
}

:root {
  // Transition
  --transition-timing-function: ease;
}
