// Font Weight Utilities
// ===

.u-fw-n {
  font-weight: var(--fw-normal);
}

.u-fw-s {
  font-weight: var(--fw-semi);
}

.u-fw-b {
  font-weight: var(--fw-bold);
}
