.u-w-100 {
  width: 100%;
}

.u-vw-100 {
  width: 100vw;
}

.u-w-min0 {
  min-width: 0;
}
