.c-button-inline {
  display: inline-block;
  padding: 0 var(--spacing-1);

  background-color: transparent;
  border: none;

  color: var(--inline-button-text);

  vertical-align: middle;

  transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: transparent;
    color: var(--inline-button-hover-text);
  }

  &:active,
  &:focus {
    background-color: transparent;
    color: var(--inline-button-active-text);
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;

    cursor: default;

    &:hover {
      color: var(--text-2);
    }
  }

  &.c--dark-theme {
    color: var(--button-link-dark-text);

    &:hover {
      color: var(--button-link-dark-hover);
    }

    &:active,
    &:focus {
      color: var(--button-link-dark-active);
    }
  }
}
