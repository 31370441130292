@use "src/styles/utilities/_rem-transform" as style;
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-document-search-message {
  position: relative;

  display: flex;
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-2);

  background-color: var(--background-1);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);

  &.c--selected {
    background-color: var(--action-10);
    border-color: var(--action-50);
    box-shadow: 0 0 0 1px var(--action-50);

    .c-document-search-item__preview-btn,
    .c-document-search-item__actions {
      background-color: var(--action-10);
    }

    .c-document-search-item__preview-btn {
      &:active,
      &:focus,
      &:hover {
        background-color: var(--action-20);
      }
    }
  }
}

.c-document-search-message__attachment {
  min-width: style.pxtorem(8*2.5);
}

.c-document-search-message__actions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  padding: var(--spacing-3);

  background-color: var(--background-1);

  border-radius: var(--br1);

  .c-document-search-message.c--selected & {
    background-color: var(--action-10);
  }
}

.c-document-search-message__item-sender {
  flex: 1 1 auto;

  font-weight: var(--fw-bold);
  @include text-truncate();
}

.c-document-search-message__item-time {
  white-space: nowrap;
}
