// Quick Filter styles
// ===
@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";

.c-quick-filter {
  display: block;

  min-width: style.pxtorem(8*5);
  padding: var(--spacing-2) var(--spacing-1);

  background-color: transparent;
  border: none;
  border-radius: 0;

  font-size: var(--fs-sm);
  line-height: initial;

  color: var(--text-4);

  text-align: center;

  @media (min-width: $medium) {
    min-width: style.pxtorem(8*7);
  }

  @media (min-width: $x-large) {
    min-width: style.pxtorem(8*9);
    padding: var(--spacing-3);
  }

  // override bootstrap btn-secondary
  &:hover {
    background-color: transparent;
    color: var(--action-50);
  }

  &.c--active {
    background-color: var(--menu-item-dark-hover-background);
  }

  &.c--active {
    color: var(--white);
  }

  &:focus {
    box-shadow: none;
  }

  .c-icon {
    display: block;
    margin: 0 auto var(--spacing-1);
  }

  // override bootstrap
  &.nav-link.nav-link {
    color: var(--text-4);

    &:hover {
      color: var(--white);
    }
  }
}

.c-quick-filter-time {
  display: block;

  min-width: style.pxtorem(8*5);
  padding: var(--spacing-2) var(--spacing-1);

  background-color: transparent;
  border: none;
  border-radius: 0;

  font-size: var(--fs-sm);
  line-height: initial;

  color: var(--text-4);

  text-align: center;

  @media (min-width: $medium) {
    min-width: style.pxtorem(8*7);
  }

  @media (min-width: $x-large) {
    min-width: style.pxtorem(8*9);
    padding: var(--spacing-3);
  }

  .c-icon {
    display: block;
    margin: 0 auto var(--spacing-1);
  }
}

.mobile-specific-styling {
  .c-quick-filter:hover {
    color: var(--text-4);
  }
}
