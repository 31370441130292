// Tippy Theme
@use "src/styles/utilities/_rem-transform" as style;

.tippy-box[data-theme="team-select"] {
  //over-ride these values as needed
  min-width: style.pxtorem(8*44);
  max-height: style.pxtorem(8*35);
  background: var(--white);
  box-shadow: var(--dropshadow-lg);
  border-radius: var(--br2);
  padding: var(--spacing-2) var(--spacing-3);
  border: style.pxtorem(8*0.06) solid rgba(0, 0, 0, 0.15);
  color: inherit;
  overflow-y: auto;

  header {
    margin: 0 var(--spacing-2) var(--spacing-3) 0;
    color: var(--text-2);
  }
}
