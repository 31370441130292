// Count badge component
// ===

.c-count-badge {
  position: relative;
  bottom: 8px;

  display: block;
  height: var(--fs);
  min-width: var(--fs);
  padding: 0 2px;

  background-color: var(--text-2);
  border-radius: 50%;
  color: var(--white);
  font-size: var(--fs-xs);
  line-height: 1.5;
  text-align: center;
}

.c-count-badge.c--activity {
  background-color: var(--danger);
}

.mobile-specific-styling {
  .c-count-badge {
    position: absolute;
    top: 0;
    left: 0;
  }
}
