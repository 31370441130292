// Colour Palette

// Import Harbour colours (new colour palette at time of writing Feb 2023)
// as a temporary measure until the theming is fully sorted out.
// New features can use css vars from here like var(--harbour-grey-200) etc.
@import "./_harbour-palette.scss";

// Grey
:root {
  --grey-05: #f8f9fa;
  --grey-10: #ebedf0;
  --grey-15: #f6f6f7;
  --grey-20: #d7dbe1;
  --grey-30: #b0b9c7;
  --grey-40: #8e9aac;
  --grey-50: #6b788c;
  --grey-60: #515f74;
  --grey-70: #344154;
  --grey-80: #262f3d;
  --grey-90: #1a202a;

  // Grey Shade
  --shade-10: rgba(30, 36, 36, 0.075);
  --shade-20: rgba(30, 36, 36, 0.2);

  // Modal overlay
  --modal-overlay: rgba(30, 36, 36, 0.5);

  // Purple

  --purple-05: #f1f0f8;
  --purple-10: #dbd9ed;
  --purple-20: #bab2db;
  --purple-30: #968dca;
  --purple-40: #7366b7;
  --purple-50: #503fa5;
  --purple-60: #413487;
  --purple-70: #3d2e7d;
  --purple-80: #2a2158;
  --purple-90: #191435;

  // Yellow

  --yellow-05: #fdfae7;
  --yellow-10: #fbf5d0;
  --yellow-20: #fae8a4;
  --yellow-30: #f7de77;
  --yellow-40: #ffd333;
  --yellow-50: #f5c945;
  --yellow-60: #d6af3b;
  --yellow-70: #b79631;
  --yellow-80: #715f25;
  --yellow-90: #332c17;

  // Red

  --red-20: #ffe4e0;
  --red-50: #d61a06;

  // Green

  --green-20: #cafde2;
  --green-30: #28c37b;
  --green-40: #0a971a;
  --green-50: #048741;
  --green-60: #4fb082;

  // Blue

  --blue-20: #ebf2f9;
  --blue-40: #87b1f4;
  --blue-50: #3b81f1;
  --blue-60: #1f67db;

  // Pink

  --pink-50: #d92a75;

  // Teal

  --teal-05: #e8faf6;
  --teal-10: #ddf7f6;
  --teal-20: #c5eded;
  --teal-30: #a0e7e7;
  --teal-40: #7adcdc;
  --teal-50: #45c4c4;
  --teal-60: #51a0a1;
  --teal-70: #138181;
  --teal-80: #056161;
  --teal-90: #004747;

  // legacy palette. these will be replaced in phase 2 of DS update

  --yellow: var(--yellow-50);
  --tag-blue: #3978bf;
  --blue-tint: #ebf2f9;
  --purple-tint: #eae1f0;
  --note-grey: #495057;
  --brand-purple: var(--purple-50);
  --brand-purple-tint: var(--purple-10);
  --brand-purple-shade: var(--purple-70);
  --action-05: var(--teal-05);
  --action-10: var(--teal-10);
  --action-20: var(--teal-20);
  --action-30: var(--teal-30);
  --action-40: var(--teal-40);
  --action-50: var(--teal-50);
  --action-60: var(--teal-60);
  --action-70: var(--teal-70);
  --action-80: var(--teal-80);
  --action-90: var(--teal-90);
  --action-tint-10: rgba(43, 169, 169, 0.15);
  --action-tint-20: rgba(43, 169, 169, 0.18);
  --action-tint-30: rgba(43, 169, 169, 0.3);
  --action-tint-60: rgba(43, 169, 169, 0.6);

  // new palette
  --primary-purple: #654aeb;
  --content-primary: #1b1537;
  --content-secondary: #49445f;
  --accent-primary: #503fa5;
  --highlight-primary: #7b61ff;
  --background-light-purple: #e5e0ff;
  --purple-100: #f8f8fc;
  --purple-400: #aca3dc;
  --purple-500: #8b7ece;
  --purple-600: #6a59c0;
  --purple-700: #503fa5;
  --purple-800: #392d76;
  --purple-900: #2d235c;
  --background-secondary: #f4f3f5;
  --grey-100: #e8e8eb;
  --grey-300: #bbb9c3;
  --grey-400: #a4a1af;
  --grey-500: #8d8a9b;
  --grey-700: #5f5b73;
  --supporting-red: #f34825;
  --supporting-blue: #00f;

  .new-sedna-ds {
    --action-05: var(--purple-05);
    --action-10: var(--purple-10);
    --action-20: var(--purple-20);
    --action-30: var(--purple-30);
    --action-40: var(--purple-40);
    --action-50: var(--purple-50);
    --action-60: var(--purple-60);
    --action-70: var(--purple-70);
    --action-80: var(--purple-80);
    --action-90: var(--purple-90);
    --action-tint-10: rgba(150, 141, 202, 0.15);
    --action-tint-20: rgba(150, 141, 202, 0.18);
    --action-tint-30: rgba(150, 141, 202, 0.3);
    --action-tint-60: rgba(150, 141, 202, 0.6);
  }

  .polaris-sedna-ds {
    --action-05: var(--purple-05);
    --action-10: var(--purple-10);
    --action-20: var(--purple-20);
    --action-30: var(--purple-30);
    --action-40: var(--purple-40);
    --action-50: var(--purple-50);
    --action-60: var(--purple-60);
    --action-70: var(--purple-70);
    --action-80: var(--purple-80);
    --action-90: var(--purple-90);
    --action-tint-10: rgba(150, 141, 202, 0.15);
    --action-tint-20: rgba(150, 141, 202, 0.18);
    --action-tint-30: rgba(150, 141, 202, 0.3);
    --action-tint-60: rgba(150, 141, 202, 0.6);
  }

  // System Palette

  --white: #fff;
  --white-10: rgba(255, 255, 255, 0.1);

  // these tag colours will be updated in phase 2 of DS updates
  --job-tag: #048741; // this is the old green
  --category-tag: #3978bf; // this is the old blue
  --person-tag: #8e59b2; // this is the old purple. we will use it until phase 2 of the DS changes
  --team-tag: #d92a75; // this is the old pink
  --asset-tag: #3978bf;
  --text-tag: var(--grey-10);
  --operator-tag: var(--grey-20);
  --danger: var(--red-50);
  --danger-light: var(--red-20);
  --warning: var(--yellow-50);
  --warning-light: var(--yellow-20);
  --success: var(--green-50);
  --success-light: var(--green-20);
  --bucket: var(--grey-20);
  --bucket-tab: var(--grey-30);

  // Typography Palette

  --text-1: var(--grey-90); // dark
  --text-2: var(--grey-50); // medium
  --text-3: var(--grey-30); // medium-light => for disabled and description
  --text-4: var(--grey-20); // light

  // Background Palette

  --background-1: var(--grey-05); // light
  --background-2: var(--grey-10); // medium
  --background-3: var(--grey-70); // medium-dark
  --background-4: var(--grey-90); // dark
  --background-shade: var(--shade-10);

  // Border Palette

  --border-1: var(--grey-20); // light
  --border-2: var(--grey-30); // medium
  --border-3: var(--grey-60); // dark
  --border-shade: var(--shade-20);

  // Light Background Sidebar
  --split-button-border: none;
  --split-button-color: var(--text-4);
  --split-button-hover-color: none;
  --icon-border-color: transparent;
  --button-primary-color: var(--white);
  --button-active-bg: var(--purple-800);
  --button-primary-bg: var(--accent-primary);
  --button-hover-bg: var(--purple-800);
  --scrollbar-bg: var(--action-tint-30);
  --scrollbar-hover-bg: var(--action-tint-60);
  --activity-banner-color: var(--content-primary);
  --activity-banner-bg: var(--white);
  --activity-hover-color: var(--white);
  --activity-hover-bg: var(--grey-100);

  // Button Palette

  --button-primary-background: var(--action-70);
  --button-primary-text: var(--white);
  --button-primary-hover: var(--action-80);
  --button-primary-active: var(--action-90);
  --button-primary-disabled-background: var(--grey-30);
  --button-primary-disabled-text: var(--text-2);
  --button-primary-dark-disabled-background: var(--grey-70);
  --button-primary-dark-disabled-text: var(--grey-30);
  --button-secondary-background: transparent;
  --button-secondary-text: var(--action-80);
  --button-secondary-disabled-text: var(--action-80);
  --button-secondary-border: var(--grey-50);
  --button-secondary-disabled-border: var(--grey-50);
  --button-secondary-hover: var(--action-tint-10);
  --button-secondary-active: var(--action-tint-30);
  --button-secondary-dark-text: var(--grey-30);
  --button-secondary-dark-border: var(--grey-70);
  --button-secondary-dark-hover: var(--action-tint-10);
  --button-link-background: transparent;
  --button-link-text: var(--action-80);
  --button-link-disabled-text: var(--action-80);
  --button-link-hover: var(--action-tint-10);
  --button-link-active: var(--action-tint-30);
  --button-link-dark-text: var(--text-4);
  --button-link-dark-hover: var(--action-tint-10);
  --button-link-dark-active: var(--action-tint-20);

  //// Icon Button Palette

  --icon-button-text: var(--grey-60);
  --icon-button-background: transparent;
  --icon-button-active-background: var(--action-20);
  --icon-button-hover-background: var(--action-10);
  --icon-button-hover-text: var(--action-60);
  --icon-button-selected-text: var(--text-4);
  --icon-button-selected-background: var(--grey-50);
  --icon-button-selected-hover-background: var(--grey-60);
  --icon-button-selected-hover-text: var(--text-3);
  --icon-button-link-text: var(--action-80);
  --icon-button-dark-background: transparent;
  --icon-button-dark-text: var(--text-4);
  --icon-button-dark-hover-text: var(--action-50);
  --icon-button-dark-hover-background: var(--action-tint-10);
  --inline-button-text: var(--icon-button-text);
  --inline-button-hover-text: var(--icon-button-hover-text);
  --inline-button-active-text: var(--action-40);

  // New Tab Button Palette

  --tab-button-hover-text: var(--action-50);

  // Checkbox Palette

  --checkbox-checked-background: var(--action-70);

  // Input Palette

  --input-background-default: var(--white);
  --input-background-disabled: var(--grey-30);
  --input-border-focus: var(--action-50);

  // Date Picker Palette

  --date-picker-today: var(--action-50);
  --date-picker-selected: var(--grey-05);
  --date-picker-range: var(--grey-20);
  --date-picker-input-background: var(--grey-20);
  --date-picker-input-focused: var(--grey-05);
  --date-picker-nav-icon: var(--grey-05);

  // Menu Palette
  --menu-item-text: var(--grey-70);
  --menu-item-active-text: var(--action-50);
  --menu-item-hover-text: var(--action-80);
  --menu-item-hover-background: var(--action-tint-10);
  --menu-item-dark-hover-background: var(--background-3);
  --menu-item-dark-hover-text: var(--white);

  // Anchor Palette

  --link-text: var(--teal-50);
  --link-text-hover: var(--teal-60);

  // Dropdown Palette

  --dropdown-input-border: var(--border-1);
  --dropdown-item-focus: none;

  // ToolTip Palette

  --tooltip-background: var(--grey-90);
  --tooltip-text: var(--white);

  // Toast Palette

  --toast-background: var(--brand-purple-shade);
  --toast-text: var(--text-4);

  // Toggle Palette

  --toggle-on-color: var(--teal-30);
  --toggle-off-color: var(--grey-20);
  --toggle-handle-on-color: #2aa2a2;
  --toggle-handle-off-color: var(--grey-50);

  // Filter Search Palette

  --filter-search-footer-background: var(--background-1);
  --filter-search-text: var(--text-2);
  --filter-search-suggestion-descrption-text: var(--text-2);
  --filter-search-placeholder-text: var(--text-2);

  // Radio Button Palette

  --radio-button-default-background: var(--background-1);
  --radio-button-default-border: var(--border-shade);
  --radio-button-checked-background: var(--action-50);
  --radio-button-checked-border: var(--action-50);
  --radio-button-active-outline: none;
  --radio-button-active-border: var(--action-50);

  // Modal Palette

  --modal-header-background: var(--background-1);
  --modal-background: #fff;

  // these palette variables have to be repeated again so that the appropriate variables
  // get applied after root variables are set
  .new-sedna-ds {
    --tag-blue: #3978bf; // TODO
    --blue-tint: #ebf2f9; // TODO

    // New System Palette

    --job-tag: #048741; // this is the old green
    --category-tag: #3978bf; // this is the old blue
    --person-tag: #8e59b2; // this is the old purple. we will use it until phase 2 of the DS changes
    --team-tag: #d92a75; // this is the old pink
    --asset-tag: #3978bf;

    // New Text Palette

    --text-1: var(--grey-90); // dark
    --text-2: var(--grey-50); // medium
    --text-3: var(--grey-30); // medium-light => for disabled and description
    --text-4: var(--grey-20); // light

    // New Background Palette

    --background-1: var(--grey-05); // light
    --background-2: var(--grey-10); // medium
    --background-3: var(--purple-80); // medium-dark
    --background-4: var(--purple-90); // dark
    --background-shade: var(--shade-10);

    // New Border Palette

    --border-1: var(--grey-20); // light
    --border-2: var(--grey-30); // medium
    --border-3: var(--grey-60); // dark
    --border-shade: var(--shade-20);

    // New Button Palette

    --button-primary-background: var(--action-50);
    --button-primary-text: var(--grey-10);
    --button-primary-hover: var(--action-70);
    --button-primary-active: var(--action-90);
    --button-primary-disabled-background: var(--grey-30);
    --button-primary-disabled-text: var(--text-2);
    --button-primary-dark-disabled-background: var(--grey-70);
    --button-primary-dark-disabled-text: var(--grey-30);
    --button-secondary-background: transparent;
    --button-secondary-text: var(--action-50);
    --button-secondary-disabled-text: var(--action-50);
    --button-secondary-border: var(--action-50);
    --button-secondary-disabled-border: var(--action-50);
    --button-secondary-hover: var(--action-tint-10);
    --button-secondary-active: var(--action-tint-30);
    --button-secondary-dark-text: var(--grey-30);
    --button-secondary-dark-border: var(--grey-70);
    --button-secondary-dark-hover: var(--action-tint-10);
    --button-link-background: transparent;
    --button-link-text: var(--action-50);
    --button-link-disabled-text: var(--action-50);
    --button-link-hover: var(--action-10);
    --button-link-active: var(--action-tint-30);
    --button-link-dark-text: var(--text-4);
    --button-link-dark-hover: var(--text-3);
    --button-link-dark-active: var(--text-3);

    // New Icon Button Palette

    --icon-button-text: var(--grey-60);
    --icon-button-background: transparent;
    --icon-button-active-background: var(
      --action-40
    ); // TODO rename these to focus

    --icon-button-hover-background: var(--action-10);
    --icon-button-hover-text: var(--grey-60);
    --icon-button-selected-text: var(--text-4);
    --icon-button-selected-background: var(--grey-50);
    --icon-button-selected-hover-background: var(--grey-60);
    --icon-button-selected-hover-text: var(--text-3);
    --icon-button-link-text: var(--action-80);
    --icon-button-dark-background: transparent;
    --icon-button-dark-text: var(--text-4);
    --icon-button-dark-hover-text: var(--action-20);
    --icon-button-dark-hover-background: var(--action-tint-10);
    --inline-button-text: var(--icon-button-text);
    --inline-button-hover-text: var(--action-50);
    --inline-button-active-text: var(--action-40);

    // New Tab Button Palette

    --tab-button-hover-text: var(--action-50);

    // New Checkbox Palette

    --checkbox-checked-background: var(--action-70);

    // New Input Palette

    --input-background-default: var(--white);
    --input-background-disabled: var(--grey-30);
    --input-border-focus: var(--action-20);

    // New Date Picker Palette

    --date-picker-today: var(--action-30);
    --date-picker-selected: var(--grey-05);
    --date-picker-range: var(--grey-20);
    --date-picker-input-background: var(--grey-20);
    --date-picker-input-focused: var(--grey-05);
    --date-picker-nav-icon: var(--grey-05);

    // New Menu Palette

    --menu-item-text: var(--grey-70);
    --menu-item-active-text: var(--action-50);
    --menu-item-hover-text: var(--action-50);
    --menu-item-hover-background: var(--action-tint-10);
    --menu-item-dark-hover-background: var(--action-80);
    --menu-item-dark-hover-text: var(--white);

    // New Anchor Palette

    --link-text: var(--blue-50);
    --link-text-hover: var(--blue-60);

    // New Dropdown Palette

    --dropdown-input-border: var(--border-1);
    --dropdown-item-focus: none;

    // New ToolTip Palette

    --tooltip-background: var(--grey-90);
    --tooltip-text: var(--white);

    // New Toast Palette

    --toast-background: var(--brand-purple-shade);
    --toast-text: var(--text-4);

    // New Toggle Palette

    --toggle-on-color: var(--purple-20);
    --toggle-off-color: var(--grey-20);
    --toggle-handle-on-color: var(--purple-50);
    --toggle-handle-off-color: var(--grey-50);

    // New Filter Search Palette

    --filter-search-footer-background: var(--background-1);
    --filter-search-text: var(--text-2);
    --filter-search-suggestion-descrption-text: var(--text-2);
    --filter-search-placeholder-text: var(--text-2);

    // New Radio Button Palette

    --radio-button-default-background: var(--background-1);
    --radio-button-default-border: var(--border-shade);
    --radio-button-checked-background: var(--action-50);
    --radio-button-checked-border: var(--action-50);
    --radio-button-active-outline: none;
    --radio-button-active-border: var(--action-50);

    // New Modal Palette

    --modal-header-background: var(--background-1);
    --modal-background: #fff;
  }

  // NEW DESIGN SYSTEM CONSISTENCY PALETTE

  // Text
  --text-primary: var(--content-primary);
  --text-secondary: var(--grey-700);

  // Utility
  --utility-disabled: #afadb9;
  --utility-white: var(--white);
  --utility-error: var(--red-50);
  --utility-success: #096c14;

  // Button
  --primary-button: var(--primary-purple);
  --primary-button-hover: #5e45dd;
  --primary-button-pressed: #523aca;

  // Indicator
  --indicator-new: #d0014d;
  --indicator-important: #0000ff;

  --calendar-primary: #0069e0;
  --calendar-secondary: #c1deff;

  // Divider
  --divider-color: #cac7ce;

  // Light tones
  --primary-light: #f6f5fe;
  --grey-hover: #f6f6f7;
  --grey-light: #e9e8eb;
  --grey-mid: #cac7ce;
  --tag-green-light: #eaf9f2;
  --tag-blue-light: #ebf3fc;
  --tag-purple-light: #f5ecfb;
  --tag-pink-light: #fde8f1;

  // Vibrant tones
  --vibrant-pink: #fe9cc5;
  --vibrant-purple: #d9a5fe;
  --vibrant-green: #9fe3c3;

  // Tags
  --tag-green: #036236;
  --tag-blue-new: #17559b;
  --tag-purple: #6e19a9;
  --tag-pink: #be0d59;

  // tag border
  --tag-border-green: #92e2bc;

  .polaris-sedna-ds {
    --tag-blue: #3978bf; // TODO
    --blue-tint: #ebf2f9; // TODO

    // New System Palette
    --job-tag: #048741; // this is the old green
    --category-tag: #3978bf; // this is the old blue
    --person-tag: #8e59b2; // this is the old purple. we will use it until phase 2 of the DS changes
    --team-tag: #d92a75; // this is the old pink
    --asset-tag: #3978bf;

    // New Text Palette

    --text-1: var(--grey-90); // dark
    --text-2: var(--grey-50); // medium
    --text-3: var(--grey-30); // medium-light => for disabled and description
    --text-4: var(--grey-20); // light

    // New Background Palette

    --background-1: var(--grey-05); // light
    --background-2: var(--grey-10); // medium
    --background-3: var(--purple-80); // medium-dark
    --background-4: var(--purple-90); // dark
    --background-shade: var(--shade-10);

    // New Border Palette

    --border-1: var(--grey-20); // light
    --border-2: var(--grey-30); // medium
    --border-3: var(--grey-60); // dark
    --border-shade: var(--shade-20);

    // New Button Palette

    --button-primary-background: var(--primary-button);
    --button-primary-text: var(--utility-white);
    --button-primary-hover: var(--primary-button-hover);
    --button-primary-active: var(--primary-button-pressed);
    --button-primary-disabled-background: var(--utility-disabled);
    --button-primary-disabled-text: var(--text-secondary);
    --button-primary-dark-disabled-background: var(--grey-70);
    --button-primary-dark-disabled-text: var(--grey-30);
    --button-secondary-background: transparent;
    --button-secondary-text: var(--primary-button);
    --button-secondary-disabled-text: var(--utility-disabled);
    --button-secondary-border: var(--primary-button);
    --button-secondary-disabled-border: var(--utility-disabled);
    --button-secondary-hover: var(--primary-light);
    --button-secondary-active: var(--primary-button-pressed);
    --button-secondary-dark-text: var(--grey-30);
    --button-secondary-dark-border: var(--grey-70);
    --button-secondary-dark-hover: var(--action-tint-10);
    --button-link-background: transparent;
    --button-link-text: var(--primary-button);
    --button-link-disabled-text: var(--utility-disabled);
    --button-link-hover: var(--primary-light);
    --button-link-active: var(--primary-button-pressed);
    --button-link-dark-text: var(--text-4);
    --button-link-dark-hover: var(--text-3);
    --button-link-dark-active: var(--text-3);

    // New Icon Button Palette

    --icon-button-text: var(--text-secondary);
    --icon-button-background: transparent;
    --icon-button-active-background: var(
      --action-40
    ); // TODO rename these to focus
    --icon-button-hover-background: var(--primary-light);
    --icon-button-hover-text: var(--text-secondary);
    --icon-button-selected-text: var(--primary-button);
    --icon-button-selected-background: var(--primary-light);
    --icon-button-selected-hover-background: var(--primary-light);
    --icon-button-selected-hover-text: var(--primary-button);
    --icon-button-link-text: var(--text-secondary);
    --icon-button-dark-background: transparent;
    --icon-button-dark-text: var(--text-4);
    --icon-button-dark-hover-text: var(--primary-button);
    --icon-button-dark-hover-background: var(--primary-light);
    --inline-button-text: var(--icon-button-text);
    --inline-button-hover-text: var(--action-50);
    --inline-button-active-text: var(--action-40);

    // New Tab Button Palette

    --tab-button-hover-text: var(--primary-button);

    // New Checkbox Palette

    --checkbox-checked-background: var(--primary-button);

    // New Input Palette

    --input-background-default: var(--white);
    --input-background-disabled: var(--grey-30);
    --input-border-focus: var(--action-20);

    // New Date Picker Palette

    --date-picker-today: var(--action-30);
    --date-picker-selected: var(--grey-05);
    --date-picker-range: var(--grey-20);
    --date-picker-input-background: var(--grey-20);
    --date-picker-input-focused: var(--grey-05);
    --date-picker-nav-icon: var(--grey-05);

    // New Menu Palette

    --menu-item-text: var(--text-primary);
    --menu-item-active-text: var(--primary-button-pressed);
    --menu-item-hover-text: var(--text-primary);
    --menu-item-hover-background: var(--grey-hover);
    --menu-item-dark-hover-background: var(--action-80);
    --menu-item-dark-hover-text: var(--white);

    // New Anchor Palette

    --link-text: var(--primary-purple);
    --link-text-hover: var(--primary-button-hover);

    // New Dropdown Palette

    --dropdown-input-border: var(--text-secondary);
    --dropdown-item-focus: 1px solid var(--text-primary);

    // New ToolTip Palette

    --tooltip-background: var(--text-primary);
    --tooltip-text: var(--utility-white);

    // New Toast Palette

    --toast-background: var(--text-primary);
    --toast-text: var(--utility-white);

    // New Toggle Palette

    --toggle-on-color: var(--primary-button);
    --toggle-off-color: var(--text-secondary);
    --toggle-handle-on-color: var(--grey-light);
    --toggle-handle-off-color: var(--grey-light);

    // New Filter Search Palette

    --filter-search-footer-background: var(--grey-light);
    --filter-search-text: var(--text-primary);
    --filter-search-suggestion-descrption-text: var(--text-secondary);
    --filter-search-placeholder-text: var(--text-secondary);

    // New Radio Button Palette

    --radio-button-default-background: var(--utility-white);
    --radio-button-default-border: var(--text-secondary);
    --radio-button-checked-background: var(--primary-button);
    --radio-button-checked-border: var(--primary-button);
    --radio-button-active-outline: var(--text-primary);
    --radio-button-active-border: var(--primary-button);

    // New Modal Palette

    --modal-header-background: var(--background-1);
    --modal-background: rgba(27, 21, 55, 0.5);

    // Light Background Sidebar

    --split-button-border: none;
    --split-button-color: var(--primary-button);
    --split-button-hover-color: none;
    --icon-border-color: transparent;
    --button-primary-color: var(--white);
    --button-active-bg: var(--purple-800);
    --button-primary-bg: var(--accent-primary);
    --button-hover-bg: var(--purple-800);
    --scrollbar-bg: var(--action-tint-30);
    --scrollbar-hover-bg: var(--action-tint-60);
    --activity-banner-color: var(--content-primary);
    --activity-banner-bg: var(--white);
    --activity-hover-color: var(--white);
    --activity-hover-bg: var(--grey-100);
  }

  --draft: repeating-linear-gradient(
    135deg,
    var(--shade-20),
    var(--shade-20) 12px,
    transparent 12px,
    transparent 20px
  );

  // Dropshadow
  --dropshadow: 1px 1px 2px var(--shade-20);
  --dropshadow-lg: 0 4px 8px 0 var(--shade-20);

  // Dropshadow Colour
  --dropshadow-15: rgba(30, 36, 36, 0.15);
  --dropshadow-25: rgba(30, 36, 36, 0.25);
}
