// Flexbox Utility Classes
// ===

.u-flex {
  display: flex;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-auto {
  flex: 1 1 auto;
}

.u-flex-equal {
  flex: 1 1 0;
}

.u-flex-grow-0 {
  flex-grow: 0;
}

.u-flex-shrink-0 {
  flex-shrink: 0;
}

.u-inline-flex {
  display: inline-flex;
}

.u-self-end {
  align-self: flex-end;
}

.u-self-start {
  align-self: flex-start;
}

.u-self-center {
  align-self: center;
}

.u-items-center {
  align-items: center;
}

.u-items-baseline {
  align-items: baseline;
}

.u-justify-between {
  justify-content: space-between;
}

.u-justify-around {
  justify-content: space-around;
}

.u-justify-end {
  justify-content: flex-end;
}

.u-justify-center {
  justify-content: center;
}

.u-order-1 {
  order: 1;
}
