.c-tile-item {
  padding: var(--spacing-3);
  margin: var(--br) var(--br) calc(var(--br) + var(--spacing-2));

  background-color: var(--white);

  border-radius: var(--br1);

  color: var(--text-2);

  &:active,
  &:focus,
  &:hover {
    background-color: var(--action-10);
    color: var(--action-80);
  }
}

.c-tile-item.c--selected {
  background-color: var(--action-10);
  box-shadow: 0 0 0 var(--bw1) var(--action-50);
  color: var(--action-80);
}
