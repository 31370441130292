@use "src/styles/utilities/_rem-transform" as style;
@mixin font-weight() {
  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);

  text-transform: uppercase;
}

.c-list-header-v2 {
  @include font-weight();
  height: style.pxtorem(8*4.5); // prevent jump when toggling between batch actions dropdown
  padding: var(--spacing-2);

  background-color: var(--background-1);

  .sidebar-expanded &,
  .sidebar-collapsed & {
    padding-left: var(--spacing-3);
  }

  .c--new-message-container {
    display: flex;
    flex: 1 1 0;
    margin-right: var(--spacing-1);
  }
}

.c-list-header-v2__wrapper {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}

.c-list-header-v2__scroll {
  background: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.c-new-message-indicator__button {
  @include font-weight();

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 var(--spacing-1) 0 0;

  background: none;

  border: none;
  border-radius: var(--br1);
  box-shadow: none;

  color: inherit;
  vertical-align: middle;

  .c--bucket-notifier {
    background: var(--danger);
    border-radius: 50%;

    color: var(--white);
  }
}

.c-list-header-v2__count {
  @include font-weight();
  align-items: center;

  display: inline-block;

  padding: 0 var(--spacing-1) 0 0;
  vertical-align: middle;

  &.c--clickable {
    cursor: pointer;
  }
}

.c-list-header-v2__selected {
  margin-right: var(--spacing-2);

  background-color: transparent;
  border: none;
  border-radius: 0;
  color: inherit;
}

.c-list-header-v2__modal {
  flex: style.pxtorem(8*4.5);
  justify-content: center;
}
