@use "src/styles/utilities/_rem-transform" as style;
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../styles/breakpoints";

.c-header {
  background: var(--background-4);
  border-bottom: 1px solid var(--divider-color);

  .navbar {
    display: flex;
    padding: 0;
  }

  .c-header-logo {
    margin-left: var(--spacing-2);

    @media (min-width: $x-large) {
      margin-left: var(--spacing-6);
    }

    flex-shrink: 0;
  }
}

.c-header__nav-filters {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}

.c-header__nav-divider {
  display: inline-flex;

  &.c--header-divider {
    &::after {
      content: "";

      display: inline-flex;
      width: 1px;

      margin: var(--spacing-3) var(--spacing-1);

      background-color: var(--border-3);

      @media (min-width: $x-large) {
        margin: var(--spacing-3);
      }
    }
  }
}

.c-header__settings {
  flex: 0 0 auto;
  justify-content: flex-end;
  margin: 0 var(--spacing-2);

  @media (min-width: $x-large) {
    flex: 0 0 style.pxtorem(8*33);
    margin-right: var(--spacing-6);
  }
}

/* Header / Navbar / Search Bar */

.header-search {
  display: flex;

  background-color: var(--white);

  .sidebar-collapsed &,
  .sidebar-expanded & {
    padding: var(--spacing-2) 0;
  }
}

.c-quick-filter-container {
  display: flex;
}
