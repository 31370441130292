// Text/Fill colours
// ---

.u-text-light {
  color: var(--text-4);
}

.u-text-medium {
  color: var(--text-2);
}

.u-text-dark {
  color: var(--text-1);
}

.u-sed-red {
  color: var(--red-50);
}

.u-sed-person {
  color: var(--person-tag);
}

.u-sed-yel {
  color: var(--yellow);
}

.u-sed-category {
  color: var(--category-tag);
}

.u-icon-button-text {
  color: var(--icon-button-text);
}
