@import "../../styles/breakpoints";

.c-message-scheduled-bar {
  padding: var(--spacing-2);
  margin: var(--spacing-3);

  background-color: var(--action-10);
  flex-shrink: 0;

  @media (min-width: $small + 1) {
    display: flex;
    padding: 0;
  }
}

.c-message-scheduled-bar__content {
  display: flex;

  align-items: center;
  padding: var(--spacing-2) var(--spacing-3);

  @media (min-width: $small + 1) {
    flex: 1 1 auto;
  }

  .c-icon {
    color: var(--action-80);
  }
}

.c-message-scheduled-bar__btns {
  display: flex;
  align-items: center;

  .c-button {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }

  @media (min-width: $small + 1) {
    flex: 0 0 auto;

    .c-button {
      display: inline-flex;
    }
  }
}
