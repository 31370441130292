@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";
// Message List styles
// ===

.c-message-list {
  // SED-2844 Prevents scrollbar flicker in React Virtualized Grid
  .layout-bd {
    overflow: hidden;
  }

  .mobile-specific-styling & {
    flex-grow: 1;
    height: initial;
    overflow-y: auto;
  }
}

.c-message-list__header {
  height: style.pxtorem(8*4.5); // prevent jump when toggling between batch actions dropdown
  padding: var(--spacing-2);

  background-color: var(--background-1);

  .c-inline-icon-button {
    padding: 0;
  }
}

.c-message-list__count-appear {
  transform: translateY(var(--spacing-2));
}

.c-message-list__count-appear.c-message-list__count-appear-active {
  transition: transform 0.125s ease-out;
  transform: translateY(0);
}

.c-message-list__sort {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.c-message-list__priority-sort-icon {
  transform: rotate(180deg);
}

.c-message-list__full-view {
  fill: var(--white);
}

.c-message-list__store-all {
  display: flex;
  align-items: center;

  padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-1);

  background-color: transparent;
  border: none;
  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);

  line-height: inherit;
  color: inherit;
  text-transform: uppercase;

  &:hover {
    background-color: transparent;
    color: var(--action-50);
  }

  &:focus {
    box-shadow: none;
  }

  .c-icon {
    transition: transform ease-in 150ms;
  }
}

.c-message-list__indicator {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding-left: var(--spacing-1);

  border: none;
  border-radius: var(--br1);
  box-shadow: var(--dropshadow);

  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
  color: var(--text-4);
  text-transform: uppercase;
  vertical-align: middle;

  &.c--new-message {
    top: var(--spacing-3);

    background-color: var(--danger);
    color: var(--white);
  }

  &.c--new-message-notifier {
    padding: 0;

    background: none;
    box-shadow: none;
    color: inherit;
    text-transform: none;
  }

  &.c--bucket {
    padding: var(--spacing-1) var(--spacing-2);
    margin-left: var(--spacing-6);

    box-shadow: none;
    font-size: var(--fs);
    text-transform: none;
  }

  &.c--new-message.c--new-message-enter {
    opacity: 0;
    transform: translateY(-150%);
  }

  &.c--new-message.c--new-message-enter-active {
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s;
    transform: translateY(0);
  }

  &.c--new-message.c--new-message-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.c--new-message.c--new-message-exit-active {
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
    transform: translateY(-150%);
  }
}

.c-message-list__banner {
  position: absolute;
  bottom: var(--spacing-3);
  left: 2%;
  z-index: var(--frontrow-layer);

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 96%;
  height: calc(18px + var(--spacing-3) * 2);

  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);

  background-color: var(--brand-purple-shade);
  border-radius: var(--br2);

  color: var(--text-4);

  cursor: pointer;

  &.c--return-to-previous {
    bottom: var(--spacing-3);
  }

  &.c--return-to-previous.c--return-to-previous-enter {
    opacity: 0;
    transform: translateY(150%);
  }

  &.c--return-to-previous.c--return-to-previous-enter-active {
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s;
    transform: translateY(0);
  }

  &.c--return-to-previous.c--return-to-previous-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.c--return-to-previous.c--return-to-previous-exit-active {
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
    transform: translateY(150%);
  }
}

.c-message-list__banner-dismiss {
  display: flex;
  padding: var(--spacing-1);

  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: var(--text-4);

  &:active,
  &:focus,
  &:hover {
    color: var(--action-50);
    outline: none;
  }
}

.c-message-list__sort-popover-container {
  position: absolute;
  bottom: calc(var(--icon-sm) + var(--spacing-3) * 2);
  left: 0;

  min-width: 100%;
  padding: 0;
}

.c-message-list__sort-popover {
  background-color: var(--background-4);
  border-radius: var(--br2);
  color: var(--text-4);
}

.c-message-list__sort-popover-line-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-3);
}

.mobile-specific-styling {
  &.c--return-to-previous {
    bottom: calc(
      var(--icon-md) + #{style.pxtorem(8*2.625)} + 10px + var(--spacing-3)
    ); // push up by the height of the search bar
  }
  .c-message-list {
    .layout-ft {
      display: none;
    }

    .message-list {
      padding-bottom: var(--mobile-large-bottom-search);
    }
  }
}
