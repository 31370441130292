@use "src/styles/utilities/_rem-transform" as style;
.c-message-nav {
  position: absolute;
  right: var(--spacing-2);
  bottom: style.pxtorem(8*9);

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .c-attachments.c--expanded + & {
    top: -(style.pxtorem(8*10.5));
    bottom: initial;
  }
}

.c-message-nav__button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-2);

  margin-right: var(--spacing-3);

  margin-bottom: var(--spacing-3);

  background-color: var(--white);

  border: none;
  border-radius: 50%;
  box-shadow: var(--dropshadow-lg);

  transition: background-color 0.24s ease-in, color 0.24s ease-in;

  &:active {
    background-color: var(--action-20);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--action-20);
  }

  &:hover:not(:disabled) {
    background-color: var(--action-tint-10);
    color: var(--action-60);
  }

  &:disabled {
    cursor: default;
  }
}
