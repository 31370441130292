.c-activity-pane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  @media print {
    position: initial;
  }
}

.c-activity-pane__items {
  flex: 1 1 auto;
  overflow-y: auto;

  // Flow activity pane from the bottom on Mobile
  .mobile-specific-styling & {
    flex: 0 1 auto;
    margin-top: auto;
  }
}
