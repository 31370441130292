.c-add-from-sedna-footer {
  display: flex;
  flex-shrink: 0; // SED-6507
  align-items: center;

  justify-content: center;

  padding: var(--spacing-3);

  background-color: var(--side-menu-color);

  color: var(--text-4);

  &.has-selection {
    flex-direction: column;
  }
}

.c-add-from-sedna-footer__actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.c-add-from-sedna-footer__buttons {
  color: var(--text-4);
}
