// Message Compose Container
// ===

@import "../styles/breakpoints";

.v-new-message-container {
  width: 100%;
}

.mobile-specific-styling {
  .v-new-message-container {
    height: 100%;
    padding-bottom: var(--mobile-header-height);
    margin: 0;

    background-color: transparent;
    word-wrap: break-word;
  }
}
