// Global App wrapper
// ===
// Basically handles global banner layout

.c-app-container {
  display: flex;
  flex-direction: column;
  width: 100vw;

  // Making height 100% so the end of the email and attachment bar are not hidden
  // behind the mobile browser navigation in shared message view. 100vh did not work
  // because the footer in the shared view was being covered by the navigation bar on
  // mobile browsers. 100% height fits the app in between the url bar and the navigation bar.
  height: 100%;

  // SED-6979
  @media print {
    .firefox & {
      display: block;
    }
  }
}

// For iOS app, we are making the screen width inherit from the root container because
// cordova seems to implement 100vw differently compared to browsers. This causes the screen to
// cut off and make some functions inaccessible, so we are altering the width in cordova (SED-11278).
.c--cordova {
  width: inherit;
}

.c-app-container__content {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  align-content: stretch;
  min-height: 0; // contain nested flex items
  position: relative;

  // SED-6979
  @media print {
    .firefox & {
      display: block;
    }
  }
}
