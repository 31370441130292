@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";

.c-attachments {
  padding: var(--spacing-3) var(--spacing-4) 0;

  background-color: var(--background-1);
  border-top: 1px solid var(--border-shade);

  outline: none;

  &.c--extra-gutters {
    padding: var(--spacing-5) var(--spacing-6) var(--spacing-4);

    .c-button {
      margin-right: var(--spacing-5);
    }
  }
}

.c-attachments__tiles {
  position: relative;

  max-height: 40vh;
  overflow-y: auto;

  @media (min-width: $small) {
    display: flex;
    flex-wrap: wrap;
  }

  .c-message-document-tile {
    min-width: 0;

    @media (min-width: $small) {
      flex: 0 1 50%;
    }

    @media (min-width: $large) {
      flex: 0 1 33.33%;
    }

    @media (min-width: $x-large) {
      flex: 0 1 style.pxtorem(8*31.5);
    }
  }
}

.c-attachments__updownload {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-end;
}

.c-attachments__upload-menu {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

.c-attachments__upload {
  position: relative;

  padding-right: var(--spacing-3);
  padding-left: var(--spacing-3);
  margin-right: calc(-1 * var(--spacing-3));

  margin-left: calc(-1 * var(--spacing-3));

  &.c--active {
    &::after {
      content: "Attach to message";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: var(--action-20);
      border: var(--bw2) dashed var(--action-tint-20);
      opacity: 0.85;

      font-size: var(--fs-xlg);
      font-weight: var(--fw-bold);
      color: var(--text-2);

      .v-topic-compose-container &,
      .v-topic-compose-container & {
        content: "Attach to Note";
      }
    }
  }
}

.c-attachments__collapsed {
  display: flex;
  align-items: flex-start;

  .c-attachments__tiles {
    flex: 1 1 auto;
    min-width: 0;

    .mobile-specific-styling & {
      display: flex;
      padding-top: var(--spacing-2);
      padding-bottom: var(--spacing-2);
    }
  }
}

.c-attachments__icon {
  display: none;
  flex: 0 0 auto;
  padding: var(--spacing-2);

  border: 2px solid var(--text-2);
  border-radius: 50%;

  color: var(--text-2);

  @media (min-width: $small) {
    display: block;
  }

  &.c--collapsed {
    align-self: center;
    margin-top: var(--spacing-1);
  }
}

.c-attachments__bar {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-3);
}

.c-attachments__bar-topic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

.c-attachments__mobile-heading {
  padding: var(--spacing-2);

  margin-bottom: 0;

  font-size: var(--fs);
}

.c-attachments__mobile-dropzone {
  //ove dropzone focus
  &:focus {
    outline: none;
  }
}

.c-flex-doc-popover-container {
  .popover {
    border-width: 0;
  }
}

.c-flex-doc-popover {
  min-width: 250px;

  background-color: var(--background-4);
  border-radius: 8px;
}

.c-flex-doc-popover__header {
  display: flex;
  justify-content: space-between;
}

.c-flex-doc-popover__header-title {
  position: relative;

  font-size: var(--fs-lg);
  font-weight: var(--fw-normal);

  color: var(--text-4);
}

.c-flex-doc-popover__vessel {
  cursor: pointer;
}

.c-flex-doc-popover__no-tags {
  margin: var(--spacing-2);

  font-size: var(--fs);

  font-weight: normal;
  color: var(--text-4);
  text-align: center;
}

// Tippy Theme
.tippy-box[data-theme="attachments"] {
  border-radius: var(--br2);
  background-color: var(--background-4);

  .tippy-content {
    padding: var(--spacing-2) var(--spacing-3);
  }
}
