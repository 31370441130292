.tippy-box[data-theme="dropdown"] {
  border-radius: var(--br1);

  .tippy-content {
    padding: 0;
  }

  @media print {
    display: none;
  }
}
