.c-snippet-menu-mobile {
  padding-bottom: var(--spacing-3);

  background-color: var(--background-4);
  border-color: var(--background-4);

  .modal-header {
    display: flex;
    align-items: center;

    border-bottom: none;
    color: var(--text-4);
  }

  .modal-title {
    flex: 1 1 auto;

    padding-left: calc(
      var(--spacing-3) * 2.46
    ); // ~= close button size for centering

    font-size: var(--fs);

    text-align: center;
  }

  .c-insert-snippet {
    width: 100%;
  }

  .c-insert-snippet__heading {
    position: initial;

    color: var(--text-4);
  }

  // Operational Data Styles

  .c-operational-data-list__list-item {
    color: var(--text-4);
  }

  .c-operational-data-list__list-item-content {
    text-align: center;
  }
}

.c-snippet-menu-mobile__wrapper {
  position: absolute;
  bottom: 0;

  width: 100%;
  max-width: 100%;
  margin: 0;

  .modal.fade & {
    transform: translate(0, 100%);
  }

  .modal.show & {
    transform: translate(0, 0);
  }
}
