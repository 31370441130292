@use "sass:math";
@use "src/styles/utilities/_rem-transform" as style;

@import "../bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../palette";
@import "../variables";
@import "../sass-variables";
@import "../styles/breakpoints";

// Global Components
// ---
@import "../styles/components/app-container";
@import "../styles/components/button-inline";
@import "../styles/components/button-inline-topic";
@import "../styles/components/layout";
@import "../styles/components/modal";
@import "../styles/components/radio";
@import "../styles/components/search-bar";
@import "../styles/components/tag";
@import "../styles/components/warning";

// Utility Classes
// ---
@import "../styles/utilities/borders";
@import "../styles/utilities/clip";
@import "../styles/utilities/colour";
@import "../styles/utilities/display";
@import "../styles/utilities/flex";
@import "../styles/utilities/font-size";
@import "../styles/utilities/font-style";
@import "../styles/utilities/font-weight";
@import "../styles/utilities/headings";
@import "../styles/utilities/height";
@import "../styles/utilities/lists";
@import "../styles/utilities/opacity";
@import "../styles/utilities/overflow";
@import "../styles/utilities/print";
@import "../styles/utilities/position";
@import "../styles/utilities/spacing";
@import "../styles/utilities/text-align";
@import "../styles/utilities/text-transform";
@import "../styles/utilities/transform";
@import "../styles/utilities/typography";
@import "../styles/utilities/width";
@import "../styles/utilities/rem-transform";

/* Base classes
 * These are basic classes (using tachyon / bs4 naming scheme) we can use on styling elements, or used by react-strap
 */

/* Layout / Grid */
.offset-sm-4-right {
  margin-left: -(math.div(100%, 3)) !important;
}

@media (min-width: 1200px) {
  .offset-xl-3-right {
    margin-left: -(100% * 0.25) !important;
  }
}

/* Simple layout structure with fixed header / footer, will take full height of parent container, giving all available spaces to -bd.
 * Usage: better use as wrapper classes, -hd, -bd, -ft must be direct child, -hd and -ft are optional
 * Code:
 * <div class="layout-hd-ft">
 *   <div class="layout-hd">(insert content)</div>
 *   <div class="layout-bd">(insert content)</div>
 *   <div class="layout-ft">(insert content)</div>
 * </div>
 */
.layout-hd-ft {
  height: 100%;
  display: flex;
  flex-direction: column;

  .layout-hd {
    border-right: 1px solid var(--divider-color);
  }

  .layout-ft {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
  }

  .layout-bd {
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
  }
}

.right-divider {
  border-right: 1px solid var(--divider-color);
}

.hidden {
  display: none !important;
}

/* Common Elements, Widgets
 * Widgets can probably have its own styles file later
 */
.empty-content-message {
  /* I thought we have this already */
  margin: style.pxtorem(8*3.5);
}

.message-teams-list .show {
  display: inline;
}

.badge-assign {
  background-color: var(--person-tag);
  color: var(--white);
}

// SED-6848
// @todo it doesn't make sense that this lives here, should be refactored
// when we refactor the list tiles
.badge-category {
  background-color: var(--category-tag);
  color: var(--white);
}

.badge-team {
  background-color: var(--team-tag);
  color: var(--white);
}

.badge-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--icon-md);
  height: var(--icon-md);
  border-radius: var(--icon-md);

  font-size: var(--fs-sm);
  font-weight: 400;
  line-height: 0;
}

/* Filter Tabs used at top of a column or list (notification)
 * this is not a search/filter bar element! (may need renaming)
 */
.filter-tabs {
  background: var(--background-1);
  border-bottom: 1px solid var(--border-1);
  border-top: 1px solid var(--background-1);
  display: flex;
  align-items: center;

  .filter-label {
    font-size: var(--fs-sm);
    display: inline-block;
  }
}

/*
SED-9025 We want to style scrollbars on browsers running in Windows
(non-firefox as custom scrollbars aren't a feature on that browser)
options are: "windows" | "mac-os" | "unix" | "linux" |
 */
.windows {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: var(--spacing-3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: var(--br1);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

/* Layout
 *
 */

// Ensure the app has the entire viewport available for layout
html,
body,
#root {
  height: 100%;
}

html {
  font-size: 100%; /* set base units to 16px */
}

body {
  /* NOTE: these styles affects login page and everything! */
  background: white;
  overflow: hidden; /* prevent background scrolling */
  color: var(--text-1);
  font-size: var(--fs);
}

.container-fluid {
  padding: 0;
}

// Bootstrap overrides

.btn,
button {
  cursor: pointer; // SED-2473
}

.btn {
  font-size: var(--fs);
  padding: var(--spacing-2) var(--spacing-3);
}

.popover {
  max-width: style.pxtorem(8*46);
  border-radius: var(--br2);
}

.dropdown {
  .dropdown-item {
    padding: var(--spacing-2) var(--spacing-6);
    font-size: var(--fs);
  }
}

.form-control {
  font-size: var(--fs);
  padding: var(--spacing-2) var(--spacing-3);
  min-height: style.pxtorem(8*4.5);
}

// AnnounceKit Override
.announcekit-widget-badge {
  display: none !important;
}
