// Message Address Draggable
// ===

.c-message-address-draggable {
  cursor: grab;

  &.c--dragging {
    cursor: grabbing;
  }
}
