.c-flexdocs-filter-bar {
  display: flex;
  flex-direction: row;

  align-items: center;

  &.c--filtering {
    background-color: var(--background-1);

    input,
    input:focus {
      background-color: var(--background-1);
      border: none;
    }
  }

  &.c--not-filtering {
    background-color: var(--background-2);
  }
}
