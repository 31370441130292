// Filter Tray Styles
// ===
// Found in header
@use "src/styles/utilities/_rem-transform" as style;

.c-filter-tray {
  position: relative; // for absolutely positioned icon

  display: flex;

  flex-grow: 1;
  align-items: center;

  .react-autosuggest__container {
    flex: 1 1 auto;
  }

  /* maybe we can somehow pass in an inverse theme instead of styling inside .filter-tray */
  .react-autosuggest__input {
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
    padding-left: calc(var(--spacing-3) * 3 + var(--spacing-2));

    background: none;
    border: none;
  }
}

.c-filter-tray__teams-icon-container {
  &::before {
    content: "";

    width: 1px;
    height: style.pxtorem(8*3.5);

    background-color: var(--border-shade);
  }

  position: relative;
  flex-grow: revert;
  display: flex;
  align-items: center;
}

.c-filter-tray__bookmark-icon-container {
  display: flex;
  align-items: center;
}

.c-filter-tray__bookmark-icon {
  margin: 0;
  &.c--active {
    color: var(--action-50);
  }
  .c-icon {
    margin-right: 0;
  }
}

.c-filter-tray__back-icon {
  align-items: center;
  padding: 0 var(--spacing-2) 0 var(--spacing-2) !important;
  border-radius: var(--br1) !important;
}

.c-filter-tray__search-container.c--sidebar-enabled {
  &::before {
    content: "";

    width: 0;
    height: 0;

    background-color: var(--white);
  }
}

.c-filter-tray__search-container {
  &::before {
    content: "";

    width: 1px;
    height: style.pxtorem(8*3.5);

    background-color: var(--border-shade);
  }

  position: relative; // for absolutely positioned icon

  display: flex;

  flex-grow: 1;
  align-items: center;

  color: var(--filter-search-placeholder-text);

  .c-filter-tray__search-icon {
    color: var(--filter-search-placeholder-text);
  }
}

.c-filter-tray__search-icon {
  position: absolute;
  left: var(--spacing-3);
}

.c-filter-tray__search-icon.c--extra-padding {
  left: var(--spacing-6);
}

.c-filter-tray__bookmarks {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-3);

  .c-filter-tray__bookmarks-remove {
    padding: 0;
    margin: 0;

    background-color: transparent;

    border-width: 0;

    outline: none;
    color: var(--text-4);
  }
}

// Active state (when filters are applied
// ---
//
// When focused or when filters are applied

.c-filter-tray.c--active {
  flex: 0 0 style.pxtorem(8*33);
}

.c-filter-tray__search-container.c-sidebar-bookmarks-search {
  flex-grow: 1;
}

.c-filter-tray__search-container.c--active {
  flex: 0 0 style.pxtorem(8*31.5);

  @media (min-width: 375px) {
    flex: 0 0 style.pxtorem(8*33);
  }
}

.c-filter-tray__action-button {
  background-color: transparent;

  border: none;
  border-radius: 0;

  color: inherit;

  &:active {
    color: var(--white);
  }

  &:focus {
    box-shadow: none;
  }
}
