// Borders
// ===

.u-br {
  border-right-style: solid;
  border-right-width: 1px;
}
.u-bl {
  border-left-style: solid;
  border-left-width: 1px;
}

// Border Colours
// ---
.u-b--shade {
  border-color: var(--border-shade);
}
