.c-template-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 var(--spacing-3) 0 var(--spacing-5);
}

.c-template-list__heading {
  margin: var(--spacing-3) 0;
  padding: var(--spacing-2) 0;

  font-size: var(--fs-lg);
  font-weight: var(--fw-bold);
  line-height: var(--icon-md);
}

.c-template-list__list {
  flex: 1 1 auto;
  overflow-y: scroll;

  outline: none;

  text-align: center;
  list-style: none;
}
