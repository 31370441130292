@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";

.c-page-header {
  display: flex;
  flex: 0 0 auto;
  align-items: center;

  padding-left: var(--spacing-3);

  background-color: var(--background-4);

  color: var(--text-4);

  white-space: nowrap;

  @media (min-width: $x-large) {
    padding-left: var(--spacing-6);
  }
}

.c-page-header__left {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  height: style.pxtorem(8*6.5); // match app header height

  .mobile-specific-styling & {
    flex: initial;
    padding-left: var(--spacing-3);
  }
}

.c-page-header__right {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--spacing-3);

  .mobile-specific-styling & {
    flex: initial;
  }
}

.c-page-header__right,
.c-page-header__left-menu {
  .c-icon-label-button {
    padding: var(--spacing-2);

    border-radius: 50%;
  }
}

.c-page-header__left-menu {
  margin-left: var(--spacing-6);

  .c-icon-label-button {
    color: var(--text-4);
  }
}

.c-page-header__title {
  flex: 1 1 0;

  margin-bottom: 0;

  font-size: var(--fs-xxlg);
  font-weight: var(--fw-normal);

  color: var(--text-4);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.c-page-header__divider {
  display: inline-block;
  padding-left: var(--spacing-3);

  border-left: 1px solid var(--border-3);
}

.mobile-specific-styling {
  .c-page-header__center {
    display: flex;
    align-items: center;
    height: style.pxtorem(8*7);
    text-align: center;
    flex: 1 1 auto;
    justify-content: center;

    a {
      color: var(--text-4);
    }
  }
  .c-page-header {
    padding-left: 0;
    z-index: var(--orchestra-layer);
    width: 100%;

    .c-button-inline {
      color: var(--text-4);
    }

    .filter-text-header {
      font-weight: var(--fw-bold);
    }

    .c-page-header__left-menu {
      margin-left: 0;
    }

    .c-page-header__right {
      .col {
        justify-content: flex-end !important;
        padding: 0;
      }
    }
  }
}
