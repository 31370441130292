@import "../../styles/breakpoints";

.c-error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: var(--spacing-3);
}

.c-error-page__explanation {
  margin-bottom: var(--spacing-6);

  font-size: var(--fs-xlg);

  @media (min-width: $small) {
    max-width: 30vw;
  }
}

.c-error-page__detail {
  margin-bottom: var(--spacing-6);

  font-size: var(--fs);

  @media (min-width: $small) {
    max-width: 30vw;
  }
}

.c-error-page__logo {
  max-width: 40vw;
  height: 12rem;

  padding-top: var(--spacing-8);
  margin-bottom: var(--spacing-6);

  color: var(--text-4);

  @media (min-width: $small) {
    max-width: 30vw;
    margin-bottom: var(--spacing-6);
  }

  .mobile-specific-styling & {
    color: var(--text-2);
  }
}

.c-error-page.c--dark {
  .c-error-page__logo {
    color: white;
  }

  .c-error-page__glyph {
    color: var(--text-3);
  }

  .c-error-page__explanation {
    color: var(--white);
  }

  .c-error-page__detail {
    color: var(--text-3);
  }
}
