@import "../styles//breakpoints";

.c-sedna-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  .c-sedna-editor__button-inline-dialog {
    position: relative;
  }

  .c-sedna-editor__link-url-input {
    position: absolute;
    left: -8px;
    top: 24px;
    color: var(--text-1);
    background-color: var(--white);
    border: 1px solid var(--border-1);
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    min-width: 240px;

    @media screen and (max-width: $small) {
      min-width: auto;
    }
  }

  @media screen and (max-width: $small) {
    font-size: 14px;
    line-height: 21px;
  }
}

.c-sedna-editor-header {
  margin: 24px 0 0;
  border-bottom: 1px solid var(--border-1);
  padding: 0 48px 24px;

  @media screen and (max-width: $small) {
    margin: 16px 0 0;
    padding: 0 16px 16px;
  }
}

.c-sedna-editor__slatejs {
  font-size: 16px;
  line-height: 26px;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
  padding: 24px 48px;

  overflow-x: hidden;

  // We can define styles here, including for the mobile case, but
  // with something like emotion more advanced CSS could be kept
  // within the component
  p {
    margin-bottom: 0;
  }

  h1 {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 16px;
  }

  .c-sedna-editor__link-url-label {
    color: var(--white);
    padding: 4px 8px;
    background-color: var(--text-1);
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    font-size: 14px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media screen and (max-width: $small) {
    padding: 24px 16px 0;
  }
}
