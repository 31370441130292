/* DayPicker styles from react-day-picker/lib/style.css */
@use "src/styles/utilities/_rem-transform" as style;

$date-picker-day-width-height: style.pxtorem(40);
$date-picker-input-line-height: 2.25;

.DayPicker {
  min-width: style.pxtorem(8*38);
  display: inline-block;
  background: var(--background-4);
  border-radius: var(--br);
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);

  .c-daypicker--light & {
    background: var(--white);
  }
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  outline: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  border-spacing: 0;
  border-collapse: collapse;
  margin: var(--spacing-5) var(--spacing-3) var(--spacing-4);
}

.DayPicker-Weekdays {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.DayPicker-WeekdaysRow {
  display: inline-flex;
}

.DayPicker-Weekday {
  display: inline-flex;
  justify-content: center;
  width: $date-picker-day-width-height;

  font-size: var(--fs-sm);
  color: var(--text-4);

  padding-bottom: var(--spacing-2);

  .c-daypicker--light & {
    color: var(--text-secondary);
  }
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
  cursor: default;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: flex;
  justify-content: center;

  .DayPicker-Day:first-of-type,
  .DayPicker-Day:last-of-type {
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--today):not(.DayPicker-Day--selected) {
      color: var(--text-secondary);
    }
  }
}

@mixin day-box($bg-color) {
  background-color: $bg-color;
  border-radius: var(--br);
}

.DayPicker-Day {
  cursor: pointer;
  line-height: 1;
  color: var(--text-4);
  font-size: var(--fs);
  height: $date-picker-day-width-height;
  width: $date-picker-day-width-height;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .c-daypicker--light & {
    border: none;
    color: var(--text-primary);
    margin: var(--spacing-2);
    height: calc(#{$date-picker-day-width-height} - var(--spacing-2) * 2);
    width: calc(#{$date-picker-day-width-height} - var(--spacing-2) * 2);
  }
}

.DayPicker-Day:not(.DayPicker-Day--outside) {
  border: 1px solid var(--border-1);

  .c-daypicker--light & {
    border: none;
  }
}

.DayPicker-Day--disabled {
  color: var(--text-2);
  cursor: default;

  .c-daypicker--light & {
    color: var(--grey-light);
  }
}

.DayPicker--interactionDisabled .DayPicker-Day,
.DayPicker-Day--outside {
  cursor: default;
}

.DayPicker-Week .DayPicker-Day--today:not(.DayPicker-Day--outside) {
  background-color: var(--purple-60);

  .c-daypicker--light & {
    font-weight: var(--fw-bold);
    @include day-box(var(--grey-light));
  }
}


.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: var(--date-picker-selected);
  border: var(--bw1) solid var(--date-picker-selected);

  :not(.c--daypicker--light) & {
    color: var(--text-1);
  }

  .c-daypicker--light & {
    color: var(--white);
    font-weight: var(--fw-bold);
    @include day-box(var(--primary-purple));
  }
}

.c-date-range-picker
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
  background-color: var(--date-picker-range);
  border: var(--bw1) solid var(--date-picker-selected);

  .c-daypicker--light & {
    font-weight: var(--fw-bold);
    @include day-box(var(--grey-hover));
  }
}

.c-date-range-picker
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end):hover {
  background-color: var(--date-picker-selected);

  .c-daypicker--light & {
    @include day-box(var(--grey-light));
  }
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: var(--date-picker-range);

  :not(.c--daypicker--light) & {
    color: var(--text-1);
  }

  .c-daypicker--light & {
    background-color: initial;

    @include day-box(var(--grey-hover));
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  .c-daypicker--light & {
    @include day-box(var(--primary-button-pressed));
  }
}

.DayPickerInput {
  display: inline-block;
  width: 100%;
}

.DayPickerInput-OverlayWrapper {
  display: inline-block;
}

// date-picker-input

.c-date-picker-input {
  display: inline-block;
  position: relative;
  width: 100%;
}

.c-date-picker-input__prefix {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);

  width: style.pxtorem(8*2);
  height: style.pxtorem(8*2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.c-date-picker-input__input {
  padding-left: 40px;
}

.c-date-picker-input__input.c--invalid {
  border-color: var(--danger);
}

// date-range-picker-input

.c-date-range-picker-input {
  display: inline-flex;
  align-items: center;
}

// date-range-input

.c-date-range-input {
  background-color: var(--date-picker-input-background);
  border: none;
  border-radius: var(--br1);
  box-shadow: inset -1px 1px 2px rgba(0, 0, 0, 0.3);
}

.c-date-range-input__input-container {
  width: style.pxtorem(8*11.5);
}

.c-date-range-input__input {
  width: 100%;
  padding: var(--spacing-2);
  border-radius: var(--br1);

  font-size: var(--fs-sm);
  font-weight: 200;
  line-height: 2.25;

  color: var(--text-1);

  background: transparent;
  border: none;
  outline: none;
}

.c-date-range-input__input.c--focused {
  background-color: var(--date-picker-input-focused);
}

.c-date-range-input__arrow {
  fill: var(--text-2);
  width: style.pxtorem(8*2);
}

.c-date-range-picker-inline__input-container {
  margin-bottom: var(--spacing-6);
}

.c-date-range-picker-inline__input-container.c-date-range-input
  .c-date-range-input__input-container {
  min-width: style.pxtorem(8*10.5);
  width: 0;
}

// month-year-select

.c-date-picker-month-year-select__select-container {
  display: flex;
  padding-top: var(--spacing-1);
  margin-left: var(--spacing-3);
  height: $date-picker-day-width-height;

  .c-daypicker--light & {
    margin-left: var(--spacing-4);
  }
}

.c-date-picker-month-year-select__title {
  font-size: var(--fs-xlg);
  color: var(--text-primary);
  font-weight: var(--fw-semi);
}

.c-date-picker-month-year-select__select {
  position: relative;
  padding: 0 var(--spacing-2);
  margin-right: var(--spacing-2);

  select {
    appearance: none;
    border: none;

    font-size: var(--fs-lg);
    background-color: transparent;
    color: var(--text-4);

    position: absolute;
    left: 0;

    cursor: pointer;
    outline: none;

    .c-daypicker--light & {
      color: var(--text-secondary);
    }
  }
}

.c-date-picker-month-year-select__select-icon {
  color: var(--text-4);

  position: absolute;
  right: 4px;
  top: 2px;

  .c-daypicker--light & {
    color: var(--text-secondary);
  }
}

.c-date-picker-month-year-select__select.c--month {
  width: style.pxtorem(8*14);

  select {
    width: style.pxtorem(8*14);
  }
}

.c-date-picker-month-year-select__select.c--year {
  width: style.pxtorem(8*8);

  select {
    width: style.pxtorem(8*8);
  }
}

// nav-bar

.c-date-picker__nav-bar {
  position: absolute;
  top: var(--spacing-3);
  height: style.pxtorem(40);
  right: 0;
  display: inline-flex;
  margin-right: var(--spacing-3);
}

.c-date-picker__nav-bar.c-date-range-picker__inline-nav-bar {
  top: calc(
    var(--spacing-2) * 2 + var(--fs-sm) * #{$date-picker-input-line-height} + var(
        --spacing-6
      ) * 2
  );
}

.c-date-picker__nav-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--date-picker-nav-icon);
  margin: 0 var(--spacing-2);

  .c-daypicker--light & {
    color: var(--text-secondary);
  }
}

// clear-button

.c-date-picker__clear-button {
  border: none;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.c-date-picker__clear-button:focus {
  outline: none;
}

.c-date-picker__clear-button.c--inner {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);

  width: style.pxtorem(8*2);
  height: style.pxtorem(8*2);
  background: transparent;
}

.c-date-picker__clear-button.c--inner:hover {
  background: var(--date-picker-input-background);
}

.c-date-picker__clear-button.c--inner .c-date-picker__clear-icon {
  fill: var(--text-2);
}

.c-date-picker__clear-button.c--outer {
  margin-left: 4px;
  width: style.pxtorem(8*4.5);
  height: style.pxtorem(8*4.5);
  background: transparent;
}

.c-date-picker__clear-button.c--outer:hover {
  background-color: var(--date-picker-input-background);
}

.c-date-picker__clear-button.c--outer .c-date-picker__clear-icon {
  fill: var(--text-2);
}

// Tippy Theme
.tippy-box[data-theme="date-picker"] {
  border-radius: var(--br);

  .tippy-content {
    padding: 0;
  }
}
