.c-warning-message {
  position: relative; // for absolutely positioned triangle

  padding: var(--spacing-2);
  margin-right: auto;

  margin-left: auto;

  background-color: var(--danger-light);

  border-radius: var(--br1);

  font-size: var(--fs-sm);
  color: var(--danger);
  text-align: center;

  &::after {
    content: "";
    position: absolute;

    left: 50%;

    display: block;
    width: 0;
    height: 0;

    margin-left: calc(-0.25 * var(--arrow-size));
    transform: translateX(-50%);
  }
}

// Caret up
// ---
.c-warning-message.c--up {
  margin-top: var(--arrow-size);

  &::after {
    top: calc(-1 * var(--arrow-size));

    border-right: var(--arrow-size) solid transparent;

    border-bottom: var(--arrow-size) solid var(--danger-light);
    border-left: var(--arrow-size) solid transparent;
  }
}

// Caret down
// ---
.c-warning-message.c--down {
  .c-warning-message.c--up {
    bottom: calc(-1 * var(--arrow-size));

    margin-top: var(--arrow-size);

    &::after {
      top: calc(-1 * var(--arrow-size));

      border-top: var(--arrow-size) solid var(--danger-light);
      border-right: var(--arrow-size) solid transparent;
      border-left: var(--arrow-size) solid transparent;
    }
  }
}
