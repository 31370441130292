@use "../../../styles/utilities/rem-transform" as style;

.c-operator-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: style.pxtorem(8*6); // magic number to prevent jump on hover
  max-width: 100%;
  height: 20px;

  padding: var(--spacing-1) var(--spacing-3);

  background-color: var(--operator-tag);

  border-radius: var(--br);

  font-size: var(--fs-sm);

  color: inherit;
}

.c-operator-tag.c--after-before-token {
  margin-right: calc(-1 * var(--spacing-2));
  min-width: style.pxtorem(8*7); // magic number to prevent jump on hover
}

.c-operator-tag.c--not {
  margin-right: calc(-1 * var(--spacing-2));
}

.c-operator-tag.c--or {
  z-index: var(--backdrop-layer);

  margin-right: calc(-1 * var(--spacing-3));
  margin-left: calc(-1 * var(--spacing-3));
}
