@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

@-moz-keyframes fadeout {
  to {
    background-color: var(--white);
  }
}

@-webkit-keyframes fadeout {
  to {
    background-color: var(--white);
  }
}

@-o-keyframes fadeout {
  to {
    background-color: var(--white);
  }
}
@keyframes fadeout {
  to {
    background-color: var(--white);
  }
}
//above is for mobile applications

.c-group-contact-tile__wrapper {
  cursor: pointer;
  margin-bottom: var(--spacing-1);
  padding: 0 var(--spacing-2);
}

.c-group-contact-tile-block {
  position: relative;
  width: 100%;
}

.c-group-contact-tile-grid {
  display: grid;
  align-items: stretch;
  border-radius: var(--br1);

  padding: var(--spacing-3) var(--spacing-3) var(--spacing-3) var(--spacing-5);
  background-color: var(--white);
  grid-template-columns: 1fr var(--spacing-6);
  grid-template-rows: 20px 20px 20px;
  grid-column-gap: var(--spacing-3);
  grid-row-gap: 0;
  color: inherit;
  // This is needed to have z-index on the tile, so that the box-shadow on the active tile is not hidden under the next tile
  position: relative;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }

  &.c--active {
    background-color: var(--action-10);
    box-shadow: 0 0 0 var(--bw1) var(--action-50);
    z-index: var(--backdrop-layer);
  }

  &.c--highlight {
    background-color: var(--action-10);
    animation: fadeout 2s forwards;
    -moz-animation: fadeout 2s forwards;
    -webkit-animation: fadeout 2s forwards;
    -o-animation: fadeout 2s forwards;
  }
}

.c-group-contact-tile__grid-section {
  display: flex;
  align-items: center;

  &.c--1 {
    grid-area: 1 / 1 / 1 / 2;
    font-weight: bold;
    justify-content: left;
    min-width: 0;
  }

  &.c--2 {
    grid-area: 2 / 1 / 2 / 2;
    color: #8e9aac;
    min-width: 0; // contain flexed children
    justify-content: left;
  }

  &.c--3 {
    grid-area: 3 / 1 / 3 / 2;
    justify-content: left;
    min-width: 0;
  }

  &.c--4 {
    grid-area: 1 / 2 / 1 / 3;
    justify-content: center;
    //transform: rotate(45deg);
  }
}

.c-group-contact-tile__subject {
  @include text-truncate();
  &.right-margin {
    margin-right: var(--spacing-2);
    @media print {
      margin-right: 4px;
    }
  }
}
