.c-nested-search-dialog {
  width: 100%;
  padding: var(--spacing-6);
}

.c-nested-search-dialog__filter-set {
  padding: var(--spacing-2) var(--spacing-3);

  margin-bottom: var(--spacing-3);

  background-color: var(--background-1);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);
}

.c-nested-search-dialog__filter-set-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-3);
}

.c-nested-search-dialog__filter-set-container {
}

.c-nested-search-dialog__filter-term {
  margin-bottom: var(--spacing-2);
}

.c-nested-search-dialog__filter-select {
  margin-bottom: var(--spacing-3);

  background-color: var(--white);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);

  // Specificity and alignment of suggestions
  .c-filter-token-select-container.c--modal
    .react-autosuggest__suggestions-container--open {
    left: -1px;

    width: calc(100% + 2px);
    max-height: 30vh;
    margin-top: -1px;
  }
}
