@use "src/styles/utilities/_rem-transform" as style;

.c-operational-data-list {
  width: style.pxtorem(8*30);
  padding: var(--spacing-2);

  display: flex;
  flex-direction: column;
  max-height: style.pxtorem(8*37);
}

.c-operational-data-list__heading {
  font-size: var(--fs);
  text-align: center;

  flex: 1 1 auto;
  margin-bottom: 0;
}

.c-operational-data-list__list {
  height: style.pxtorem(8*49);
  padding: var(--spacing-2);
  overflow-y: auto;

  list-style-type: none;

  .c-icon {
    margin-right: var(--spacing-3);

    flex-shrink: 0;
  }

  // Skeleton loading state
  &:empty {
    width: style.pxtorem(8*30);
    height: style.pxtorem(8*49);

    background-image: linear-gradient(var(--border-3) style.pxtorem(8*2), transparent 0),
      linear-gradient(var(--border-3) style.pxtorem(8*2), transparent 0);

    background-repeat: repeat-y;
    background-position: 0 style.pxtorem(8*2), style.pxtorem(8*4) style.pxtorem(8*2);
    background-size: style.pxtorem(8*2) style.pxtorem(8*4), style.pxtorem(8*28) style.pxtorem(8*4);
  }
}

.c-operational-data-list__list-item {
  display: flex;
  flex: 1 1 auto;

  cursor: pointer;

  transition: opacity 0.15s ease-in-out;

  &:hover {
    color: var(--action-40);
  }

  &.c--loading {
    opacity: 0.5;

    cursor: initial;
  }
}

.c-operational-data-list__list-item-link {
  color: var(--action-20);

  &:hover {
    color: var(--action-40);
  }
}

.c-operational-data-list__cancel {
  position: absolute;
  top: var(--spacing-1);
  left: var(--spacing-1);

  padding-left: 0;

  color: var(--text-4);

  &:hover {
    color: var(--white);
  }
}

.c-operational-data-list__header {
  display: flex;
  align-items: center;
  padding-right: calc(
    var(--icon-md) + var(--spacing-2) * 2
  ); // compensate for left side icon

  .c-icon-button:not(:hover) {
    color: var(--text-4);
  }
}

.c-operational-data-list__list-item-wrapper {
  display: flex;
  padding: var(--spacing-2);

  border-radius: var(--br1);

  &:hover {
    background-color: var(--menu-item-dark-hover-background);
    color: var(--menu-item-dark-hover-text);
  }
}
