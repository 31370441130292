@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";
@import "../../styles/breakpoints";

.c-message-document-search__results {
  background-color: var(--background-2);

  &.c-message-document-search__results.c-message-document-search__results {
    overflow-x: hidden;
    height: 80%;
  }
}
