// Modal Styles
// ===

.c-mobile-search-modal__content {
  position: absolute;
  top: var(--spacing-8);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--frontrow-layer);

  display: flex;
  flex-direction: column;

  background-color: var(--white);

  border-radius: var(--br3) var(--br3) 0 0;
  outline: none;
  transition: transform 150ms ease-in-out;
  transform: translateY(50%);

  &.ReactModal__Content--after-open {
    transform: translateY(0);
  }

  &.ReactModal__Content--before-close {
    transform: translateY(50%);
  }
}

.c-mobile-search-modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--orchestra-layer);

  background-color: rgba(30, 36, 36, 0.75);
  opacity: 0;
  transition: opacity 150ms ease-in-out;

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
}
