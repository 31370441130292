.c-settings-filter-box {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;

  svg {
    position: absolute;
    left: var(--spacing-3);
  }
}

.c-settings-filter-box__input {
  font-size: var(--fs-lg);
  padding-left: var(--spacing-8);
  border: 1px solid var(--dropdown-input-border);
}

.c-settings-filtered-term-text__active {
  background-color: var(--action-30);
}
