// Font Size Utility Classes
// ===

.u-fs-xs {
  font-size: var(--fs-xs);

  @media print {
    font-size: 10px;
  }
}
.u-fs-sm {
  font-size: var(--fs-sm);

  @media print {
    font-size: 12px;
  }
}

.u-fs-md {
  font-size: var(--fs);

  @media print {
    font-size: 14px;
  }
}

.u-fs-l {
  font-size: var(--fs-lg);

  @media print {
    font-size: 16px;
  }
}

.u-fs-xlg {
  font-size: var(--fs-xlg);

  @media print {
    font-size: 20px;
  }
}
