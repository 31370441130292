@use "src/styles/utilities/_rem-transform" as style;
.c-contact-card-search-existing {
  margin-bottom: var(--spacing-2);

  .c-search-bar__icon {
    left: var(--spacing-3);
  }

  .react-autosuggest__input {
    padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) style.pxtorem(8*6);
  }

  .react-autosuggest__suggestions-container--open {
    position: relative;
    top: initial;

    min-height: 0;
    max-height: style.pxtorem(8*26);

    border: none;
  }
}

.c-contact-card-search-existing__selected {
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-3);

  .c-message-address {
    transition: none;
  }
}
