@use "src/styles/utilities/_rem-transform" as style;

.c-bookmark-item__unread {
  top: var(--spacing-3);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 var(--spacing-1);
  margin-left: var(--spacing-6);

  background-color: var(--danger);

  border: none;
  border-radius: var(--br1);

  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
  color: var(--white);
  text-transform: uppercase;
  vertical-align: middle;

  &.c--loading {
    width: style.pxtorem(8*7);
    padding: 0;
    // border: 1px solid pink;
  }

  &.c--loading::after {
    content: "\00a0";

    display: block;

    width: 100%;
    height: 100%;

    background-color: var(--white);
    background-image: linear-gradient(
      -45deg,
      rgba(#eceeee, 0) 0,
      rgba(#e1e4e3, 0.6) 50%,
      rgba(#eceeee, 0) 100%
    );
    background-repeat: no-repeat;
    background-position: -(style.pxtorem(8*12)) 0;
    background-size: style.pxtorem(8*4);

    border: 1px solid var(--danger-light);
    border-radius: var(--br1);

    animation: loading 1s infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes loading {
    to {
      background-position: style.pxtorem(8*12) 0;
    }
  }
}
