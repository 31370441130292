@mixin c-inline-icon-button-topic-mouse-effects($color) {
  &:hover {
    background-color: lighten($color, 5%);
  }

  &:active {
    background-color: darken($color, 5%);
  }
}

.c-inline-icon-button-topic {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--spacing-3) var(--spacing-3);
  z-index: 2;
  transition: background-color, color 0.24s ease-in,
    transform 0.24s cubic-bezier(0.75, -0.5, 0, 1.75);

  color: var(--text-4);
  background-color: transparent;
  border-radius: var(--br1);
  border-color: transparent;

  vertical-align: middle;
  outline: none !important;
  text-decoration: none;
  appearance: none;

  white-space: nowrap;
  font-size: var(--fs);

  span {
    padding-right: var(--spacing-2);

    margin-left: var(--spacing-2);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover:disabled {
    background-color: initial;

    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.96);
  }

  &:active:disabled {
    transform: initial;
  }

  span {
    padding-left: 5px;
  }
}

.c-inline-icon-button-topic-active {
  background: rgba(255, 255, 255, 0.1);
}

.c-inline-icon-button-topic-primary {
  color: var(--action-60);
}

.c-inline-icon-button-topic-primary.c-inline-icon-button-topic-inverted {
  background-color: var(--action-60);
  color: rgb(255, 255, 255);
  @include c-inline-icon-button-topic-mouse-effects(
    #0e9ea1
  ); // var(--action-70)
}

.c-inline-icon-button-topic-dark {
  color: var(--text-2);
  .c-icon {
    transform: rotate(45deg);
  }
}

.c-inline-icon-button-topic-danger {
  color: #ed4e5e;
}

.c-inline-icon-button-disabled-without-opacity:disabled {
  opacity: 1 !important;
}

.mobile-specific-styling {
  .c-inline-icon-button-topic {
    display: inline-block;

    span {
      display: none;
    }
  }

  .c-attachments__upload-menu {
    .c-inline-icon-button-topic-experimental {
      padding: 0;
    }
  }
}
