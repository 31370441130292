@use "src/styles/utilities/_rem-transform" as style;
.c-search-bar {
  position: relative;

  display: flex;

  .react-autosuggest__container {
    flex: 1 1 auto;
  }

  .react-autosuggest__input {
    padding: var(--spacing-2);
    padding-left: style.pxtorem(8*4.5);

    background-color: transparent;
    border: none;

    font-size: var(--fs);
    color: inherit;

    &::placeholder {
      opacity: 0.6;

      color: inherit;
    }
  }
}

.c-search-bar__icon {
  position: absolute;
  top: var(--spacing-1);
  left: 0;

  color: var(--text-2);
}
