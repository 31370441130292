.u-truncate {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-break-word {
  word-break: break-word;
}
