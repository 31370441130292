// Icon Widget Styles
// ===

// Base styles

.c-icon {
  display: inline-flex;
  align-items: center;
  fill: currentColor;
  vertical-align: middle;
  justify-content: center;

  @media print {
    color: initial;
  }
}
