// Bookmark Saver
// ===

.c-bookmark-saver {
  display: flex;
  align-items: center;

  color: var(--text-2);

  transition: transform 0.15s ease-out;

  transform: translateX(0);
}

.c-bookmark-saver__wrapper {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin-right: var(--spacing-3);
  border-left: 2px solid var(--shade-20);
}

.c-bookmark-saver__input {
  width: initial;
  padding: var(--spacing-1) var(--spacing-2);
  margin-right: var(--spacing-2);

  &:focus {
    border-color: var(--action-50);
    outline: 0;
  }

  &::placeholder {
    opacity: 0.6;

    color: inherit;
  }
}

// Transitions when loading in
// ---

.c-bookmark-saver.c--slide-appear {
  transition: none;
  transform: translateX(-100%);
}
