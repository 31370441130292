// Display Search Tokens
// ===
// Used in global search bar and in Add From Sedna document search
@use "src/styles/utilities/_rem-transform" as style;

.c-display-search-tokens {
  position: relative; // for sticky clear button

  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow: hidden;
}

.c-display-search-tokens__search-tags {
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .c-tag,
  .c-text-modifier-tag {
    margin-left: var(--spacing-2);

    vertical-align: middle;
  }

  .c-tag.c--not {
    padding-right: var(--spacing-6);
  }

  .c-tag.c--or {
    padding-right: var(--spacing-6);
    padding-left: var(--spacing-6);
  }
}
.c-display-search-tokens__search-tags-scroll {
  display: flex;
  overflow-x: auto;
  position: relative;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
}
.c-display-search-tokens__search-tags-gradient-left {
  background: linear-gradient(90deg, white, rgba(255, 255, 255, 0.001));
  left: 0;
  height: 100%;
  position: absolute;
  width: 25px;
  z-index: 10;
}
.c-display-search-tokens__search-tags-gradient-right {
  background: linear-gradient(-90deg, white, rgba(255, 255, 255, 0.001));
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  z-index: 10;
}

.c-display-search-tokens__tag {
  z-index: var(--backdrop-layer);

  display: inline-flex;

  &.c--not {
    margin-left: var(--spacing-2);
  }

  &.c--or {
    z-index: initial;
  }

  .c-operator-tag.c--after-before-token {
    margin: 0 calc(-1 * var(--spacing-3)) 0 var(--spacing-2);
  }
}

.c-display-search-tokens__tag.c--not + .c-display-search-tokens__tag {
  margin-left: calc(-1 * var(--spacing-3));

  .c-text-modifier-tag {
    margin-left: var(--spacing-2);
  }
}

.c-display-search-tokens__tag + .c-display-search-tokens__tag.c--or,
.c-display-search-tokens__tag.c--or + .c-display-search-tokens__tag {
  margin-left: calc(-1 * var(--spacing-3));
}

.c-display-search-tokens__tag.c--or + .c-display-search-tokens__tag {
  .c-tag {
    margin-left: 0;
  }
}

.c-display-search-tokens__combinators {
  display: inline-flex;
  align-items: center;
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
  margin-bottom: 0;

  .c--after,
  .c--before,
  .c--since {
    margin-left: var(--spacing-2);
  }

  &.c--or {
    position: relative;

    padding-right: var(--spacing-3);
    padding-left: calc(1.5 * var(--spacing-3));

    &::before,
    &::after {
      position: absolute;
      top: 45%;

      display: block;
      padding-right: var(--spacing-1);
      padding-left: var(--spacing-1);

      font-size: style.pxtorem(8*1.424);

      transform: translateY(-50%);
    }

    &::before {
      content: "(";
      left: var(--spacing-2);
    }

    &::after {
      content: ")";
      right: 0;
    }

    .c-display-search-tokens__tag {
      + .c-display-search-tokens__tag {
        position: relative;

        padding-left: var(--spacing-6);

        &::before {
          content: "or";
          position: absolute;
          top: 0;
          left: 0;

          display: block;
          padding-left: var(--spacing-2);

          font-size: var(--fs-lg);
        }
      }

      .tag {
        margin-left: 0;
      }
    }
  }
}

.c-display-search-tokens__filter-button-container {
  padding-left: var(--spacing-5);
}

.c-display-search-tokens__clear {
  position: sticky;
  right: 0;
  z-index: var(--stage-layer);
  background-color: var(--background-1);
}
