// Tippy Theme
.tippy-box[data-theme="snippet"] {
  border-radius: var(--br2);
  background-color: var(--background-4);

  .tippy-content {
    padding: 0;

    ::-webkit-scrollbar {
      background-color: transparent;
      width: var(--spacing-3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--action-tint-30);
      border-radius: var(--br1);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--action-tint-60); // TODO this needs fixing
    }
  }
}
