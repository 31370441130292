// Active Styles
// ===

.c-job-reference-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: var(--spacing-6) 0;
}

.c-job-reference-list-container__create {
  padding: 0 var(--spacing-3) var(--spacing-3) var(--spacing-3);

  border-bottom: 1px solid var(--border-shade);

  text-align: center;
}

.c-job-reference-list-container__list {
  flex: 1 1 auto;
  padding-top: var(--spacing-3);
}
