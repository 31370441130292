.c-tags-pane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  @media print {
    position: initial;
  }
}

.c-tags-pane__content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.c-tags-pane__footer {
  padding: 0 var(--spacing-3) var(--spacing-3);

  @media print {
    display: none;
  }
}
