// Message Address
// ===
@use "src/styles/utilities/_rem-transform" as style;
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-message-address {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding-right: var(--spacing-1);
  padding-left: var(--spacing-1);
  overflow: hidden;

  border: var(--bw1) solid;
  border-color: transparent;

  border-radius: style.pxtorem(8*18);

  font-size: var(--fs-sm);
  line-height: 1.25; // magic height where descenders don't get cut off for this font size
  color: inherit;

  vertical-align: baseline;

  transition: border 150ms ease-in-out, color 150ms ease-in-out;
}

.c-message-address__remove {
  display: block;

  padding: 0;
  margin-right: var(--spacing-1);

  background-color: transparent;

  border: none;

  font-size: var(--fs-xlg);
  line-height: 0;

  color: rgba(0, 0, 0, 0.55);

  &:focus {
    outline: none;
    color: var(--action-20);
  }

  &:hover {
    color: var(--action-50);
  }

  //The x button needs a position tweak in Chrome and Firefox
  .chrome &,
  .firefox & {
    position: relative;
    bottom: 1px;
  }

  //The x button doesn't show in Safari and is not clickable in Firefox if the height is not specified
  .safari &,
  .firefox & {
    height: 20px;
  }
}
.c-message-address__remove-all {
  position: absolute;
  top: calc(var(--spacing-1) + 1px);
  right: var(--spacing-1);
}

.c-message-address__content {
  @include text-truncate();
  display: block;
  padding: var(--spacing-1) 0;
}

.c-message-address.c--recognized {
  padding: 0 var(--spacing-2);

  background-color: var(--white);
  border-color: currentColor;
  border-radius: style.pxtorem(8*18);

  color: var(--person-tag);
}

.c-message-address.c--active {
  box-shadow: 0 0 0 1px var(--action-50);
}

// override default Reactstrap tooltip that
// would otherwise wrap long email addresses
.c-message-address__tooltip {
  white-space: nowrap;

  .tooltip-inner {
    max-width: initial;
  }
}
