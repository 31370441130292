.c-global-alert {
  background-color: var(--grey-60);
  transition: background-color 200ms ease-in-out;

  @media print {
    display: none;
  }
}

.c-global-alert__message {
  display: flex;
  align-items: center;

  justify-content: center;
  padding: var(--spacing-3);

  font-size: var(--fs-sm);
  color: var(--white);
  transition: transform 0.15s ease-out;

  transform: translateY(0);

  &.drop-in-appear {
    transform: translateY(-100%);
  }
}

.c--warning {
  background-color: var(--danger);
}

.c-global-alert__btn {
  color: var(--action-30);
}

.c--design-info {
  background-color: var(--purple-50);

  a {
    color: var(--blue-40);

    &:hover,
    &:focus,
    &:active {
      color: var(--blue-50);
    }
  }
}
