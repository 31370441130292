// Text Align Utilities
// ===

.u-ta-c {
  text-align: center;
}

.u-ta-r {
  text-align: end;
}
