// Message Compose Container
// ===
@use "src/styles/utilities/_rem-transform" as style;
@import "../styles/breakpoints";

.v-message-compose-container {
  .v-message-compose-container__compose-pane {
    display: flex;
    flex-direction: column;

    background-color: var(--white);
    box-shadow: var(--dropshadow);
  }
}

.v-message-compose-container__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: style.pxtorem(8*3.5);

  .c-icon-label-button {
    margin-bottom: var(--spacing-6);

    color: var(--text-4);

    &:hover {
      color: var(--white);
    }

    &:disabled {
      background-color: transparent;
    }
  }
}

.v-message-compose-container__meta {
  .c-inline-icon-button {
    margin: 0 auto;
  }
}

.v-message-compose-container__menu {
  display: flex;
  align-items: center;

  margin-right: var(--spacing-3);

  border-right: 1px solid var(--border-3);

  > .c-icon {
    color: var(--text-4);
  }
}

.v-message-compose-container__public-draft-tooltip {
  &.tooltip.show {
    // extra specificity
    opacity: 1;
  }

  .tooltip-inner {
    max-width: style.pxtorem(8*26);
  }
}

/* ff collapsible header */
.v-message-compose-container__compose-pane-top {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0;
}

.mobile-specific-styling {
  .v-message-compose-container {
    flex: 1 1 auto;
    margin: 0;

    background-color: transparent;
    word-wrap: break-word;

    .c-layout__body {
      overflow: auto;
    }

    .c-page-header {
      order: 1;
    }

    .c-message-compose-header__icon {
      display: none;
    }

    .react-autosuggest__input {
      padding-left: var(--spacing-2);
      margin-left: 0;
    }

    .v-message-compose-container__compose-pane {
      flex: 0 0 100%;
      max-width: 100% !important;
    }

    .c-message-address__remove {
      display: flex;
      padding: 0 var(--spacing-2);

      background-color: transparent;
      border: none;
      color: inherit;

      &:active,
      &:focus,
      &:hover {
        outline: none;
      }
    }

    .c-header-logo {
      display: none;
    }
    .c-page-header__title {
      display: none;
    }
    .v-message-compose-container__actions {
      display: none;
    }
    .c-message-compose__tab {
      display: none;
    }

    .c-message-write-pane {
      .c-snippet-wrapper {
        display: none;
      }
    }

    .c-message-write-pane__fullscreen {
      display: none;
    }
  }
  .v-message-compose-container__footer {
    z-index: var(--orchestra-layer);
  }

  /* ff collapsible header */
  .v-message-compose-container__compose-pane-top {
    // adding min height for smaller android devices because when keyboard is open, screen size becomes smaller
    // this causes attachment button to float over the editor.
    min-height: 350px;

    &.c--expanded {
      overflow: auto;
    }
  }
}
