// Print Styles
// ===
//

@media print {
  @page {
    margin: 56pt 14pt;
    size: auto;
  }

  .c-app-container {
    height: 100%;
  }

  body {
    height: auto;
    padding: 0 !important;
    margin: 0;

    font-size: 14pt;
  }

  :root {
    font-size: 14pt;
  }

  header,
  footer {
    display: none !important;
  }

  a[href]:after {
    content: "";
  }

  pre {
    font-size: 100%;
    page-break-inside: auto !important;
    border: none !important;
  }

  .c-header,
  .c-message-list,
  .c-message-read-container .message-tags-area,
  .c-message-read-container .message-teams-area,
  .c-message-toolbar,
  .c-message-read-container-actions,
  .c-message-read-container__following-label,
  .filter-tabs,
  .popover,
  .c-message-read-container__bips {
    display: none !important;
  }
  .c-message-read-container {
    height: auto;
    margin: 0 !important;
  }

  // adding this to safari as the page overflows on the the right
  // and text gets cut off.
  .safari {
    .c-message-read-container__body {
      max-width: 50%;
    }
  }

  .c-message-read-container .c-message-header h2 {
    font-size: 14pt;
  }
  .c-message-header__date {
    font-size: 12pt;
    right: 0;
    top: 0;
  }
  .c-message-address-block > p {
    margin: 0;
  }

  .c-message-address-block label,
  .c-message-address-block span {
    color: #000;
    margin-right: 4px;
  }
  .c-message-header__print-recipients,
  .c-message-address-block,
  .c-message-address__content {
    font-size: 12pt;
    color: #000;
    line-height: 1;
  }
  .c-message-header__print-recipients {
    margin-left: var(--spacing-4);
  }
  .c-message-read-container pre,
  .c-message-read-container blockquote,
  .c-message-read-container tr {
    border: none;
    white-space: pre-wrap;
    page-break-inside: auto !important;
  }

  .c-message-read-container__footer {
    display: none !important;
  }

  .c-topic-read-container__footer {
    display: none !important;
  }

  .container-fluid {
    height: initial;
  }

  .firefox {
    .c-message-read-container,
    .c-layout,
    .c-layout__body,
    .no-gutters,
    .c-message-read-container__main-ff-collapsible-hd {
      display: block !important;
    }
  }
}

.mobile-specific-styling {
  @media print {
    min-width: auto !important;
    max-width: initial !important;
    height: 100%;

    .c-mobile-layout {
      width: 100% !important;
    }

    .c-page-footer {
      display: none !important;
    }

    .c-message-header__container {
      background-color: var(--white);
    }

    .c-message-header__following {
      display: none;
    }

    .c-message-read-container__footer {
      display: none !important;
    }

    .c-message-header__date {
      font-size: 12pt;
      right: 0;
      top: 0;
      margin-right: 0;
    }

    .c-mobile-sidepanel-tabs {
      display: none;
    }
  }
}
