@use "src/styles/utilities/_rem-transform" as style;
@import "../styles/breakpoints";

//The DOM looks more or less like this:
//
//<div class="notifications-wrapper">
//  <div class="notifications-br">
//    <div class="notification notification-info-notification-visible"/>
//      <h4 class="notification-title>Title</h4>
//      <div class="notification-message">Message</div>
//      <span class="notification-dismiss">x</span>
//    </div>
//  </div>
//</div>

.notifications-bc {
  // bc == bottom center
  position: fixed;
  top: auto;
  bottom: var(--spacing-3);
  left: 0;
  z-index: var(--usurper-layer);

  width: 90%;

  @media (min-width: $small) {
    width: style.pxtorem(8*52);
  }

  height: auto;

  margin: 0 auto 0 50%;
  transform: translateX(-50%);
}

.notification {
  position: relative;

  display: flex;

  width: 100%;
  padding: var(--spacing-3) var(--spacing-3) var(--spacing-3) var(--spacing-6);

  background-color: var(--toast-background);
  border-radius: var(--br);

  opacity: 0;
  color: var(--toast-text);

  cursor: pointer;
  transition: 0.25s ease-in-out;
  transform: translateY(100%);
  will-change: transform, opacity;
}

.notification-visible {
  opacity: 1;
  transform: translateY(0);
}

.notification-hidden {
  opacity: 0;
}

.notification-dismiss {
  position: absolute;
  top: 4px;
  right: 5px;

  width: 14px;
  height: 14px;

  background-color: rgb(164, 190, 203);
  border-radius: 50%;

  font-size: var(--fs-lg);

  font-weight: bold;

  line-height: 15px;
  color: rgb(232, 240, 244);
  text-align: center;

  cursor: pointer;
}

.notification-message {
  flex: 1 1 auto;
  border-right: 1px solid var(--border-1);
  padding-right: var(--spacing-3);
  margin-right: var(--spacing-3);
}

// Custom Styles for svg icon
.notification-dismiss-icon {
  align-self: center;
  flex-shrink: 0;

  color: var(--toast-text);

  &:hover {
    color: var(--action-50);
  }
}
