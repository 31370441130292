@use "src/styles/utilities/_rem-transform" as style;

.c-mobile-sidepanel-tabs {
  position: absolute;
  top: var(--spacing-2);
  right: var(--spacing-3);

  &.verified-by-sedna-bold {
    top: style.pxtorem(8*4.5);
  }

  .c-count-badge {
    position: absolute;
    top: 0;
    left: 0;
  }

  .c-ff-collapsible-hd & {
    z-index: var(--stage-layer);
  }
}
