@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";

// Message Compose Address Search
// ===
//
// Notes:
// 1. Compensate for border in parent container
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-address-search {
  .react-autosuggest__input {
    padding: var(--spacing-2) var(--spacing-3);
    min-height: style.pxtorem(8*3.5);
    border: none;
  }

  .react-autosuggest__container {
    position: initial;

    margin-top: 0;
  }

  .react-autosuggest__suggestions-container {
    left: -1px; // 1

    width: calc(100% + 2px); //1

    padding-top: var(--spacing-2);
  }

  .react-autosuggest__suggestion {
    padding: var(--spacing-2);

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-shade);
    }
  }

  .react-autosuggest__section-title {
    padding: var(--spacing-3) var(--spacing-2) var(--spacing-2);

    font-size: var(--fs-sm);
    font-weight: var(--fw-bold);
    color: var(--text-2);
    text-transform: uppercase;
  }
}

.c-address-search__input {
  position: relative;
}

.c-address-search__tag {
  display: inline-block;
  max-width: 100%;
  margin-right: var(--spacing-1);
}

.c-address-search__selected {
  width: 90%; /* SED-5549 spacing for clear all X */
  max-height: style.pxtorem(8*10);
  padding: var(--spacing-1) var(--spacing-1) 0;
  margin-bottom: 0;
  overflow-y: auto;

  font-size: var(--fs-sm);
  list-style-type: none;

  // Nested so it only affects selected and not suggested
  .c-address-search__tag {
    margin-bottom: var(--spacing-1);
  }
}

.c-address-search__selected-collapsible-header {
  width: 90%; /* SED-5549 spacing for clear all X */
  max-height: style.pxtorem(8*9); // Cut off top of next line text to indicate there are more
  overflow-y: auto;
  padding: var(--spacing-1) var(--spacing-1) 0;
  margin-bottom: 0;

  font-size: var(--fs-sm);
  list-style-type: none;

  // Nested so it only affects selected and not suggested
  .c-address-search__tag {
    margin-bottom: var(--spacing-1);
  }

  @media (min-width: $small) {
    max-height: style.pxtorem(8*11);
  }

  @media (min-width: $x-large) {
    max-height: style.pxtorem(8*9);
  }
}

.c-address-search__more {
  position: relative;
  top: calc(-1 * var(--spacing-1));

  display: inline-block;
  padding-left: var(--spacing-2);

  .firefox & {
    top: calc(-1 * var(--spacing-2));
  }
}

.c-address-search__suggestion {
  display: flex;
  align-items: flex-start;
}

.c-address-search__suggestion-icon {
  flex: 0 0 auto;
  margin-right: var(--spacing-2);

  color: var(--text-2);
}

.c-address-search__suggestion-contact {
  flex: 1 1 auto;

  font-size: var(--fs-sm);
}

.c-address-search__suggestion-group-name {
  @include text-truncate();
  font-weight: var(--fw-bold);
}

.c-address-search__suggestion-group-count {
  flex: 1 0 auto;
  align-self: flex-end;
  padding-left: var(--spacing-2);

  font-size: var(--fs-sm);
  color: var(--text-2);

  text-align: right;
}

// Collapsed Modifier
//

.c-address-search.c--collapsed {
  .c-address-search__selected {
    overflow: hidden;
  }
}
