// Topic Read Container
// ===
//
@use "src/styles/utilities/_rem-transform" as style;
@import "../styles/breakpoints";

.c-topic-read-container {
  position: relative; // For absolutely positioned nav buttons
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: white;
  word-wrap: break-word;
  overflow: auto;

  @media (min-width: $large) {
    margin: 0 0 0 var(--spacing-3);
  }

  .c-topic-read-container__header {
    position: relative;
    min-height: fit-content;
    border-top: var(--bw2) solid transparent;

    &.c--jr {
      border-top-color: var(--job-tag);
    }

    .c-topic-read-container__meta {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: var(--spacing-3) var(--spacing-6) 0;

      .c-count-badge {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .c-topic-read-container__subject {
      font-size: 28px;
      font-weight: var(--fw-bold);
      line-height: 1.3;
      padding: var(--spacing-3) 48px;
      max-width: 800px;
      margin: 0 auto;

      span {
        color: var(--text-3);
      }
    }

    .c-topic-read-container__date {
      color: var(--text-2);
    }

    .c-topic-read-container__followers:hover {
      cursor: pointer;
    }

    .c-topic-read-container__followers {
      margin-left: var(--spacing-3);
    }
  }

  .c-topic-read-container__item {
    display: flex;

    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;

    .c-topic-read-container__body {
      flex-grow: 1;
      overflow: auto;
      min-height: style.pxtorem(8*9);
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      padding: 0;
      color: var(--text-1);

      .c-topic-read-container__body-content {
        display: inline;

        font-family: inherit;
        font-size: 16px;
        line-height: 26px;

        white-space: pre-wrap;
        padding: 24px 48px;
      }
    }

    .c-topic-read-container__footer {
      border-top: 1px solid var(--border-shade);
    }
  }
}

.mobile-specific-styling {
  .c-topic-read-container {
    height: 100%;
    margin: 0;

    .c-topic-read-container__meta .c-topic-read-container__activity {
      display: initial;
      position: relative;
    }

    .c-topic-read-container__subject {
      padding: var(--spacing-6) var(--spacing-6) 0;
    }

    .c-topic-read-container__followers {
      padding: 0 var(--spacing-2);
    }

    .c-topic-read-container__body {
      padding: 0;

      .c-topic-read-container__body-content {
        padding: 24px 21px 0;
      }
    }
  }
}
