// Icon Button
// ===
// Takes an icon and text

.c-icon-label-button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
  color: inherit;
  text-transform: uppercase;

  &:active,
  &:hover,
  &:focus {
    color: inherit;
  }

  &:active {
    background-color: var(--action-20);
  }

  &:hover {
    background-color: var(--action-tint-10);
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 3px var(--action-tint-10);
  }

  .c-icon {
    display: block;
    margin: 0 auto;
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      background-color: rgba(0, 0, 0, 0);

      cursor: auto;
    }
  }
}
