@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-template-preview {
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow: hidden;

  background-color: var(--white);

  &.c--panes-view {
    margin-bottom: var(--spacing-3);

    border-radius: var(--br1);

    box-shadow: var(--dropshadow);
  }
}

.c-template-preview__heading {
  display: flex;

  align-items: center;

  padding: var(--spacing-3) var(--spacing-3) 0;

  border-bottom: 1px solid var(--border-shade);
}

.c-template-preview__title {
  @include text-truncate();
  flex: 1 1 auto;

  padding-right: var(--spacing-3);

  font-size: var(--fs-xxlg);
  font-weight: var(--fw-bold);
}

.c-template-preview__actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--spacing-3);
}

.c-template-preview__content {
  flex: 1 1 auto;
  padding: var(--spacing-3);
  overflow: auto;
}
