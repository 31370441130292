@use "src/styles/utilities/_rem-transform" as style;
@import "../../styles/breakpoints";

@media (max-width: $medium) {
  .c-document-preview-modal {
    width: 100% !important;
  }
}

.mobile-specific-styling {
  .c-page-footer {
    z-index: var(--orchestra-layer);

    width: 100%;
    margin-top: auto;

    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    .col {
      padding: 0;
    }
  }

  .mobile-time-date-filters {
    position: absolute;
    top: var(--spacing-3);
    right: var(--spacing-2);
    z-index: var(--orchestra-layer);

    display: flex;
    justify-content: flex-end;

    background-color: var(--white);

    .c-quick-filter {
      span {
        display: none !important;
      }

      &.c--active {
        color: var(--text-4);
      }
    }
  }

  .mobile-teams-list {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .active {
      background: var(--background-3);
      color: var(--text-4);
    }
  }

  .mobile-dropdown-item {
    padding: var(--spacing-5) var(--spacing-3);

    background-color: var(--white);
    border-bottom: 1px solid var(--border-3);

    color: var(--text-1);

    &:active {
      background-color: var(--background-shade);
    }
  }

  // Overrides
  .c-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: var(--spacing-3);

    background-color: var(--white);
    border-top: 1px solid var(--text-4);
    border-bottom: 1px solid var(--text-4);

    .mobile-filter.btn {
      align-self: center;

      font-size: 0;
      color: var(--text-2);
    }

    .c--active {
      color: var(--text-4) !important;
    }
  }

  .c-search-mobile-interceptor {
    position: absolute;
    z-index: var(--frontrow-layer);

    display: block;
    width: 100%;

    height: style.pxtorem(8*6); // mobile search input height
    background-color: transparent;
  }
}

.c-mobile-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
}

// For iOS app, we are making the screen width inherit from the root container because
// cordova seems to implement 100vw differently compared to browsers. This causes the screen to
// cut off and make some functions inaccessible, so we are altering the width in cordova (SED-11278).
.c--cordova-mobile-layout {
  width: inherit;
}
