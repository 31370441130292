@use "src/styles/utilities/_rem-transform" as style;
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-document-search-item {
  position: relative;

  display: flex;
  align-items: center;
  padding: var(--spacing-2) var(--spacing-3);
  margin-bottom: var(--spacing-2);

  background-color: var(--white);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);

  &.c--selected {
    background-color: var(--action-10);
    border-color: var(--action-50);
    box-shadow: 0 0 0 1px var(--action-50);

    .c-document-search-item__preview-btn,
    .c-document-search-item__actions {
      background-color: var(--action-10);
    }

    .c-document-search-item__preview-btn {
      &:active,
      &:focus,
      &:hover {
        background-color: var(--action-20);
      }
    }
  }
}

.c-document-search-item__icon {
  flex-shrink: 0;

  margin-right: var(--spacing-3);

  color: var(--text-2);
}

.c-document-search-item__name {
  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
  color: var(--text-2);

  @include text-truncate();
}

.c-document-search-item__preview {
  position: absolute;
  top: 50%;
  bottom: 2px;
  left: calc(var(--spacing-6) + var(--spacing-2));

  display: flex;
  align-items: center;

  font-size: var(--fs-sm);
}

.c-document-search-item__preview-btn {
  padding: 0 var(--spacing-3) 0 0;

  background-color: var(--white);
  border: none;

  border-radius: style.pxtorem(8*3.5);
  font-weight: var(--fw-semi);
  color: var(--action-80);

  &:active,
  &:focus,
  &:hover {
    background-color: var(--action-10);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--action-tint-10);
  }
}

.c-document-search-item__preview-icon {
  display: inline-block;
  padding: 1px;
  margin-right: var(--spacing-2);

  background-color: var(--action-80);
  border: 1px solid var(--white);
  border-radius: 50%;

  color: white;
}

.c-document-search-item__actions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;

  padding-right: var(--spacing-3);
  padding-left: var(--spacing-3);

  background-color: white;
}
