// Tag Component
// ===

@use "src/styles/utilities/_rem-transform" as style;
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";
.c-tag {
  @include text-truncate();

  display: inline-flex;
  align-items: center;
  max-width: 100%;

  padding: var(--spacing-1) var(--spacing-2);

  border-color: transparent;
  border-style: solid;
  border-width: var(--bw1);
  border-radius: var(--br);

  color: inherit;

  @media (pointer: fine) {
    height: 24px;
    font-size: var(--fs-sm);
  }

  @media print {
    font-size: 11pt;
  }
}

.c-tag.c--touchscreen {
  height: 24px;
  font-size: var(--fs-sm);
}

.c-tag.c--small {
  height: initial;
  padding: 0 var(--spacing-1);
}

.c-tag.c--connected-by-or {
  position: relative;
  z-index: var(--stage-layer);
}

.c-tag__icon {
  padding: 0 var(--spacing-2);

  @media (pointer: fine) {
    padding: 0 var(--spacing-1);
  }
}

.c-tag__remove {
  display: flex;
  padding: 0 var(--spacing-2);

  background-color: transparent;
  border: none;
  color: inherit;

  // Padding should match c-tag__icon for desktop tags
  // since they toggle on hover
  @media (pointer: fine) {
    padding: 0 var(--spacing-1);
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}

.c-tag__content {
  @include text-truncate();
  display: block;
}

// Tag with icon
.c-tag.c--icon {
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-1);
}

// Modifiers
// ---

.c-tag.c--modifier {
  background-color: var(--text-tag);
  color: inherit;
}

// Person styles
// ---
.c-tag.c--person {
  background-color: var(--person-tag);
  border-color: var(--person-tag);
  color: var(--white);
}

// Outline styles
// ---

.c-tag.c--outline {
  background-color: transparent;
  border-color: inherit;

  &.c--job {
    color: var(--job-tag);
  }

  &.c--person {
    color: var(--person-tag);
  }
}

// Dashed outline styles
// ---
// Use with c--outline modifier

.c-tag.c--dashed {
  border-style: dashed;
  border-width: 1.5px;
}

// Solid styles
// ---

.c-tag.c--solid {
  color: var(--white);

  &.c--job {
    background-color: var(--job-tag);
    border-color: var(--job-tag);
  }
}
