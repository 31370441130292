// Message Pane
// ===
//
// Notes
// 1. Add spacing around message body scrollbar
@use "src/styles/utilities/_rem-transform" as style;
@import "../styles/print-styles";
@import "../styles/breakpoints";

.c-message-read-container {
  position: relative;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0; // contain nested flex items
  margin: 0;

  background: var(--white);

  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

  &.c--jr::before {
    content: "";
    position: absolute;
    top: calc(var(--bw2) * -1);
    left: 0;

    z-index: var(--backdrop-layer);

    display: block;
    width: 100%;
    height: var(--bw2);

    background: var(--job-tag);
  }

  @media (min-width: $medium) {
    // space for drop shadows
    margin-right: var(--spacing-1);
    margin-left: var(--spacing-1);
  }

  -webkit-overflow-scrolling: touch;
}

.c-message-read-container__bips {
  display: flex;
  align-items: center;
  min-height: calc(
    var(--spacing-2) * 6 + var(--icon-md)
  ); // prevents jump when loaded in
}

.c-message-read-container__head {
  flex: 0 0 auto;

  .message-teams {
    display: inline-block;

    .team-name {
      margin-right: var(--spacing-3);

      color: #999;
    }

    .collapse.show {
      display: inline-block;
    }
  }
}

.c-message-read-container__body {
  flex: 1 1 auto;
  min-height: style.pxtorem(8*9);

  overflow: auto;

  .c-message-read-container__newlines {
    white-space: pre-line;
  }

  // For the vessel-etx div we don't want the same amount of padding so exclude it here.
  > div:not(#vessel-etx) {
    position: relative;

    padding: var(--spacing-6);
  }

  > pre {
    padding: var(--spacing-6);
  }
}

.c-message-read-container__footer {
  position: relative; // For absolutely positioned nav buttons
}

.c-message-read-container.c--draft {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    z-index: var(--backdrop-layer);

    display: block;
    width: 100%;
    height: var(--spacing-2);

    background: var(--draft);
  }

  &.c--jr::before {
    // apply job ref bar using same ::before draft bar elem by using border-top
    top: calc(var(--bw2) * -1);

    height: calc(var(--spacing-2) + var(--bw2));

    border-top: var(--bw2) solid var(--job-tag);
  }

  .c-message-header__following {
    top: var(--spacing-2);

    height: style.pxtorem(8*10.5);
  }
}

.c-message-read-container__scheduledbar-enter {
  opacity: 0;
  transition: opacity 0.125s ease-out, transform 0.25s ease-in-out;
  transform: translateY(-100%);
}

.c-message-read-container__scheduledbar-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.c-message-read-container__scheduledbar-exit {
  opacity: 1;
  transition: opacity 0.15s ease-out, transform 0.25s ease-in-out;
  transform: translateX(0);
}

.c-message-read-container__scheduledbar-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

/* feature flag collapsible header */
.c-message-read-container.c-ff-collapsible-hd {
  position: relative;

  &.c--draft {
    padding-top: var(--spacing-2);
  }
}

.c-message-header__following.c-ff-collapsible-hd {
  position: absolute;
  top: 0;
  right: 0;

  z-index: var(--stage-layer);

  height: style.pxtorem(8*10.5);
}

.c-message-read-container__main-ff-collapsible-hd {
  display: flex;

  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;

  &.c--expanded {
    display: block;
    overflow: auto;

    .c-message-header__top {
      position: absolute;
      top: 0;
      z-index: var(--stage-layer);

      width: 100%;
      padding-bottom: var(--spacing-2);

      background-color: var(--background-1);
    }

    .c-message-toolbar__pos {
      // temporary add a wrapper to position bar instead of passing in style
      position: sticky;
      z-index: var(--stage-layer);

      margin-top: -1px;

      border-top: 1px solid var(--border-shade);
    }

    .c--draft & {
      .c-message-header__top {
        top: var(--spacing-2); // make space for draft bar
      }
    }
  }
}

.mobile-specific-styling {
  .c-message-read-container {
    position: relative; // for absolutely positioned side panel tab icons

    height: 100%;
    margin: 0;
    overflow: hidden;

    .c-message-read-container__body-bounds {
      flex: 1 1 auto;
      min-height: 0;
      width: 100%;

      // these classNames come from react-zoom-pan-pinch
      & > .react-transform-wrapper {
        height: 100%;
        width: 100%;
        user-select: auto;

        & > .react-transform-component {
          min-height: 100%;
          min-width: 100%;
        }
      }
    }

    .c-message-read-container__body {
      overflow: auto;

      word-wrap: break-word;
      -webkit-overflow-scrolling: touch;
    }

    .c-message-read-container__footer {
      h3 {
        display: none;
      }
    }

    &.c--jr::before,
    &.c--draft.c--jr::before {
      top: 0;
    }
  }

  .c-message-read-container__main-ff-collapsible-hd.c--expanded {
    .c-message-header__top {
      padding-bottom: var(--spacing-3);
    }

    .c-message-header__subject {
      white-space: pre-wrap;
    }
  }
}
