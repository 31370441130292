.c-tag-document-popover {
  padding: var(--spacing-2) var(--spacing-3);
  background-color: var(--background-4);
  min-width: 350px;
  min-height: 100px;
  border-radius: var(--br2);

  &.tag-attachment {
    height: 445.25px;
  }
}

.c-tag-document-popover__header {
  display: flex;
  justify-content: space-between;
}

.c-tag-document-popover__header-title {
  position: relative;
  color: var(--text-4);
  font-size: var(--fs-lg);
  font-weight: var(--fw-normal);
}

.c-tag-document-popover__body-title {
  color: var(--text-4);
  font-size: var(--fs);
  font-weight: var(--fw-semi);
  margin: var(--spacing-2);

  &.no-tags {
    font-weight: normal;
    text-align: center;
  }
}

.c-tag-document-popover__doc-type {
  color: var(--text-4);
  font-size: var(--fs);
  font-weight: var(--fw-semi);
  cursor: pointer;

  &.is-active {
    color: var(--action-60);
  }
}

.c-tag-document-popover__date-label {
  color: rgb(166, 174, 173);
  font-size: var(--fs-sm);
  font-weight: var(--fw-normal);
}

.c-tag-document-popover__date-group {
  display: flex;
  justify-content: left;
}

.c-tag-document-popover__body {
  display: flex;
  flex-direction: column;
  height: 393px;

  @media print {
    position: initial;
  }
}

.c-tag-document-popover__doc-type-content {
  flex: 1 1 auto;
  margin-left: var(--spacing-2);
  overflow-y: auto;
}

.c-tag-document-popover__set-date-container {
  display: flex;
}

.c-tag-document-popover__set-date-button {
  background-color: transparent;
  border: 1px solid #56605f;
}

.c-tag-document-popover__vessel {
  cursor: pointer;
}

.c-tag-document-popover__tag-document-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.c-tag-document-popover__cancel-button {
  position: relative;
  background-color: transparent;
  border: none;
}

.c-tag-document-popover__remove-tag {
  display: flex;
  justify-content: center;
}

.c-tag-document-popover__tag-button {
  position: relative;
}

.c-tag-document-popover__expiry-date {
  font-size: var(--fs);
  font-weight: var(--fw-normal);
  color: var(--text-4);
}

hr {
  width: 100%;
  height: 1px;
  border-top: 1px solid #56605f;
}
