// customize bootstrap variables
// bootstrap overrides
@use "src/styles/utilities/_rem-transform" as style;

$border-radius: var(--br1);

$btn-line-height: 1.25;

$dropdown-link-hover-bg: var(--menu-item-hover-background);

$input-border-color: var(--border-shade);
$input-border-radius: var(--br1);
$input-btn-focus-box-shadow: none;
$input-focus-border-color: var(--action-50);

$link-color: initial;
$link-hover-color: initial;
$link-hover-decoration: none;

$modal-inner-padding: style.pxtorem(8*2.5); // 🙃 just going along with bootstrap
$modal-footer-margin-between: style.pxtorem(8*1.5); // ☝️
$modal-header-padding: var(--spacing-2);

$popover-body-padding-y: var(--spacing-3);
$popover-body-padding-x: var(--spacing-3);
$popover-font-size: var(--fs);
$popover-max-width: style.pxtorem(8*46);

$tooltip-font-size: var(--fs-sm);
$tooltip-padding-x: var(--spacing-2);
$tooltip-padding-y: var(--spacing-1);

$font-size-base: 0.5rem;

$theme-colors: (
  "primary": #45c4c4,
  "secondary": #d7dbe1,
  "success": #048741,
  "danger": #d61a06,
);

$font-size-base: 0.5rem;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji", 微软雅黑, "Microsoft YaHei New",
  "Microsoft Yahei", "PingFang TC", 宋体, SimSun, SimHei, DengXian;
