.c-message-interface-panel {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
  min-height: calc(var(--spacing-2) * 6 + var(--icon-md));

  .dropdown {
    display: inline-block;
  }

  .c--messageId {
    padding: var(--spacing-2) 0;
    border: var(--bw1) solid transparent;
  }

  // Using the div ids to create equal spacing between the right side interface panel
  // as the copy message Id button and the share message button are not IconButtons
  // and use different margins
  #messageId,
  #messageShareModal {
    margin: 0 var(--spacing-2);
  }

  .c-icon-button {
    margin: 0 var(--spacing-1);

    .mobile-specific-styling & {
      margin: 0 var(--spacing-2);
    }

    &.c--selected.c--following {
      .mobile-specific-styling.mobile-specific-styling & {
        color: var(--text-1);
      }
    }
  }
}
