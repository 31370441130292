.c-tag-search {
  position: relative; // for absolutely positioned search icon

  display: flex;
  align-items: center;

  width: 100%;
  height: calc(
    24px + (var(--spacing-3) * 2) + var(--spacing-1)
  ); // force the height to the absolutely positioned icon

  padding: var(--spacing-2) 0;

  background-color: var(--white);
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);
  box-shadow: var(--dropshadow);

  color: inherit;

  .c-icon.c--search-icon {
    position: absolute;
    top: var(--spacing-3);
    left: var(--spacing-3);

    color: var(--text-2);
  }

  .react-autosuggest__container {
    width: 100%;
  }

  .react-autosuggest__container::before {
    top: 0;

    padding: var(--spacing-3) var(--spacing-4);

    font-size: var(--fs);
  }

  .react-autosuggest__input {
    padding: var(--spacing-3) var(--spacing-6);
    padding-left: var(--spacing-8);

    background-color: transparent;
    border: none;

    font-size: var(--fs);
    color: inherit;

    &::placeholder {
      opacity: 0.6;

      color: inherit;
    }
  }

  .react-autosuggest__input--open {
    border-radius: 0 0 var(--br1) var(--br1);
  }

  .react-autosuggest__suggestions-container--open {
    top: auto;
    bottom: calc(100% + var(--spacing-1));

    background-color: var(--background-1);
    border-radius: var(--br1) var(--br1) 0 0;
  }
}
