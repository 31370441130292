// Opacity
// ===

.u-o-100 {
  opacity: 1;
}

.u-o-0 {
  opacity: 0;
}

.u-o-50 {
  opacity: 0.5;
}
