// Batch Tag Component
// ===

.c-batch-tag {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-2);
  background-color: transparent;
  border: none;

  color: var(--text-2);
}

.c-batch-tag.disabled {
  opacity: 0.5;
}

.c-batch-tag__box {
  display: flex;
  width: var(--spacing-6);
  height: var(--spacing-6);
  padding: 0;
  justify-content: center;
  align-items: center;

  margin-right: var(--spacing-3);

  border: 2px solid var(--border-shade);

  cursor: pointer;

  &.c-batch-tag__box {
    &:active,
    &:focus,
    &:hover {
      background-color: var(--action-50);
      outline: none;
    }
  }
}

.c-batch-tag__box.c--invert {
  background-color: var(--text-2);
  color: var(--white);
}

.c-batch-tag__count {
  margin-left: var(--spacing-3);

  font-size: var(--fs-sm);
  color: var(--text-2);
}
