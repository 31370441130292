// Spacing Utility Classes
// ===

// Margin All
// ---

.u-ma-0 {
  margin: 0;
}

.u-ma-xs {
  margin: var(--spacing-1);

  @media print {
    margin: 2px;
  }
}

.u-ma-sm {
  margin: var(--spacing-2);

  @media print {
    margin: 4px;
  }
}

.u-ma-md {
  margin: var(--spacing-3);

  @media print {
    margin: 8px;
  }
}

.u-ma-lg {
  margin: var(--spacing-6);

  @media print {
    margin: 24px;
  }
}

// Margin Top
// ---

.u-mt-0 {
  margin-top: 0;
}

.u-mt-xs {
  margin-top: var(--spacing-1);

  @media print {
    margin-top: 2px;
  }
}

.u-mt-sm {
  margin-top: var(--spacing-2);

  @media print {
    margin-top: 4px;
  }
}

.u-mt-md {
  margin-top: var(--spacing-3);

  @media print {
    margin-top: 8px;
  }
}

.u-mt-lg {
  margin-top: var(--spacing-6);

  @media print {
    margin-top: 24px;
  }
}

// Margin Right
// ---

.u-mr-0 {
  margin-right: 0;
}

.u-mr-xs {
  margin-right: var(--spacing-1);

  @media print {
    margin-right: 2px;
  }
}

.u-mr-sm {
  margin-right: var(--spacing-2);

  @media print {
    margin-right: 4px;
  }
}

.u-mr-md {
  margin-right: var(--spacing-3);

  @media print {
    margin-right: 8px;
  }
}

.u-mr-lg {
  margin-right: var(--spacing-6);

  @media print {
    margin-right: 24px;
  }
}

// Margin Botton
// ---

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-xs {
  margin-bottom: var(--spacing-1);

  @media print {
    margin-bottom: 2px;
  }
}

.u-mb-sm {
  margin-bottom: var(--spacing-2);

  @media print {
    margin-bottom: 4px;
  }
}

.u-mb-md {
  margin-bottom: var(--spacing-3);

  @media print {
    margin-bottom: 8px;
  }
}

.u-mb-lg {
  margin-bottom: var(--spacing-6);

  @media print {
    margin-bottom: 24px;
  }
}

// Margin Left
// ---

.u-ml-0 {
  margin-left: 0;
}

.u-ml-xs {
  margin-left: var(--spacing-1);

  @media print {
    margin-left: 2px;
  }
}

.u-ml-sm {
  margin-left: var(--spacing-2);

  @media print {
    margin-left: 4px;
  }
}

.u-ml-md {
  margin-left: var(--spacing-3);

  @media print {
    margin-left: 8px;
  }
}

.u-ml-lg {
  margin-left: var(--spacing-6);

  @media print {
    margin-left: 24px;
  }
}

.u-ml-xl {
  margin-left: var(--spacing-8);

  @media print {
    margin-left: 40px;
  }
}

// Padding All
// ---

.u-pa-0 {
  padding: 0;
}

.u-pa-xs {
  padding: var(--spacing-1);

  @media print {
    padding: 2px;
  }
}

.u-pa-sm {
  padding: var(--spacing-2);

  @media print {
    padding: 4px;
  }
}

.u-pa-md {
  padding: var(--spacing-3);

  @media print {
    padding: 8px;
  }
}

.u-pa-lg {
  padding: var(--spacing-6);

  @media print {
    padding: 24px;
  }
}

// Padding Top
// ---

.u-pt-0 {
  padding-top: 0;
}

.u-pt-xs {
  padding-top: var(--spacing-1);

  @media print {
    padding-top: 2px;
  }
}

.u-pt-sm {
  padding-top: var(--spacing-2);

  @media print {
    padding-top: 4px;
  }
}

.u-pt-md {
  padding-top: var(--spacing-3);

  @media print {
    padding-top: 8px;
  }
}

.u-pt-lg {
  padding-top: var(--spacing-6);

  @media print {
    padding-top: 24px;
  }
}

// Padding Right
// ---

.u-pr-0 {
  padding-right: 0;
}

.u-pr-xs {
  padding-right: var(--spacing-1);

  @media print {
    padding-right: 2px;
  }
}

.u-pr-sm {
  padding-right: var(--spacing-2);

  @media print {
    padding-right: 4px;
  }
}

.u-pr-md {
  padding-right: var(--spacing-3);

  @media print {
    padding-right: 8px;
  }
}

.u-pr-lg {
  padding-right: var(--spacing-6);

  @media print {
    padding-right: 24px;
  }
}

// Padding Left
// ---

.u-pl-0 {
  padding-left: 0;
}

.u-pl-xs {
  padding-left: var(--spacing-1);

  @media print {
    padding-left: 2px;
  }
}

.u-pl-sm {
  padding-left: var(--spacing-2);

  @media print {
    padding-left: 4px;
  }
}

.u-pl-md {
  padding-left: var(--spacing-3);

  @media print {
    padding-left: 8px;
  }
}

.u-pl-lg {
  padding-left: var(--spacing-6);

  @media print {
    padding-left: 24px;
  }
}

// Padding Bottom
// ---

.u-pb-0 {
  padding-bottom: 0;
}

.u-pb-xs {
  padding-bottom: var(--spacing-1);

  @media print {
    padding-bottom: 2px;
  }
}

.u-pb-sm {
  padding-bottom: var(--spacing-2);

  @media print {
    padding-bottom: 4px;
  }
}

.u-pb-md {
  padding-bottom: var(--spacing-3);

  @media print {
    padding-bottom: 8px;
  }
}

.u-pb-lg {
  padding-bottom: var(--spacing-6);

  @media print {
    padding-bottom: 24px;
  }
}
