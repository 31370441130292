.c-sidebar-right-pane {
  display: flex;
  flex-direction: column;
  height: 100%;

  .mobile-specific-styling & {
    flex: 1 1 auto;
    height: auto;
  }
}

.c-sidebar-right-pane__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: calc(
    var(--spacing-2) * 6 + var(--icon-md)
  ); // match height of bips
  margin-right: var(--spacing-3);
  margin-left: var(--spacing-3);

  border-bottom: 1px solid var(--border-shade);

  .c-count-badge {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.c-sidebar-right-pane__content {
  position: relative;

  flex: 1 1 auto;

  .mobile-specific-styling & {
    -webkit-overflow-scrolling: touch;
  }
}

.c-sidebar-right-pane.c--dark {
  color: var(--text-4);

  .c-sidebar-right-pane__header {
    border-color: var(--border-3);
  }

  .c-tag.c--outline {
    background-color: rgba(255, 255, 255, 0.85);
  }
}
