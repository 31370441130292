@use "src/styles/utilities/_rem-transform" as style;
@import "../styles/breakpoints";

.react-autosuggest__container {
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: var(--spacing-3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: var(--br1);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.react-autosuggest__input {
  /* this matches bootstrap .form-control-sm class */
  width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  font-size: var(--fs-sm);
  line-height: 1.25;
  background-color: var(--white);
  border: 1px solid var(--dropdown-input-border);
  border-radius: var(--dropdown-input-border-radius);
}

.react-autosuggest__input--focused {
  outline: var(--dropdown-item-focus);
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  width: 100%;
  font-size: var(--fs);
  display: block;
  position: absolute;
  top: 100%;
  background-color: var(--white);
  border: 1px solid var(--border-shade);
  border-bottom-left-radius: var(--br1);
  border-bottom-right-radius: var(--br1);
  max-height: 50vh;
  min-height: style.pxtorem(8*30);
  overflow-y: auto;
  z-index: 3;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: var(--spacing-3) var(--spacing-5);
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--menu-item-hover-background);
}

.react-autosuggest__section-container {
  border-top: 1px solid var(--border-shade);
}

.react-autosuggest__section-container--first {
  margin-top: 0;
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: var(--spacing-2);

  font-size: var(--fs-sm);
  font-weight: var(--fw-bold);
  color: var(--text-2);
  text-transform: uppercase;
}

.mobile-specific-styling {
  .c-filter-token-select-container.c--header-wide
    .react-autosuggest__suggestions-container--open {
    z-index: var(--orchestra-layer);

    @media (max-width: $small) {
      .react-autosuggest__suggestion-description {
        display: none;
      }
    }
  }

  .c-search {
    display: flex;
    flex-wrap: wrap;

    position: relative; // for absolutely positioned date filter buttons

    color: var(--text-1);
    padding: var(--spacing-2);
    background-color: var(--white);
    .react-autosuggest__suggestions-container--open {
      left: -51px !important;
    }
  }

  .c-filter-select-container.c--modal .react-autosuggest__input {
    width: style.pxtorem(8*32);
  }

  .react-autosuggest__filter-search-footer {
    display: none;
  }

  .react-autosuggest__input {
    margin-left: var(--spacing-2);
    font-size: var(--fs);
  }
}
