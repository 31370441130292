.c-mobile-menu__overlay {
  position: fixed;
  bottom: 0;
  z-index: var(--frontrow-layer);

  width: 100%;
}

.c-mobile-menu__content {
  position: absolute;
  bottom: 0;

  width: 100%;

  padding-bottom: var(--spacing-3);
  margin: 0;

  background-color: var(--background-4);
  border-color: var(--background-4);
  border-radius: var(--br1);

  transition: transform 100ms;
  transform: translateY(100%);

  &.ReactModal__Content--after-open {
    transform: translateY(0);
  }

  &.ReactModal__Content--before-close {
    transform: translateY(100%);
  }

  a {
    color: var(--text-4);
  }
}

.c-mobile-menu__header {
  display: flex;
  align-items: center;

  padding: var(--spacing-2);

  border-bottom: none;
  color: var(--text-4);

  .c-mobile-menu.c--back & {
    justify-content: space-between;
  }
}

.c-mobile-menu__title {
  flex: 1 1 auto;

  padding-left: calc(
    var(--icon-md) + 2 * var(--spacing-2)
  ); // ~= close button size for centering
  margin-bottom: 0;

  font-size: var(--fs);

  text-align: center;

  .c-mobile-menu.c--back & {
    padding-left: 0;
  }
}
