// Visibility
// ===

// Text that is hidden but accessible
// Ref: http://snook.ca/archives/html_and_css/hiding-content-for-accessibility

@import "../breakpoints";

.u-clip {
  position: fixed !important;
  // To fix the horizontal scroll issue on message list
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media screen and (max-width: $small) {
  .u-clip-s {
    position: fixed !important;

    width: 1px;
    height: 1px;
    overflow: hidden;

    clip: rect(1px, 1px, 1px, 1px);
  }
}

@media screen and (max-width: $x-small) {
  .u-clip-xs {
    position: fixed !important;

    width: 1px;
    height: 1px;
    overflow: hidden;

    clip: rect(1px, 1px, 1px, 1px);
  }
}
