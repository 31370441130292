// Message Activity Pane Comment Input Mention
// ===
@use "src/styles/utilities/_rem-transform" as style;

.c-activity-input-bar-mention__highlighter strong {
  // The way the mentions library works is it creates a duplicate
  // copy of the “tag” positioned over the raw text in the input/textarea.
  // The difference in placement of the "tag" and the raw text was causing the distortion.
  // This fixes the distortion for the desktop.
  text-shadow: white 0 0 3px;
  color: var(--person-tag);
  position: relative;
  left: -1px;
  z-index: var(--backdrop-layer);
  bottom: 1px;

  // Fix distortion for the iOS app and ios mobile browsers
  .c--cordova &,
  .c--cordova-mobile-layout &,
  .c-ios-styling & {
    bottom: 0;
    left: -4px;
  }
}

.c-activity-input-bar-mention__suggestions__list {
  background-color: white;
  border: 1px solid var(--border-shade);
  border-radius: var(--br1);
  overflow: auto;
  max-height: style.pxtorem(8*36);

  .mobile-specific-styling & {
    max-height: style.pxtorem(8*16); // seems like a good height on a moto android 🎲
  }

  .safari.mobile-specific-styling & {
    max-height: style.pxtorem(8*21); // seems like a good height on an iphoneX 🎲
  }
}

.c-activity-input-bar-mention__suggestions {
  .mobile-specific-styling & {
    top: initial !important; // override inline styles set by plugin
    bottom: calc(var(--spacing-3) * 3); // position approx above line height
  }
}
