@use "../../../styles/utilities/rem-transform" as style;
@import "../../../../node_modules/bootstrap/scss/mixins/text-truncate";

.c-text-modifier-tag {
  @include text-truncate();

  display: inline-flex;
  align-items: center;
  max-width: 100%;

  padding: var(--spacing-1) var(--spacing-2);

  background-color: var(--background-2);

  border-radius: style.pxtorem(8*18); // match .c-tag

  color: inherit;

  @media (pointer: fine) {
    height: 24px; // match .c-tag height

    font-size: var(--fs-sm);
  }
}

.c-text-modifier__whitespace {
  width: var(--icon-sm);
  height: var(--icon-sm);
}
