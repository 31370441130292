// Message Documents
// ===
@use "src/styles/utilities/_rem-transform" as style;
@import "../../../node_modules/bootstrap/scss/mixins/text-truncate";

@keyframes loading-bar {
  from {
    left: -95%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 130%;
  }
  to {
    left: 150%;
  }
}

.c-message-document-tile {
  position: relative;

  &.c--loading {
    .c-message-document-tile__inner {
      position: relative;

      z-index: 0;

      border-color: var(--action-50);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        z-index: -1;

        width: 100%;
        height: 100%;

        background-color: var(--action-tint-10);
        animation: loading-bar 1.6s linear infinite;
      }
    }
  }
}

.c-message-document-tile__tagged {
  position: absolute;
  top: 45%;
  bottom: 10px;
  left: calc((var(--spacing-2) * 2) + 18px);

  display: flex;
  align-items: center;

  font-size: var(--fs-sm);
}

.c-message-document-tile__blue-dot {
  width: var(--fs-lg);
  height: var(--fs-lg);

  background-color: var(--action-70);
  border-radius: calc(var(--fs-lg) / 2);
}

.c-message-document-tile__preview {
  position: absolute;
  bottom: calc(var(--spacing-2) + 2px);
  left: calc((var(--spacing-1) * 2) + var(--icon-sm));

  display: flex;
  align-items: center;

  font-size: var(--fs-sm);
}

.c-message-document-tile__preview-btn {
  padding: 0 var(--spacing-3) 0 0;

  background-color: var(--white);
  border: none;

  border-radius: style.pxtorem(8*3.5);
  font-weight: var(--fw-semi);
  line-height: var(--icon-sm);
  color: var(--action-80);

  &:active,
  &:focus,
  &:hover {
    background-color: var(--action-10);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--action-tint-10);
  }
}

.c-message-document-tile__preview-icon {
  display: inline-block;
  padding: 1px;
  margin-right: var(--spacing-2);

  background-color: var(--action-80);
  border: 1px solid var(--white);
  border-radius: 50%;

  color: var(--white);
}

.c-message-document-tile__inner {
  position: relative;

  display: flex;
  align-items: center;
  padding: var(--spacing-2);
  margin: var(--spacing-2);

  overflow: hidden;

  background-color: var(--white);
  border-color: var(--border-shade);
  border-style: solid;

  border-width: var(--bw1);
  border-radius: var(--br2);

  transition: border-color 450ms ease-in-out;
}

.c-message-document-tile__icon {
  position: relative;
  top: 1px;

  flex: 0 0 auto;

  font-size: style.pxtorem(8*4);
  color: var(--text-2);
}

.c-message-document-tile__meta {
  @include text-truncate();

  font-size: var(--fs-sm);
}

.c-message-document-tile__name {
  @include text-truncate();
}

.c-message-document-tile__menu {
  flex-shrink: 0;
}
