@import "../../styles/breakpoints";

.c-layout-panes {
  position: relative;
  flex-grow: 1; // full height of layout

  width: 100%;

  --panes-sm: 40%;
  --pane-left-md: 30%;
  --pane-right-md: 30%;
  --pane-left-lg: 25%;
  --pane-right-lg: 25%;
}

.c-layout-panes__main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;

  overflow: hidden;

  position: absolute;
  left: 0;
  right: 0;

  margin-right: calc(var(--spacing-3) + var(--panes-sm));
  margin-left: calc(var(--spacing-3) + var(--pane-left-md));

  // gutter is intentionally narrower on this screen size to allow all the message interface panel buttons to fit
  @media (min-width: $medium) {
    margin-right: calc(var(--spacing-2) + var(--pane-right-md));
    margin-left: calc(var(--spacing-2) + var(--pane-left-md));
  }

  @media (min-width: $large) {
    margin-right: calc(var(--spacing-2) + var(--pane-right-lg));
    margin-left: calc(var(--spacing-2) + var(--pane-left-lg));
  }

  &.c--collapsed-left:not(.c--suppress-gutter) {
    margin-left: var(--spacing-3);
  }

  &.c--collapsed-right:not(.c--suppress-gutter) {
    margin-right: var(--spacing-3);
  }

  &.c--suppress-gutter {
    margin-right: 0;
    margin-left: 0;
  }

  @media print {
    width: 100%;
    position: static;
    height: auto;
    margin-right: 0;

    margin-left: 0;

    // SED-6979
    .firefox & {
      display: block;
    }
  }
}

.c-layout-panes__side {
  position: absolute;
  top: 0;
  bottom: 0;

  overflow: hidden;

  &.c--left {
    left: 0;

    width: var(--pane-left-md);

    @media (min-width: $large) {
      width: var(--pane-left-lg);
    }

    &.c--collapsed {
      transform: translateX(-100%);
    }
  }

  &.c--right {
    right: 0;

    width: var(--panes-sm);

    @media (min-width: $medium) {
      width: var(--pane-right-md);
    }

    @media (min-width: $large) {
      width: var(--pane-right-lg);
    }

    &.c--collapsed {
      transform: translateX(100%);
    }

    @media print {
      width: 100%;
    }
  }

  @media print {
    position: initial;

    width: 100%;
  }

  &.c--left,
  &.c--collapsed {
    @media print {
      display: none;
    }
  }
}

.c-layout-panes__side-content {
  height: 100%;
  transition: transform 0.15s ease-out;
  transform: translateX(-100%);

  &.c--right {
    transform: translateX(100%);
  }

  &.c--animate {
    transform: translateX(0);
  }
}
